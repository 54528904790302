import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Confirm, Dropdown, Icon, Modal, Popup, Grid, Form} from 'semantic-ui-react';
import FormField from '../../../containers/customGrid/components/formField';
import { tripFreelanceEventSelector } from '../../../ducks/profile';
import { saveFreelanceEventRequest, progressFreelanceEventSaveSelector } from '../../../ducks/gridActions';
import moment from 'moment';


const FreelanceEventModal = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { selectedIds, closeFreelanceEventModal, cardCallback } = props;
    const now = new Date();

    let [form, setForm] = useState({dateTimeEvent: moment(now).format('DD.MM.YYYY HH:mm')});
    let [notChangeForm, setNotChangeForm] = useState(true);

    const tripFreelanceEvent = useSelector(state => tripFreelanceEventSelector(state));
    const saveLoader = useSelector(state => progressFreelanceEventSaveSelector(state));

    const handleSave = () => {
        dispatch(saveFreelanceEventRequest({
            id: selectedIds[0],
            form: form,
            callbackSuccess: () => {
                closeFreelanceEventModal();
                cardCallback && cardCallback();
            }
        }))
    };

    const onChange = useCallback((e, { name, value }) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setNotChangeForm(false);
    }, []);

    return (
        <>
            <Form className="tabs-card">
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <FormField
                                                name="dateTimeEvent"
                                                columnsConfig={tripFreelanceEvent}
                                                form={form}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="tripPointId"
                                                columnsConfig={tripFreelanceEvent}
                                                forSelectId={selectedIds[0]}
                                                form={form}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <FormField
                                                name="freelanceEventTypeId"
                                                columnsConfig={tripFreelanceEvent}
                                                form={form}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="comment"
                                                displayNameKey="freelanceEvent.comment"
                                                columnsConfig={tripFreelanceEvent}
                                                form={form}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Modal.Actions>
                <Button color="grey" onClick={() => {
                    closeFreelanceEventModal();
                    setForm({});
                    setNotChangeForm(true);
                }}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    onClick={handleSave}
                    disabled={notChangeForm}
                    loading={saveLoader}
                >
                    {t('SaveButton')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default FreelanceEventModal;