import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {dataForModalSelector} from "../../ducks/profile";
import FormField from "../BaseComponents";

const MassChangesModal = ({open, name, setOpen, loading, onSave}) => {
    const {t} = useTranslation();
    const dataForModal = useSelector(state => dataForModalSelector(state, name)) || {}

    const [form, setForm] = useState({});

    const {
        columns = []
    } = dataForModal;
    const onChange = (e, {value, name}) => {
        setForm(form => ({
            ...form,
            [name]: value
        }))
    }

    useEffect(() => {
        !open && setForm({})
    }, [open])

    const disabled = (form) => {
        const requiredFields = columns.filter(c => c.isRequired);
        return !requiredFields.every(rF => !!form[rF.name]);
    }

    return (
        <Modal open={open}>
            <Modal.Header>{t(name)}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Grid>
                        <Grid.Row>
                            {
                                columns.map(column => (
                                    <Grid.Column
                                        className='margin-bottom-10'
                                        width={8}
                                    >
                                        <FormField
                                            {...column}
                                            key={column.name}
                                            value={form[column.name]}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                ))
                            }
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="grey" onClick={() => {
                    setForm({})
                    setOpen(false)
                }}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={disabled(form) || loading}
                    onClick={() => onSave(form)}
                    loading={loading}
                >
                    {t('SaveButton')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default MassChangesModal;