import React, { useRef, useEffect, useState } from 'react';
import { dataLoadingRequest } from '../../ducks/loadingData';
import {downloadDocumentRequest, uploadFileRequest} from '../../ducks/documents';
import { Grid, Form, Dropdown, Input, Button, Icon, TextArea, Divider, Modal, Segment} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DocWithEditor from '../../components/Documents/DocWithEditor';
import {
    clearDocuments,
    documentsSelector,
    getDocumentsRequest,
    progressSelector,
} from '../../ducks/documents';
import { toast } from 'react-toastify';

const File = ({
    value = [],
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    isGrid,
    displayNameKey,
    fromModalUpload,
}) => {
    const { t } = useTranslation();
    const fileUploader = useRef(null);
    const dispatch = useDispatch();
    
    let [filesArray, setFilesArray] = useState(value);

    useEffect( () => {
        console.log(value);
    }, [filesArray])

    const onFilePicked = e => {
        let form = new FormData();
        let file = e.target.files[0];
        form.append('formFile', file);
        dispatch(
            uploadFileRequest({
                form,
                callbackSuccess: id => {
                    onChange(e, {
                        name,
                        value: {
                            value: id,
                            name: file.name,
                        },
                    });
                },
            }),
        );
        fileUploader.current.value = null;
    };

    const handleAddFile = (e, {file, fileId}) => {
        const files = value ? value.slice() : [];
        if (fileId) {
            const currentIndexFile = files.findIndex(item => item.fileId === fileId)
            files.splice(currentIndexFile, 1, file);
        } else {
            files.push(file);
        }
        setFilesArray(files.slice());
        onChange(e, {
            value: files,
            name: "files"
        });
    }

    const handleDeleteFile = (e, { fileId }) => {
        const files = value.slice();
        const currentIndexFile = files.findIndex(item => item.fileId === fileId)
        files.splice(currentIndexFile, 1);
        setFilesArray(files.slice());
        onChange(e, {
            value: files,
            name: "files"
        });
    }

    const handleDownload = () => {
        value && value.value && dispatch(downloadDocumentRequest({
            id: value.value
        }))
    };


    return (
        <Grid.Row className="flex-container tabs-card" style={!fromModalUpload ? {width: "100%", display: "flex", flexDirection: "column"} : {width: "100%", display: "flex", flexDirection: "column", padding: "0"}}>
          {/*  <Dimmer active={loading} inverted>
                <Loader size="huge">Loading</Loader>
            </Dimmer>*/}
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(displayNameKey ? displayNameKey : text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div className="form-files-container">
                {value && value.length ? value.map((document, index) => (
                    <DocWithEditor
                        key={document.fileId}
                        currentFileId={document.fileId}
                        gridName={name}
                        cardId={"12"}
                        load={false}
                        document={document}
                        handleDeleteFile={handleDeleteFile}
                        handleAddFile={handleAddFile}
                        isEditPermissions={true}                 
                        titleText={t('Edit document')}
                        okButtonText={t('SaveButton')}
                    />
                )) : null}
                {(fromModalUpload && value && value.length > 0) ? null :
                    <DocWithEditor
                        gridName={name}
                        cardId={"12"}
                        load={false}
                        handleAddFile={handleAddFile}
                        isEditPermissions={true}
                        titleText={t('Add document')}
                        okButtonText={t('AddButton')}
                    />
                }
                
            </div>
            
        </Grid.Row>
    );
};

export default File;
