import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    dataLoadingMenuSelector,
    dictionariesHeaderSelector,
    dictionariesMenuSelector,
    dictionariesCategoriesSelector,
    gridsMenuSelector,
    otherMenuSelector,
    reportsMenuSelector,
    rolesAndUsersMenu,
    roleSelector,
    orderTransportSettingSelector,
    userNameSelector,
    gridCategoriesSelector,
    gridsNoCategorySelector,
} from '../../ducks/profile'
import useReactRouter from 'use-react-router'
import { isAuthSelector, logoutRequest } from '../../ducks/login'
import './style.scss'
import { DICTIONARY_LIST_LINK, GRID_LIST_LINK, PROFILE_LINK, REPORT_LINK, ORDER_TRANSPORT_SETTING_LINK } from '../../router/links'
import {
    dataLoadingRequest,
    getInstructionRequest,
    progressLoadingDataSelector,
    templateUploadRequest,
} from '../../ducks/loadingData'
import { downloader } from "../../utils/postman"
import downloadFile from "../../utils/downloadFile"
import TemplateSettings from "../LoadingData/TemplateSettings"
import {AUTOBLOCKING_WARRANTIES_GRID} from "../../constants/grids";

const excludedDictionaries = ['claimTemplates']

const Header = () => {
    const dispatch = useDispatch()
    const grids = useSelector(state => gridsMenuSelector(state))
    const gridsNoCategory = useSelector(state => gridsNoCategorySelector(state))
    const gridCategories = useSelector(state => gridCategoriesSelector(state))
    const dictionariesList = useSelector(state => dictionariesMenuSelector(state))
    const orderTransportSetting = useSelector(state => orderTransportSettingSelector(state))
    const dictionariesMenu = useSelector(state => dictionariesHeaderSelector(state))
    const dictionariesCategories = useSelector(state => dictionariesCategoriesSelector(state))
    const otherMenu = useSelector(state => otherMenuSelector(state))
    const usersAndRoles = useSelector(state => rolesAndUsersMenu(state))
    const dataLoadingMenu = useSelector(state => dataLoadingMenuSelector(state))
    const reportMenu = useSelector(state => reportsMenuSelector(state))
    const userName = useSelector(state => userNameSelector(state))
    const userRole = useSelector(state => roleSelector(state))
    const isAuth = useSelector(state => isAuthSelector(state))
    const loading = useSelector(state => progressLoadingDataSelector(state))
    const { t } = useTranslation()
    const { location, history } = useReactRouter()
    const fileUploader = useRef(null)

    let [activeItem, setActiveItem] = useState(location.pathname)
    let [currentTypeApi, setCurrentTypeApi] = useState(null)
    let [openTemplateSettings, setOpenTemplateSettings] = useState({ open: false })

    useEffect(() => {
        setActiveItem(location.pathname)
        console.log(location.pathname.split('/').length)
        const titleName = location.pathname.split('/').length < 4 ? t(location.pathname.split('/')[location.pathname.split('/').length - 1]) : t(location.pathname.split('/')[location.pathname.split('/').length - 2]) + " - " + t('title_edit')
        document.title = "Портал ДМ: " + titleName
    }, [location.pathname])

    const logOut = () => {
        dispatch(logoutRequest())
    }

    const handleOpenProfile = () => {
        history.push(PROFILE_LINK)
    }

    const handleClickLoadData = (type, typeApi, fileName) => {
        if (type === 'unloading') {
            dispatch(templateUploadRequest({
                typeApi
            }))
        } else if (type === 'loading') {
            setCurrentTypeApi(typeApi)
            fileUploader && fileUploader.current.click()
        } else if (type === 'instruction') {
            dispatch(getInstructionRequest({
                fileName
            }))
        } else if (type === 'template_settings') {
            setOpenTemplateSettings({
                open: true,
                typeApi
            })
        }
    }

    const onFilePicked = e => {
        const file = e.target.files[0]

        const data = new FormData()
        data.append('FileName', file.name)
        data.append('FileContent', new Blob([file], { type: file.type }))
        data.append('FileContentType', file.type)

        dispatch(
            dataLoadingRequest({
                form: data,
                typeApi: currentTypeApi
            }),
        )
        setCurrentTypeApi(null)
        fileUploader.current.value = null
    }

    const incidentsGrid = grids ? grids.find(name => name == 'incidents') : undefined
    const excludedGrids = ['gridTripPoints', 'incidents']
    const claimTemplatesDictionary = dictionariesList?.find(p => p == 'claimTemplates') || null

    return (
        <>
            {isAuth ? (
                <header>
                    <Menu pointing secondary fixed="top" style={{ paddingLeft: '12px' }}>
                        {/*<Menu.Item>LOGO</Menu.Item>*/}
                        {grids && gridsNoCategory &&
                            gridsNoCategory.map(item => (
                                !["incidents", "gridTripPoints", "fineDmBillsGrid", AUTOBLOCKING_WARRANTIES_GRID].includes(item) ? <Menu.Item
                                    className="large"
                                    key={item}
                                    as={Link}
                                    to={GRID_LIST_LINK.replace(':name', item)}
                                    name={item}
                                    active={activeItem.includes(item)}
                                >
                                    {t(item)}
                                </Menu.Item> : null
                            ))}
                        {gridCategories && gridCategories.length ? gridCategories.map(item => (
                            <Menu.Item>
                                <Dropdown
                                    key={item.name}
                                    name={item.name}
                                    text={t(item.name)}
                                >
                                    <Dropdown.Menu>
                                        {item.items.map(childItem => (
                                            <Dropdown.Item
                                                key={childItem.name}
                                                name={childItem.name}
                                                as={Link}
                                                to={GRID_LIST_LINK.replace(':name', childItem.name)}
                                                active={activeItem.includes(childItem.name)}
                                            >
                                                {t(childItem.name)}
                                            </Dropdown.Item>))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        )) : null}
                        {dictionariesMenu && dictionariesMenu.length
                            ? dictionariesMenu.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item}
                                    as={Link}
                                    to={DICTIONARY_LIST_LINK.replace(':name', item)}
                                    name={item}
                                    active={activeItem.includes(item)}
                                >
                                    {t(item)}
                                </Menu.Item>
                            ))
                            : null}
                        {orderTransportSetting || otherMenu && otherMenu.length ?
                            <Menu.Menu>
                                <Dropdown
                                    text={t('settings')}
                                    item
                                >
                                    <Dropdown.Menu>
                                        {orderTransportSetting
                                            ?
                                            <Dropdown.Item
                                                className="large"
                                                key={orderTransportSetting.name}
                                                as={Link}
                                                to={ORDER_TRANSPORT_SETTING_LINK}
                                                name={orderTransportSetting.name}
                                                active={activeItem.includes(orderTransportSetting.name)}
                                            >
                                                {t(orderTransportSetting.name)}
                                            </Dropdown.Item>

                                            : null}
                                        {otherMenu && otherMenu.length
                                            ? otherMenu.map(item => (
                                                <Dropdown.Item
                                                    className="large"
                                                    key={item.name}
                                                    as={Link}
                                                    to={item.link}
                                                    name={item.name}
                                                    active={activeItem.includes(item.name)}
                                                >
                                                    {t(item.name)}
                                                </Dropdown.Item>
                                            ))
                                            : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu> : null
                        }


                        {(dictionariesList && !!dictionariesList.filter(p => !excludedDictionaries.includes(p)).length)
                            || (dictionariesCategories && !!dictionariesCategories.filter(p => !excludedDictionaries.includes(p)).length)
                            || usersAndRoles.length ? (
                            <Menu.Menu>
                                <Dropdown
                                    text={t('dictionaries')}
                                    item
                                    className={`${[
                                        ...dictionariesList.filter(p => !excludedDictionaries.includes(p)),
                                        ...usersAndRoles.map(item => item.name),
                                    ].some(x => activeItem.includes(x)) && 'superActive'}`}
                                >
                                    <Dropdown.Menu>
                                        {dictionariesList.filter(p => !excludedDictionaries.includes(p)).map(item => {
                                            return (
                                                <Dropdown.Item
                                                    key={item}
                                                    as={Link}
                                                    to={DICTIONARY_LIST_LINK.replace(':name', item)}
                                                    active={activeItem.includes(item)}
                                                    name={item}
                                                >
                                                    {t(item)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                        {dictionariesCategories && !!dictionariesCategories.length && dictionariesCategories.map(item => {
                                            return (
                                                <Dropdown
                                                    item
                                                    className="link item"
                                                    key={item.name}
                                                    name={item.name}
                                                    text={t(item.name)}
                                                >
                                                    <Dropdown.Menu>
                                                        {item.items.map(childItem => (
                                                            <Dropdown.Item
                                                                key={childItem.name}
                                                                name={childItem.name}
                                                                text={t(childItem.name)}
                                                                as={Link}
                                                                to={DICTIONARY_LIST_LINK.replace(':name', childItem.name)}
                                                                active={activeItem.includes(childItem.name)}
                                                            />))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )
                                        })}
                                        {usersAndRoles.map(item => (
                                            <Dropdown.Item
                                                key={item.name}
                                                as={Link}
                                                to={item.link}
                                                active={activeItem.includes(item.name)}
                                                name={item.name}
                                            >
                                                {t(item.name)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {(dataLoadingMenu && dataLoadingMenu.length) || claimTemplatesDictionary ? (
                            <Menu.Menu>
                                <Dropdown text={t('data_loading')} item loading={loading}>
                                    <Dropdown.Menu>
                                        {dataLoadingMenu && !!dataLoadingMenu.length && dataLoadingMenu.map(item => (
                                            <Dropdown
                                                item
                                                className="link item"
                                                key={item.name}
                                                name={item.name}
                                                text={t(item.name)}
                                            >
                                                <Dropdown.Menu>
                                                    {item.items.map(childItem => (
                                                        <Dropdown.Item
                                                            key={childItem.name}
                                                            name={childItem.name}
                                                            text={t(childItem.name)}
                                                            onClick={() =>
                                                                handleClickLoadData(
                                                                    childItem.type,
                                                                    item.typeApi,
                                                                    childItem.fileName
                                                                )
                                                            }
                                                        />))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ))}
                                        {
                                            claimTemplatesDictionary &&
                                            <Dropdown.Item
                                                key={claimTemplatesDictionary}
                                                as={Link}
                                                to={DICTIONARY_LIST_LINK.replace(':name', claimTemplatesDictionary)}
                                                active={activeItem.includes(claimTemplatesDictionary)}
                                                name={claimTemplatesDictionary}
                                            >
                                                {t(claimTemplatesDictionary)}
                                            </Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {((reportMenu && reportMenu.length)
                            || (incidentsGrid)) ? (
                            <Menu.Menu>
                                <Dropdown text={t('Reports')} item>
                                    <Dropdown.Menu>
                                        {
                                            incidentsGrid &&
                                            <Dropdown.Item
                                                className="large"
                                                key={incidentsGrid}
                                                as={Link}
                                                to={GRID_LIST_LINK.replace(':name', incidentsGrid)}
                                                name={incidentsGrid}
                                                active={activeItem.includes(incidentsGrid)}
                                            >
                                                {t(incidentsGrid)}
                                            </Dropdown.Item>
                                        }
                                        {
                                            reportMenu &&
                                            !!reportMenu.length &&
                                            reportMenu.map(reportName => (
                                                <Dropdown.Item
                                                    className="large"
                                                    key={reportName}
                                                    as={Link}
                                                    to={REPORT_LINK.replace(':name', reportName)}
                                                    name={reportName}
                                                    active={activeItem.includes(reportName)}
                                                >
                                                    {t(reportName)}
                                                </Dropdown.Item>
                                            ))
                                        }
                                        {
                                            grids.includes(AUTOBLOCKING_WARRANTIES_GRID) && <Menu.Item
                                                className="large"
                                                key={AUTOBLOCKING_WARRANTIES_GRID}
                                                as={Link}
                                                to={GRID_LIST_LINK.replace(':name', AUTOBLOCKING_WARRANTIES_GRID)}
                                                name={AUTOBLOCKING_WARRANTIES_GRID}
                                                active={activeItem.includes(AUTOBLOCKING_WARRANTIES_GRID)}
                                            >
                                                {t(AUTOBLOCKING_WARRANTIES_GRID)}
                                            </Menu.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        <div className="header-support right menu">
                            <Icon name="question circle" />
                            <div className="header-support_contacts">
                                <a href="mailto:support.tmsl@artlogics.me">support.tmsl@artlogics.me</a>
                                <div>{t('support_work_time')}</div>
                            </div>
                            {userName && userRole ? (
                                <Menu.Menu>
                                    <Dropdown text={`${userName} (${userRole})`} item>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleOpenProfile}>
                                                {t('profile_settings')}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={logOut}>
                                                {t('exit')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            ) : null}
                        </div>
                    </Menu>
                </header>
            ) : null}
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            <TemplateSettings {...openTemplateSettings} onClose={() => setOpenTemplateSettings({ open: false })} />
        </>
    )
}
Header.propTypes = {}

export default Header
