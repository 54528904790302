import React, { useCallback } from 'react';
import CardLayout from '../../../components/CardLayout';
import Content from './content';
import ScheduleTab from "./scheduleTab";

const TabsIndex = ({
    title,
    getActionsFooter,
    getActionsHeader,
    onClose,
    onChange,
    columns,
    error,
    form,
    loading,
    setNotChangeForm,
    additionalBlocks,
}) => {
    const getContent = useCallback(() => {
        let items = [
            {
                menuItem: 'information',
                render: () => (
                    <Content columns={columns} error={error} form={form} handleChange={onChange} />
                ),
            },
        ];

        additionalBlocks.forEach(block => {
            items = [
                ...items,
                {
                    menuItem: block,
                    render: () => {
                        switch (block) {
                            case 'schedule':
                                return <ScheduleTab columns={columns} error={error} form={form} onChange={onChange} />;
                            default:
                                return null;
                        }
                    },
                },
            ];
        });

        return items;
    }, [form, error, columns, additionalBlocks]);

    return (
        <>
            {additionalBlocks && additionalBlocks.length ? (
                <CardLayout
                    title={title}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    content={getContent}
                    onClose={onClose}
                    loading={loading}
                />
            ) : (
                <CardLayout
                    title={title}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    onClose={onClose}
                    loading={loading}
                >
                    <Content columns={columns} error={error} form={form} handleChange={onChange} setNotChangeForm={setNotChangeForm} />
                </CardLayout>
            )}
        </>
    );
};

export default TabsIndex;
