import React, { createRef, useState } from 'react';
import { Form, Input, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Text = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    pattern,
    setNotChangeForm,
    focused,
    displayNameKey,
    tip,
}) => {
    const { t } = useTranslation();

    const [validationError, setValidationError] = useState(null);

    const handleBlur = () => {
        if (pattern) {
            let reg = new RegExp(pattern);
            if (!reg.exec(value)) {
                setValidationError(t("invalidFormat", name));
                setNotChangeForm && setNotChangeForm(true);
            } else {
                setValidationError(null);
            }
        }  
    }

    const input = (
        <Input
            placeholder={placeholder}
            list={valuesList && name}
            className={className}
            step="1"
            type={type}
            disabled={isDisabled}
            name={name}
            value={value ? value : ""}
            error={validationError ? true : error}
            onChange={onChange}
            onBlur={handleBlur}
            autoComplete={autoComplete}
            autoFocus={autoFocus || focused}
        />
    )
   
    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(displayNameKey ? displayNameKey : text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            {tip 
                ? <Popup trigger={input} content={t(tip)} on='focus' />
                : input
            }
            {validationError ? (
                <span className="label-error">{validationError}</span>
            ) : error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    );
};
export default Text;
