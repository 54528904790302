import React from 'react';
import { Form, Grid, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { betHistoriesSelector } from '../../../../ducks/gridCard';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../../../../components/InfiniteScrollTable';
import moment from "moment/moment";

const BetHistories = ({form}) => {
    const { t } = useTranslation();

    const betHistoriesColumns = useSelector((state) => betHistoriesSelector(state));

    const headerRowComponent = () => {
        return (
            <Table.Row>
                {betHistoriesColumns &&
                    betHistoriesColumns.map((item, index) => (
                        <Table.HeaderCell width={index} className="route-tab-table__header-cell">
                            {t(item.displayNameKey ? item.displayNameKey : item.name)}
                        </Table.HeaderCell>
                    ))}
            </Table.Row>
        );
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <div className="scroll-table-container route-tab-table">
                                    <InfiniteScrollTable
                                        className="grid-table table-info"
                                        headerRow={headerRowComponent()}
                                    >
                                        {form.betHistories &&
                                            form.betHistories.map((item, index) => {
                                                let testDateUtc = moment.utc(item.bidDate, "DD.MM.YY HH:mm");
                                                let localDate = moment(testDateUtc).local();
                                                let localStringDate = localDate.format("DD.MM.YY HH:mm");

                                                return (
                                                    <Table.Row>
                                                        <td width={index}>{item.transportCompanyName}</td>
                                                        <td width={index}>{item.cost}</td>
                                                        <td width={index}>{localStringDate}</td>
                                                        <td width={index}>{item.glnCodeTc}</td>
                                                    </Table.Row>
                                                )

                                            })}
                                    </InfiniteScrollTable>
                                </div>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default BetHistories;