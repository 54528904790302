import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Grid, Segment } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import FormField from '../formField'
import { columnsTypesConfigSelector } from '../../../../ducks/representations'
import { ORDER_TRANSPORTS_CARD } from '../../../../constants/grids'

const Driver = ({
    form,
    onChange,
    uniquenessNumberCheck,
    settings,
    error,
}) => {
    const { t } = useTranslation()

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, ORDER_TRANSPORTS_CARD))

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="carrierId"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="carrierInn"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="driverId"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                            forSelectId={form['carrierId']?.value}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="passportSeries"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="passportNumber"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="driverPhone"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="driverLicence"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="vehicleId"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                            forSelectId={form['carrierId']?.value}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="trailerNumber"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="transportTypeId"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="vehicleBrand"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    )
}

export default Driver
