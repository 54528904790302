import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Confirm, Dropdown } from 'semantic-ui-react';
import OrderTransportSettingCard from './components/orderTransportSettingCard';
import {
    actionsCardSelector
} from '../../ducks/gridActions';
import { orderTransportSettingSelector } from '../../ducks/profile';
import { 
    settingDataSelector,
    settingProgressSelector,
    settingEditErrorSelector,
    getOrderTransportSettingRequest,
    editOrderTransportSettingRequest,
 } from '../../ducks/gridCard';

const OrderTransportSetting = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });

    const orderTransportSettings = useSelector(state => orderTransportSettingSelector(state));
    const settingData = useSelector(state => settingDataSelector(state));
    const loading = useSelector(state => settingProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const error = useSelector(state => settingEditErrorSelector(state));
    
    useEffect(() => {
        dispatch(getOrderTransportSettingRequest());
    }, []);
    

    useEffect(() => {
        setForm(form => ({
            ...settingData,
            ...form,
        }));
    }, [settingData]);


    const saveOrEditForm = (callbackFun) => {
        dispatch(
            editOrderTransportSettingRequest({
                params: form,
                callbackSuccess: () => {
                    setNotChangeForm(true);
                    dispatch(getOrderTransportSettingRequest());
                }
            })
        )
    };


    const handleSave = () => {
        saveOrEditForm();
    };

    const handleCancel = () => {
        dispatch(getOrderTransportSettingRequest());
        setForm(settingData);
        setNotChangeForm(true);
    }


    const onChangeForm = useCallback((e, { name, value }) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setNotChangeForm(false);
    }, []);

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                </>
            );
        },
        [form],
    );

    const getActionsHeader = useCallback(
        () => {
            return (
                <div
                    className="grid-card-header"
                    style={{padding: "3%"}}
                >
                    
                    <Button
                        color="blue"
                        disabled={notChangeForm}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                    <Button color="grey" onClick={handleCancel} disabled={notChangeForm}>
                        {t('CancelButton')}
                    </Button>
                </div>
            );
        },
        [form, actions, notChangeForm],
    );


    return (
        <React.Fragment>
            <OrderTransportSettingCard
                    {...props}
                    columns={orderTransportSettings && orderTransportSettings.columns}
                    form={form}
                    error={error}
                    loading={loading}
                    title={t(orderTransportSettings.name)}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    onChangeForm={onChangeForm}
                />
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </React.Fragment>
    );
};

export default OrderTransportSetting;
