import React from 'react';
import { useSelector } from 'react-redux';
import CardLayout from '../../../components/CardLayout';
import { userPermissionsSelector } from '../../../ducks/profile';
import DeadlineBacklight from './orderTransportSettingTabs/deadlineBacklight';
import DriverAndTcApproval from './orderTransportSettingTabs/driverAndTcApproval';
import IntegrationLag from './orderTransportSettingTabs/integrationLag';
import VatSize from './orderTransportSettingTabs/vatSize';
import FileSizeSettings from './orderTransportSettingTabs/fileSizeSettings';
import BlockMessage from './orderTransportSettingTabs/blockMessage';
import AuctionDuration from "./orderTransportSettingTabs/auctionDuration";



const OrderTransportSettingCard = ({
    form,
    onChangeForm,
    name,
    id,
    load,
    isNotUniqueNumber,
    uniquenessNumberCheck,
    settings,
    error,
    title,
    onClose,
    actionsFooter,
    actionsHeader,
    loading,
    columns,
    goToSoldTo,
}) => {
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    console.log(columns)
    const getPanes = () => {
        let obj = [
            {
                menuItem: 'deadlineBacklight',
                render: () => (
                    <DeadlineBacklight
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'driverAndTcApproval',
                render: () => (
                    <DriverAndTcApproval
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'integrationLagTab',
                render: () => (
                    <IntegrationLag
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'vatSize',
                render: () => (
                    <VatSize
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'fileSizeSettings',
                render: () => (
                    <FileSizeSettings
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'blockMessage',
                render: () => (
                    <BlockMessage
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
            {
                menuItem: 'auctionDuration',
                render: () => (
                    <AuctionDuration
                        form={form}
                        error={error}
                        onChange={onChangeForm}
                        columns={columns}
                    />
                ),
            },
        ];
        /*{
                menuItem: 'returns',
                render: () => (
                    <Returns
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            },*/


        return obj;
    };

    return (
        <CardLayout
            title={title}
            loading={loading}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            error={error}
            noLeftArrowButton={true}
        />
    );
};

export default OrderTransportSettingCard;
