import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE } from '../../constants/settings';

import { Dropdown, Form } from 'semantic-ui-react';

import './style.scss';
import {getLookupRequest, listFromMultiSelect, listFromSelectSelector, listSelector, valuesListSelector} from '../../ducks/lookup';

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    label,
    name,
    fieldName,
    text,
    multiple,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    children,
    sourceParams,
    displayNameKey,
    upward,
    isFilter,
    isBulkUpdateOnly
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let [openUpward, setOpenUpward] = useState(false);
    let [counter, setCounter] = useState(PAGE_SIZE);
    let [filter, setFilter] = useState('');
    const [ searchQuery, setSearchQuery ] = useState('');

    const handleOpen = () => {
        dispatch(
            getLookupRequest({
                name: isFilter ? fieldName : source,
                isFilter,
                isForm: true,
                entities: source,
                sourceParams,
            }),
        );
    }

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;

        if (scrollTop + clientHeight >= scrollHeight - 1) {
            setCounter((prevCounter) => prevCounter + PAGE_SIZE);
        }
    };

    const handleSearchChange = (e, {searchQuery}) => {
        if (!isFilter) {
            valuesList.forEach((item, index) => {
                if (!!searchQuery && item.name.toLowerCase().includes(searchQuery.toLowerCase())){
                    setCounter(prevCounter => prevCounter + index)
                }
            })
        } else {
            filterValuesList.forEach((item, index) => {
                if (!!searchQuery && item.name.toLowerCase().includes(searchQuery.toLowerCase())){
                    setCounter(prevCounter => prevCounter + index)
                }
            })
        }
    }

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, source, isTranslate, t),
    );

    useEffect(() => {
        if (isFilter) {
            setCounter(550);
        }
    }, []);
    

    const filterValuesList = useSelector(state => listFromSelectSelector(state, fieldName, t, filter, isTranslate, isBulkUpdateOnly ? true : false),);

    const handleChange = (e, { value }) => {
        onChange(e, { value: value ? !isFilter ? valuesList.filter(item => value.includes(item.value)) : filterValuesList.map(i => {
            return {
                isBulkUpdateOnly: false,
                isFilterOnly: false,
                name: i.name,
                text: i.text,
                value: i.value
            }
        }).filter(item => value.includes(item.value)) : null, name });
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(displayNameKey ? displayNameKey : text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    placeholder={placeholder}
                    fluid
                    clearable={clearable}
                    selection
                    loading={loading}
                    search
                    text={textValue}
                    error={error}
                    multiple
                    disabled={isDisabled}
                    value={value ? value.map(item => item.value) : null}
                    options={!isFilter ? valuesList : filterValuesList.map(i => {
                        return {
                            isBulkUpdateOnly: false,
                            isFilterOnly: false,
                            name: i.name,
                            text: i.name,
                            value: i.value
                        }
                    }).slice(0, counter)}
                    onChange={handleChange}
                    onOpen={handleOpen}
                    onScroll={handleScroll}
                    onSearchChange={handleSearchChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    upward={upward}
                />
                {children && children}
            </div>
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(MultiSelect);
