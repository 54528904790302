export default result => {
    const { data, headers } = result;
    const fileNameBlock = headers['content-disposition'].split('; ')[1];
    const startFileNameIndex = fileNameBlock.indexOf('filename=') + 9;
    const filename = decodeURI(fileNameBlock.substring(startFileNameIndex).replace(/\"/g, ''));

    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}
