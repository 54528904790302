import React from 'react';
import {Button, Form, Grid} from 'semantic-ui-react';
import FormField from '../formField';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { ORDER_TRANSPORT_AUCTIONS_CARD } from '../../../../constants/grids';

const AuctionInfo = ({ form, onChange, uniquenessNumberCheck, settings, error, handleClick }) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, ORDER_TRANSPORT_AUCTIONS_CARD));

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="dateTimeEndAuction"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="initialCost"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="bestOffer"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="vatOfferedByTc"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    { !!columnsConfig.costOfferedByTc && (
                                        <>
                                            <Grid.Column>
                                                <FormField
                                                    name="costOfferedByTc"
                                                    columnsConfig={columnsConfig}
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button
                                                    content={t('placeBet')}
                                                    onClick={() => handleClick()}
                                                    style={{marginTop: "22px"}}
                                                />
                                            </Grid.Column>
                                        </>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default AuctionInfo;
