import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Icon, Input, Popup } from 'semantic-ui-react';

const Bool = ({ value, sort, name, setSort, text, onChange, emptyValue }) => {

    let [ filterValues, setFilterValues ] = useState([]);

    const items = useMemo(() => {
        let obj = [
            {
                text: 'Да',
                value: true,
            },
            {
                text: 'Нет',
                value: false,
            },
        ];

        if (emptyValue === 'allowed') {
            obj.push({
                text: 'Не выбрано',
                value: 'null',
            });
        }

        return obj;
    }, [emptyValue]);

    useEffect(
        () => {
            value && setFilterValues(value);
        },
        [value],
    )

    const { t } = useTranslation();

    const handleChange = newValue => {
        let values = (filterValues && filterValues.length !== 0 ? filterValues.split('|') : []).map(item =>
            item === 'true' ? true : item === 'false' ? false : 'null',
        );
        if (values.some(x => x === newValue)) {
            values.splice(values.indexOf(newValue), 1);
        } else {
            values.push(newValue);
        }

        setFilterValues(values.join('|'));

    };

    const handleApplyFilter = (e) => {
        if (onChange !== undefined && filterValues && filterValues.length !== 0) onChange(e, { name: name, value: filterValues });
    }

    const handleRestClick = () => {
        setFilterValues([]);
        if (onChange !== undefined) onChange(null, { name: name, value: null });
    };

    

    let content = (
        <Form>
            <Button style={{width: "100%"}} onClick={handleApplyFilter}>{t("apply_filter_btn")}</Button>
            <div className="reset-selected">
                <span onClick={handleRestClick}>{t('reset_selected')}</span>
            </div>
            {/* <label className="label-in-popup">{t(name)}</label>*/}
            {/*  <div className="boolean-facet-values">*/}
            {items &&
                items.map(x => {
                    return (
                        <Form.Field key={x.text}>
                            <Checkbox
                                label={x.text}
                                name="checkboxRadioGroup"
                                value={x.value}
                                checked={filterValues.includes(x.value)}
                                onChange={() => handleChange(x.value)}
                            />
                        </Form.Field>
                    );
                })}
            {/*</div>*/}
        </Form>
    );

    return (
        <div className="facet-input">
            {content}
            {/* <Popup
                trigger={
                    <Input
                        fluid
                        label={{ basic: true, content: '' }}
                        labelPosition="right"
                        onKeyPress={e => {
                            e.preventDefault();
                        }}
                        placeholder={
                            value !== undefined
                                ? items.find(item => item.value === value) &&
                                  items.find(item => item.value === value).text
                                : t(name)
                        }
                    />
                }
                content={content}
                on="click"
                hoverable
                className="from-popup"
                position="bottom left"
            />
            <Button
                className={`sort-button sort-button-up ${
                    sort === 'asc' ? 'sort-button-active' : ''
                }`}
                name={name}
                value="asc"
                onClick={setSort}
            >
                <Icon name="caret up" />
            </Button>
            <Button
                className={`sort-button sort-button-down ${
                    sort === 'desc' ? 'sort-button-active' : ''
                }`}
                name={name}
                value="desc"
                onClick={setSort}
            >
                <Icon name="caret down" />
            </Button>*/}
        </div>
    );
};

export default Bool;
