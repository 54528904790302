import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react'
import { uploadFileRequest, uploadProgressSelector, getDocumentSource, documentSourceSelector } from '../../ducks/documents'
import { toast } from 'react-toastify'

const FileUploader = ({ document, onChange }) => {
    const [active, setActive] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const loading = useSelector(state => uploadProgressSelector(state))
    const documentSource = useSelector(state => documentSourceSelector(state))

    const onDragEnter = () => {
        setActive(true)
    }

    const onDragLeave = () => {
        setActive(false)
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        e.preventDefault()
        setActive(false)
        uploadFile(e, e.dataTransfer.files[0])
    }

    let [src, setSrc] = useState(document && document.fileId && documentSource ? documentSource : '')

    useEffect(
        () => {
            setSrc(document && document.fileId && documentSource ? documentSource.data : '')
        },
        [documentSource])

    const loaded = document && document.fileId,
        name = document ? document.name : '',
        extension = loaded ? name.substr(name.lastIndexOf('.') + 1) : '',
        // isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase()),
        isImage = false,
        labelClass = `uploader ${loaded && 'loaded'} ${active && 'dragndrop'}`






    const uploadFile = (e, file) => {
        // this.props.onChange();
        let form = new FormData()
        file = file || e.target.files[0]

        form.append('formFile', file)

        dispatch(
            uploadFileRequest({
                form,
                fileName: file.name,
                callbackSuccess: id => {
                    onChange(id, file.name)
                    dispatch(getDocumentSource(id))
                },
            }),
        )
    }






    let preview = loading ? (
        <Dimmer active inverted>
            <Loader size="large">{t('Loading')}</Loader>
        </Dimmer>
    ) : (
        <Segment placeholder>
            <Header icon>
                <Icon name={loaded ? 'file outline' : 'upload'} />
            </Header>
            <p style={{ overflow: "hidden" }}>{name}</p>
        </Segment>
    )


    if (loaded && isImage) preview = <img src={src} className={loaded && 'loaded'} />

    return (
        <label
            className={labelClass}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {preview}

            <input type="file" onChange={uploadFile} />
        </label>
    )
}

export default FileUploader
