import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FormField from '../formField'
import {
    BIG_TEXT_TYPE,
    DATE_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
} from '../../../../constants/columnTypes';
import { columnsTypesConfigSelector } from '../../../../ducks/representations'
import { getRegisterDefaultsRequest } from '../../../../ducks/gridActions';
import { FINE_DM_BILL_CARD } from '../../../../constants/grids'

const CreateFineDmBill = ({ type, form = {}, onChange, isNotUniqueNumber, uniquenessNumberCheck, error }) => {
    
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegisterDefaultsRequest({type}))
    }, [])

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, FINE_DM_BILL_CARD))


    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="penaltyDmType"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isRequired
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="triggerTime"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isDisabled
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="fineAmount"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="idleTripPointId"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="toleranceFact"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="fineAmountFact"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isRequired
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="isBill"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="isManual"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isDisabled
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default CreateFineDmBill;
