import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Requirements from './transportAuctionsTabs/requirements';
import Route from './transportAuctionsTabs/route';
import Documents from './shared/documents';
import History from './shared/history';

import CardLayout from '../../../components/CardLayout';
import { isTripPointsGridSelector } from '../../../ducks/gridList';
import { GRID_LIST_LINK } from '../../../router/links';
import { userPermissionsSelector } from '../../../ducks/profile';
import AuctionInfo from './transportAuctionsTabs/auctionInfo';
import BetHistories from './transportAuctionsTabs/betHistories';

const TransportAuctionsCard = ({
    form,
    onChangeForm,
    name,
    id,
    load,
    isNotUniqueNumber,
    uniquenessNumberCheck,
    settings,
    error,
    title,
    onClose,
    actionsFooter,
    actionsHeader,
    loading,
    goToSoldTo,
    handleClick,
}) => {
    const { t } = useTranslation();

    const userPermissions = useSelector((state) => userPermissionsSelector(state));
    const isTripPointsAvailable = useSelector((state) => isTripPointsGridSelector(state));

    const handleOpenRouteGrid = () => {
        localStorage.setItem('orderIdForTripPoint', id);
    };

    const getPanes = () => {
        let obj = [
            {
                menuItem: 'auctionInfo',
                render: () => (
                    <AuctionInfo
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                        handleClick={handleClick}
                    />
                ),
            },
            {
                menuItem: 'requirements',
                render: () => (
                    <Requirements
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'route',
                render: () => (
                    <Route
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'betHistories',
                render: () => <BetHistories cardId={id} form={form} />,
            },
            {
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            },
            // {
            //     menuItem: 'position',
            //     render: () => (
            //         <Position
            //             form={form}
            //             onChange={onChangeForm}
            //             gridName={name}
            //             load={load}
            //             error={error}
            //             settings={settings}
            //         />
            //     ),
            // },
        ];
        /*{
                menuItem: 'returns',
                render: () => (
                    <Returns
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            },*/

        if ((userPermissions.includes(4) || userPermissions.includes(5)) && form.id) {
            obj.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        load={load}
                        isEditPermissions={userPermissions.includes(5)}
                    />
                ),
            });
        }

        if (userPermissions.includes(6) && form.id) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            });
        }

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />
    );
};

export default TransportAuctionsCard;
