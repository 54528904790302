import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Popup, Table, Loader } from 'semantic-ui-react';
import Date from '../../components/BaseComponents/Date';
import CheckBox from '../../components/BaseComponents/Checkbox';
import { dateToString } from '../../utils/dateTimeFormater';
import {
    columnsSelector,
    filtersSelector,
    reportExportToExcelRequest,
    formReportRequest,
    reportDataSelector,
    formReportProgressSelector,
    exportProgressSelector,
    clearReportData,
} from '../../ducks/reports';
import {
    Chart as ChartJS,
    registerables
  } from 'chart.js';
import FormField from '../customGrid/components/formField';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/dateTimeFormater';
import Block from '../../components/CardLayout/components/block';
import CellValue from '../../components/ColumnsValue';
import BodyCell from '../../components/SuperGrid/components/body_cell';
import FacetField from '../../components/FilterComponents';
import { ORDERS_GRID } from '../../constants/grids';
import Icon from '../../components/CustomIcon';
import generate from "@babel/generator";
import { Chart } from 'react-chartjs-2';
import moment from 'moment';

const Report = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let [params, setParams] = useState({
        startDate: dateToString(),
        endDate: dateToString(),
        deliveryType: true,
        client: true,
        daily: false,
    });

    ChartJS.register(...registerables);

    let [isDisabledBtn, setIsDisabledBtn] = useState(true);

    
    let [sort, setSort] = useState({});

    useEffect(() => {
        dispatch(clearReportData());
    }, []);

    const reportName = props.match.params.name;

    const columns = useSelector(state => columnsSelector(state, reportName));
    const filters = useSelector(state => filtersSelector(state, reportName));
    const exportLoader = useSelector(state => exportProgressSelector(state));
    const reportData = useSelector(state => reportDataSelector(state));
    const formReportLoader = useSelector(state => formReportProgressSelector(state));

    const getInitialFilters = () => {
        const initialFilters = {};
        Object.keys(filters).forEach(i => {
            initialFilters[i] = null;
        })

        let currentYear = moment()._d.getFullYear();
        initialFilters.chartYear = currentYear;
        initialFilters.showTripsDetails = false;

        return initialFilters;
    }

    let [filter, setFilter] = useState(getInitialFilters());

    /* useEffect(() => {
        setFilter({});
        setSort({});
    }, [columns])*/


    const handleChangeParams = (e, { name, value }) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        setParams(newParams);

        if (!isDisabledBtn && !newParams.deliveryType && !newParams.client && !newParams.daily) {
            setIsDisabledBtn(true);
        } else {
            setIsDisabledBtn(false);
        }
    };

    const multiSelectArrayToString = (value) => {
        let str = '';
        value && value.forEach((i, index) => {
            str = index < value.length - 1 ? str + i.value + "|" : str + i.value
        })

        return str;
    }

    const exportReportToExcel = () => {
        dispatch(reportExportToExcelRequest({name: reportName, params: {filter: {tablePeriod: filter.tablePeriod ? 
            `${filter.tablePeriod.startDate ? formatDate(filter.tablePeriod.startDate) : ''}-${filter.tablePeriod.endDate ? formatDate(filter.tablePeriod.endDate) : ''}` : '',
            distributionCenter: filter.distributionCenter ? multiSelectArrayToString(filter.distributionCenter) : '',
            transportCompany: filter.transportCompany ? multiSelectArrayToString(filter.transportCompany) : '',
            transportationType: filter.transportationType ? multiSelectArrayToString(filter.transportationType) : '',
            chartYear: filter.chartYear ? filter.chartYear : '',
            showTripsDetails: filter.showTripsDetails ? filter.showTripsDetails : ''
            
        }
        }}));
    }

    const formReport = () => {
        dispatch(formReportRequest({name: reportName, params: {filter: {tablePeriod: filter.tablePeriod ? 
            `${filter.tablePeriod.startDate ? formatDate(filter.tablePeriod.startDate) : ''}-${filter.tablePeriod.endDate ? formatDate(filter.tablePeriod.endDate) : ''}` : '',
            distributionCenter: filter.distributionCenter ? multiSelectArrayToString(filter.distributionCenter) : '',
            transportCompany: filter.transportCompany ? multiSelectArrayToString(filter.transportCompany) : '',
            transportationType: filter.transportationType ? multiSelectArrayToString(filter.transportationType) : '',
            chartYear: filter.chartYear ? filter.chartYear : '',
            showTripsDetails: filter.showTripsDetails ? filter.showTripsDetails : ''

        }
        }}));
    }

    const handleChangeFilter = (e, { name, value }) => {
        setFilter({
            ...filter,
            [name]: value,
        })
    };

    const getCellStyle = (cellStyle) => {
        const style = {};
        cellStyle.forEach(i => {
            switch (i) {
                case 'textAlignRight': 
                    style.textAlign = 'right';
                    break;
                case 'textAlignCenter': 
                    style.textAlign = 'center';
                    break;
                case 'textAlignLeft': 
                    style.textAlign = 'left';
                    break;
                case 'boldText': 
                    style.fontWeight = 'bold';
                    break;
                case 'italicText': 
                    style.fontStyle = 'italic';
                    break;
                case 'redColor': 
                    style.backgroundColor = 'rgb(255, 175, 175)';
                    break;
                case 'yellowColor': 
                    style.backgroundColor = 'rgb(255, 238, 149)';
                    break;
            }
        })
        return style;
    }

    const getRowStyle = (rowStyle) => {
        const style = {};
        rowStyle.forEach(i => {
            switch (i) {
                case 'textAlignRight': 
                    style.textAlign = 'right';
                    break;
                case 'textAlignCenter': 
                    style.textAlign = 'center';
                    break;
                case 'textAlignLeft': 
                    style.textAlign = 'left';
                    break;
                case 'boldText': 
                    style.fontWeight = 'bold';
                    break;
                case 'italicText': 
                    style.fontStyle = 'italic';
                    break;
                case 'redColor': 
                    style.backgroundColor = 'rgb(255, 175, 175)';
                    break;
                case 'yellowColor': 
                    style.backgroundColor = 'rgb(255, 238, 149)';
                    break;
            }
        })
        return style;
    }

    const clearFilter = () => {
        setFilter({});
    };

    const converChartData = (chartData) => {
        const convertedData = {labels: {}, datasets: []};
        convertedData.labels = chartData.labels;
        convertedData.datasets = chartData.datasets.map(i => {
            let el = {...i}
            return el;
        })
        return convertedData;
    }

    // const exportExcel = () => {
    //     dispatch(reportExportToExcelRequest(mapData()));
    // };

    const item = {
        menuItem: 'report',
        render: () => (
            <Table>
                <Table.Header>
                    {/* <Table.Row>
                        {columns.map((column, i) => (
                            <Table.HeaderCell>
                                <FacetField
                                    key={'facet' + column.name}
                                    notWrapHeader
                                    index={i}
                                    gridName={ORDERS_GRID}
                                    name={column.name}
                                    displayNameKey={column.displayNameKey}
                                    sort={sort && sort.name === column.name ? sort.desc : null}
                                    setSort={setSort}
                                    type={column.type}
                                    value={filter[column.name]}
                                    filters={filter}
                                    setFilter={handleChangeFilter}
                                    source={column.source}
                                />
                            </Table.HeaderCell>
                        ))}
                    </Table.Row> */}
                </Table.Header>
                <Table.Body>
                    {/* {report.map((row, indexRow) => (
                        <Table.Row key={row.id}>
                            {columns.map((column, indexColumn) => (
                                <Table.Cell key={`${row.id}_${column.name}`}>
                                    <CellValue
                                        {...column}
                                        indexRow={indexRow}
                                        indexColumn={indexColumn}
                                        value={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].value
                                                : row[column.name]
                                        }
                                        valueText={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].name
                                                : null
                                        }
                                        valueTooltip={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].tooltip
                                                : null
                                        }
                                        rowId={row.id}
                                        t={t}
                                    />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))} */}
                </Table.Body>
            </Table>
        ),
        actions: () => [
            <Popup
                content={t('exportToExcel')}
                position="bottom right"
                trigger={
                    <Button
                        icon="download"
                    />
                }
            />,
            <Popup
                content={t('reset_filters')}
                position="bottom right"
                trigger={
                    <Button
                        icon
                        className={`clear-filter-btn`}
                    >
                        <Icon name="clear-filter" />
                    </Button>
                }
            />,
        ],
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_params" style={{height: "820px", overflow: "auto"}}>
                    <h2>{t(reportName)}</h2>
                    <Form>
                        <Grid>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <FormField
                                        name="chartYear"
                                        columnsConfig={filters}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column></Grid.Column>
                                <Grid.Column></Grid.Column>
                                <Grid.Column textAlign="right">
                                    <Button 
                                        primary 
                                        disabled={!filter.chartYear || !filter.tablePeriod || (filter.tablePeriod && !filter.tablePeriod.startDate)}
                                        loader={formReportLoader}
                                        onClick={formReport}
                                    >
                                        {t('Generate report')}
                                    </Button>
                                    <Popup
                                        content={
                                            t('exportToExcel') // todo
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon="download"
                                                loading={exportLoader}
                                                disabled={!filter.chartYear || !filter.tablePeriod || (filter.tablePeriod && !filter.tablePeriod.startDate)}
                                                onClick={exportReportToExcel}
                                            />
                                        }
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <FormField
                                        name="tablePeriod"
                                        columnsConfig={filters}
                                        isPeriod={true}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <FormField
                                        name="transportCompany"
                                        columnsConfig={filters}
                                        placeholder={t('All')}
                                        isFilter={true}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <FormField
                                        name="transportationType"
                                        columnsConfig={filters}
                                        placeholder={t('All')}
                                        isFilter={true}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <FormField
                                        name="distributionCenter"
                                        columnsConfig={filters}
                                        placeholder={t('All')}
                                        isFilter={true}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <FormField
                                        name="showTripsDetails"
                                        columnsConfig={filters}
                                        isFilter={true}
                                        form={filter}
                                        onChange={handleChangeFilter}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                            {formReportLoader ?
                                <Loader active={formReportLoader} size="huge">
                                    Loading
                                </Loader> :
                                reportData && (reportData.chart || reportData.table) && 
                                <div style={{marginTop: "2rem", border: "1px solid #e6e6e6"}}>
                                    {reportData && reportData.chart &&
                                        <div style={{marginTop: "2rem"}}>
                                            <h3 style={{textAlign: "center"}}>{t(reportData.chart.chartDisplayName)}</h3>
                                            <Chart 
                                                type='bar' 
                                                data={converChartData(reportData.chart)} 
                                            />
                                        </div>
                                        
                                    }
                                    {reportData &&
                                    <div style={{marginTop: "2rem", borderTop: "1px solid #e6e6e6"}}>
                                        <Table celled style={{border: "none"}}>
                                            <Table.Header>
                                                <Table.Row>
                                                    {columns.map((column, i) => (
                                                        <Table.HeaderCell>
                                                            <FacetField
                                                                key={'facet' + column.name}
                                                                notWrapHeader
                                                                index={i}
                                                                gridName={ORDERS_GRID}
                                                                name={column.name}
                                                                displayNameKey={column.displayNameKey}
                                                                noSort
                                                                noFilter
                                                                type={column.type}
                                                                source={column.source}
                                                            />
                                                        </Table.HeaderCell>
                                                    ))}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {reportData.table && reportData.table.length ? reportData.table.map((row, indexRow) => (
                                                    <Table.Row style={row.rowStyles && getRowStyle(row.rowStyles)} key={row.id}>
                                                        {columns.map((column, indexColumn) => (
                                                            <Table.Cell style={row.cellStyles && row.cellStyles[column.name] && getCellStyle(row.cellStyles[column.name])} 
                                                            key={`${row.id}_${column.name}`}>
                                                                <CellValue
                                                                    {...column}
                                                                    indexRow={indexRow}
                                                                    indexColumn={indexColumn}
                                                                    value={
                                                                        row[column.name] && typeof row[column.name] === 'object'
                                                                            ? row[column.name].value
                                                                            : row[column.name]
                                                                    }
                                                                    valueText={
                                                                        row[column.name] && typeof row[column.name] === 'object'
                                                                            ? row[column.name].name
                                                                            : null
                                                                    }
                                                                    valueTooltip={
                                                                        row[column.name] && typeof row[column.name] === 'object'
                                                                            ? row[column.name].tooltip
                                                                            : null
                                                                    }
                                                                    rowId={row.id}
                                                                    t={t}
                                                                />
                                                            </Table.Cell>
                                                        ))}
                                                    </Table.Row>
                                                )) : null}
                                            </Table.Body>
                                        </Table>
                                    </div>}
                                </div>
                            }
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Report;
