import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CellValue from '../../ColumnsValue';
import { Button, Form, Icon, Loader, Modal, Popup, Table, Message, Grid } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { refuseProgressSelector, progressMassUpdateSelector } from '../../../ducks/gridActions';
import FormField from '../../BaseComponents';
import SubTable from '../../SuperGrid/components/subTableGrid';
import { columnsTypesConfigSelector } from '../../../ducks/representations';
import { LINK_TYPE } from '../../../constants/columnTypes';
import _ from 'lodash';

const ModalComponent = ({ element, props, children }) => {
    if (!element) {
        return <>{children}</>;
    }
    return React.cloneElement(element, props, children);
};

const BodyCell = ({
    value,
    valueText,
    valueTooltip,
    column,
    loadList,
    indexRow,
    backlights,
    indexColumn,
    modalCard,
    gridName,
    t,
    checkForEditing,
    invokeMassUpdate,
    subtableUpdate,
    status,
    rowId,
    rowNumber,
    valueTable,
    subTableText,
    subTableColumns,
    tableName,
    subTableDefaultValues,
    readOnlyColumns,
    cardLink,
    goToCard,
    canEdit,
    refusalChange,
    row,
    id
}) => {
    const contextRef = useRef(null);
    const progressLoader = useSelector((state) => progressMassUpdateSelector(state));
    

    let [open, setOpen] = useState(false);
    let [isDoubleClick, setIsDoubleClick] = useState(null);
    let [openEditWindow, setOpenEditWindow] = useState(false);
    let [isHover, setIsHover] = useState(false);
    let [isChanged, setIsChanged] = useState(false);
    let [openConfirmModal, setOpenConfirmModal] = useState(false);
    let [tableRows, setTableRows] = useState(valueTable && valueTable.slice(0).map(item => item));
    let [subTableEditing, setSubTableEditing] = useState(false);
    let [valueForm, setValue] = useState(null);
    let [subTableValue, setSubTableValue] = useState(valueText ? valueText : null);
    let [progress, setProgress] = useState(false);
    let [needUpdateModal, setNeedUpdateModal] = useState(null);
    let [needUpdateValue, setNeedUpdateValue] = useState({});
    let [showNeedUpdateModal, setShowNeedUpdateModal] = useState(false);

    let timer = 0;
    let prevent = false;

    useEffect(() => {
        setTableRows(valueTable);
    }, [valueTable]);

    useEffect(() => {
        setSubTableValue(valueText);
    }, [valueText]);

    useEffect(() => {
        if (progressLoader === false) {
            setProgress(progressLoader);
        } 
    }, [progressLoader]);


    const copyToClipboard = () => {
        const text = contextRef.current && contextRef.current.textContent;
        navigator.clipboard &&
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };


    const handleClick = (rowId, fieldName, state, dynamicName) => {
        setProgress(true);
        checkForEditing({
            rowId,
            fieldName,
            dynamicName,
            state,
            forEntity: gridName,
            t,
            callbackSuccess: () => {
                if (canEdit === true || canEdit === null) {
                    handleOpen();
                } else {
                    setProgress(false);
                    toast.error(t(`check_for_editing_failed_${gridName}`, 
                    { fieldName: dynamicName ? dynamicName : t(fieldName) }));
                }
                
            },
            callbackFunc: () => {
                setProgress(false);
            },
        });
    };

    

    

    const handleDoubleClick = (rowId, fieldName, state, dynamicName) => {
        setProgress(true);
        checkForEditing({
            rowId,
            fieldName,
            dynamicName,
            state,
            forEntity: gridName,
            t,
            callbackSuccess: () => {
                if (canEdit === true || canEdit === null) {
                    setOpenEditWindow(true);
                    setValue(valueText ? { value, name: valueText } : value);
                    setIsDoubleClick(null);;
                } else {
                    setProgress(false);
                    toast.error(t(`check_for_editing_failed_${gridName}`, 
                    { fieldName: dynamicName ? dynamicName : t(fieldName) }));
                }
                
            },
            callbackFunc: () => {
                setProgress(false);
            },
        });
    };

    const handleOpen = () => {
        setOpen(true);
        setValue(valueText ? { value, name: valueText } : value);
    };

    const handleClose = () => {
        setOpen(false);
        setValue(null);
    };

    const handleSave = () => {
        setProgress(true);
        handleClose();
        invokeMassUpdate({
            ids: [rowId],
            name: gridName,
            field: column.name,
            modalCallback: (needUpdateField) => {
                setShowNeedUpdateModal(true);
                setNeedUpdateModal(needUpdateField);
                setNeedUpdateValue(row[needUpdateField.fieldName]);
            },
            value: valueForm,
            callbackSuccess: () => {
                loadList(false, true);
                setProgress(false);
            }
        });
    };


    const handleChange = useCallback((e, { value }) => {
        console.log("BODYCELL", value);
        setValue(value);
    }, []);

    const handleCellClick = e => {
        timer = setTimeout(function() {
            if (!prevent) {
                column.type !== LINK_TYPE ? handleClick(rowId, column.name, status, column.dynamicDisplayName ? column.dynamicDisplayName : column.displayNameKey ? column.displayNameKey : column.name) : e.stopPropagation();
            }
            prevent = false;
          }, 400);
        
    };

    const handleCellDoubleClick = e => {
        console.log("doubleClick");
        clearTimeout(timer);
        prevent = true;
        column.type !== LINK_TYPE ? handleDoubleClick(rowId, column.name, status, column.dynamicDisplayName ? column.dynamicDisplayName : column.displayNameKey ? column.displayNameKey : column.name) : e.stopPropagation();
    };

    const cellDoubleClick = e => {
        clearTimeout(timer);
        prevent = true;
        column.type !== LINK_TYPE ? copyToClipboard() : e.stopPropagation();
    };

    const handleSaveSubTable = () => {
        setProgress(true);
        subtableUpdate({
            name: gridName,
            value: tableRows,
            callbackSuccess: () => {
                loadList(false, true);
                setProgress(false);
                setOpen(false);
                setOpenConfirmModal(false);
                handleClose();
                setTableRows(valueTable && valueTable.slice(0).map(item => item));
                setIsChanged(false);
            },
            callbackError: () => {
                setTableRows(valueTable && valueTable.slice(0).map(item => item));
            }
        });
        
        
    }

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, gridName));


    const handleSaveValueOfSubtable = () => {
        const newRows = tableRows.slice();
        newRows[0].numberAvailableVehiclesTc = subTableValue;
        setProgress(true);
        subtableUpdate({
            name: gridName,
            value: newRows,
            callbackSuccess: () => {
                loadList(false, true);
                setProgress(false);
                setOpen(false);
                setOpenConfirmModal(false);
                setOpenEditWindow(false);
                handleClose();
                setTableRows(valueTable && valueTable.slice(0).map(item => item));
                setIsChanged(false);
            },
            callbackError: () => {
                setTableRows(valueTable && valueTable.slice(0).map(item => item));
            }
        });
    }


    const getModalCard = useCallback(() => {
        return (
            <ModalComponent
                element={modalCard()}
                props={{
                    id: rowId,
                    status,
                    loadList,
                    title: `edit_${gridName}`,
                }}
                key={`modal_${rowId}`}
            />
        );
    }, []);



    return (
        <>{column.type !== "SubTable" ? <>
            <Table.Cell className="value-cell" 
            style={backlights !== null ? {backgroundColor: backlights[column.name]} : {}}>
                <div className="cell-grid" >
                    <div
                        className={`cell-grid-value ${value && value !== ' ' ? '' : 'cell-grid-value_empty'}`}
                        ref={contextRef}
                        onClick={handleCellClick}
                        onDoubleClick={cellDoubleClick}
                    >
                        <CellValue
                            {...column}
                            indexRow={indexRow}
                            indexColumn={indexColumn}
                            value={value}
                            valueText={valueText}
                            valueTooltip={valueTooltip}
                            subTableText={subTableText}
                            width={column.width}
                            gridName={gridName}
                            rowId={rowId}
                            t={t}
                            goToCard={goToCard}
                        />
                    </div>
                    <div>
                        {progress ? (
                            <Loader active={true} size="mini" />
                        ) : null}
                    </div>
                </div>
            </Table.Cell>
                <Modal open={open} size="tiny" closeOnDimmerClick={false}>
                    <Modal.Header>
                        {t(`edit_${gridName}`, {
                            number: rowNumber,
                            status: t(status),
                        })}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form onSubmit={handleSave}>
                                <FormField {...column} autoFocus value={valueForm} onChange={handleChange} />
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                        <Button
                            color="primary"
                            disabled={_.isEqual(valueForm, value)}
                            onClick={handleSave}
                        >
                            {t('SaveButton')}
                        </Button>
                    </Modal.Actions>
                </Modal> </> :
                <>
                    <Popup
                    style={{padding: "0", zIndex: "10"}}
                    content={<SubTable 
                        t={t} 
                        rows={tableRows}
                        setTableRows={setTableRows}
                        subTableEditing={subTableEditing}
                        setSubTableEditing={setSubTableEditing}
                        subTableDefaultValues={subTableDefaultValues} 
                        columns={subTableColumns[tableName]}
                        handleSaveSubTable={() => handleSaveSubTable()}
                        isChanged={isChanged}
                        setIsChanged={setIsChanged}
                        closeSubTable={() => {
                            loadList(false, true);
                            setTableRows(valueTable && valueTable.slice(0).map(item => item));
                            setOpen(false);
                            setIsChanged(false);
                        }}
                        isHover={isHover}
                        readOnlyColumns={readOnlyColumns}
                        checkForEditing={checkForEditing}/>}
                    on='click'
                    onClose={() => {
                        if (isChanged) {
                            if (!subTableEditing) {
                                setOpenConfirmModal(true);
                            }
                        } else {
                            if (!subTableEditing) {
                                setTableRows(valueTable.slice(0).map(item => item));
                                setOpen(false);
                                loadList(false, true);
                                setIsChanged(false);
                            }
                        }
                        
                        
                    }}
                    open={open && valueTable !== null && valueTable.length > 0}
                    basic
                    trigger={
                        <Table.Cell className="value-cell" 
                        style={backlights !== null ? {backgroundColor: backlights[column.name]} : {}}>
                            {valueTable !== null && valueTable.length > 1 &&
                                <Icon 
                                    name="align justify"
                                    style={{float: "right", marginRight: "-10px", marginTop: "-15px"}}
                                    onMouseOver={() => {
                                        setIsHover(true);
                                        setOpen(true);
                                    }}
                                    onMouseOut={() => {
                                        setIsHover(false);
                                        setOpen(false);
                                    }}
                                />
                            }
                            <div className="cell-grid">
                                <div
                                    className={`cell-grid-value ${value ? '' : 'cell-grid-value_empty'}`}
                                    ref={contextRef}
                                    onClick={handleCellClick}
                                    onDoubleClick={handleCellDoubleClick}
                                >
                                    <CellValue
                                        {...column}
                                        indexRow={indexRow}
                                        indexColumn={indexColumn}
                                        value={value}
                                        valueText={valueText}
                                        valueTooltip={valueTooltip}
                                        subTableText={subTableText}
                                        width={column.width}
                                        gridName={gridName}
                                        rowId={rowId}
                                        t={t}
                                        goToCard={goToCard}
                                    />

                                </div>
                                <div>
                                    {progress ? (
                                        <Loader active={true} size="mini" />
                                    ) : null}
                                </div>
                            </div>
                        
                        </Table.Cell>
                    }
                    >
                   
                </Popup>
                <>
                {/* <Table.Cell className="value-cell" 
                style={backlights !== null ? {backgroundColor: backlights[column.name]} : {}}>
                    <div className="cell-grid" >
                        <div
                            className={`cell-grid-value ${value ? '' : 'cell-grid-value_empty'}`}
                            ref={contextRef}
                            onClick={handleCellClick}
                            onDoubleClick={handleCellDoubleClick}
                            
                        >
                            <CellValue
                                {...column}
                                indexRow={indexRow}
                                indexColumn={indexColumn}
                                value={value}
                                valueText={valueText}
                                valueTooltip={valueTooltip}
                                subTableText={subTableText}
                                width={column.width}
                                gridName={gridName}
                                rowId={rowId}
                                t={t}
                                goToCard={goToCard}
                                
                            />
                        </div>
                        <div>
                            {progress ? (
                                <Loader active={true} size="mini" />
                            ) : (
                                <>
                                    {value !== null ? (
                                        <div className="cell-grid-copy-btn">
                                            <Icon
                                                name="clone outline"
                                                size="small"
                                                onClick={copyToClipboard}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </Table.Cell> */}
                    <Modal open={openEditWindow && valueTable !== null && valueTable.length === 1} size="tiny" closeOnDimmerClick={false}>
                        <Modal.Header>
                            {t(`edit_${gridName}`, {
                                number: rowNumber,
                                status: t(status),
                            })}
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Form onSubmit={handleSaveValueOfSubtable}>
                                    <FormField 
                                        name={t('numberAvailableVehiclesTc')} 
                                        autoFocus 
                                        value={subTableValue} 
                                        onChange={(e, {value}) => setSubTableValue(value)} 
                                    />
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                setOpenEditWindow(false);
                                setValue(null);
                            }}>{t('cancelConfirm')}</Button>
                            <Button
                                color="primary"
                                disabled={_.isEqual(valueForm, value)}
                                onClick={handleSaveValueOfSubtable}
                            >
                                {t('SaveButton')}
                            </Button>
                        </Modal.Actions>
                    </Modal> </>
                </>
                
            }

                <Modal open={showNeedUpdateModal} size="small" closeOnDimmerClick={false}>
                    <Modal.Header>
                        {t(`edit`) + " " + t(needUpdateModal && needUpdateModal.fieldName)}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description className="need-update-modal-description">
                            {needUpdateModal && 
                                <Message info> 
                                    <p>{needUpdateModal.infoMessage}</p>
                                </Message>
                            }
                        </Modal.Description>
                        <Form>
                                <Form.Field>
                                    <Grid>
                                        <Grid.Row columns="1">
                                            <Grid.Column>
                                                <FormField
                                                    {...columnsConfig[needUpdateModal && needUpdateModal.fieldName]}
                                                    name={needUpdateModal && needUpdateModal.fieldName}
                                                    text={needUpdateModal && t(needUpdateModal.fieldName)}
                                                    value={needUpdateValue && needUpdateValue}
                                                    onChange={(e, {value}) => setNeedUpdateValue(value)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    
                                </Form.Field>
                            </Form>   
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            disabled={needUpdateModal ? needUpdateModal.disabledCancelButton : false}
                            onClick={() => {
                            refusalChange({
                                id: needUpdateModal ? needUpdateModal.id : null,
                                fieldName: needUpdateModal ? needUpdateModal.fieldName : null,
                                gridName: gridName,
                                callbackSuccess: () => {
                                    setNeedUpdateModal(null);
                                    setShowNeedUpdateModal(false);
                                    loadList(false, true);
                                    setProgress(false);
                                }
                            });
                        }}>{t('cancelConfirm')}</Button>
                        <Button
                            color="primary"
                            onClick={() => {
                                invokeMassUpdate({
                                    ids: [needUpdateModal ? needUpdateModal.id : null],
                                    name: gridName,
                                    field: needUpdateModal ? needUpdateModal.fieldName : null,
                                    value: needUpdateValue ? needUpdateValue : null,
                                    callbackSuccess: () => {
                                        setShowNeedUpdateModal(false);
                                        setNeedUpdateModal(null);
                                        loadList(false, true);
                                        setProgress(false);
                                    }
                                })
                            }}
                        >
                            {t('SaveButton')}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={openConfirmModal} size="mini" closeOnDimmerClick={false}>
                                <Modal.Header>
                                    {t(`confirm_close`)}
                                </Modal.Header>
                                <Modal.Actions>
                                    <Button onClick={() => {
                                        setOpenConfirmModal(false);
                                        setTableRows(valueTable.slice(0).map(item => item));
                                        setOpen(false);
                                        loadList(false, true);
                                        setIsChanged(false);
                                    }}>{t('cancelConfirm')}</Button>
                                    <Button
                                        color="primary"
                                        onClick={() => handleSaveSubTable()}
                                    >
                                        {t('SaveButton')}
                                    </Button>
                                </Modal.Actions>
                            </Modal>
        </>
    );
};

export default React.memo(
    BodyCell /*, (prevProps, nextProps) => {
    return prevProps.column === nextProps.column &&
        prevProps.loadList === nextProps.loadList &&
        prevProps.indexRow === nextProps.indexRow &&
        prevProps.indexColumn === nextProps.indexColumn &&
        prevProps.modalCard === nextProps.modalCard &&
        prevProps.gridName === nextProps.gridName &&
        prevProps.t === nextProps.t &&
        prevProps.checkForEditing === nextProps.checkForEditing &&
        prevProps.invokeMassUpdate === nextProps.invokeMassUpdate &&
        _.isEqual(prevProps.row, nextProps.row)
}*/,
);
