import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import _, { indexOf } from 'lodash';
import { Confirm, Loader, Tab, Table, Button } from 'semantic-ui-react';
import CustomCheckbox from "../../BaseComponents/CustomCheckbox";
import SubTableCell from './subTableCell';
import { toast } from 'react-toastify';


class SubTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRows: [],
            stateRows: this.props.rows.slice(),
        };
    }


    handleCheck = (e, row) => {
        let newSelectedRows = this.state.selectedRows;
        if (newSelectedRows.indexOf(row) === -1) {
            newSelectedRows.push(row);
        } else {
            newSelectedRows.splice(newSelectedRows.indexOf(row), 1);
        }
        this.setState({selectedRows: newSelectedRows});
    }

    handleCopyRows = () => {
        const newRows = this.state.stateRows.slice();
        
        this.state.selectedRows.forEach(selectedRow => {
            const newRow = Object.assign({}, selectedRow);
            Object.keys(this.props.subTableDefaultValues).forEach(key => {
                newRow[key] = this.props.subTableDefaultValues[key];
            })
            newRows.push(newRow);

        });
        this.setState({stateRows: newRows});
        this.props.setTableRows(newRows);
        this.props.setIsChanged(true);
    }

    handleDeleteRows = () => {
        let newRows = this.props.rows.slice();
        this.state.selectedRows.forEach(selectedRow => {

            if (newRows.indexOf(selectedRow) !== 0) {

                newRows.splice(newRows.indexOf(selectedRow), 1);

            } else {
                toast.error(this.props.t("subtableCantDeleteFirst"));  
            }

        });

        if (newRows.length !== 0) {
            this.props.setTableRows(newRows);
            this.setState({stateRows: newRows});
            this.props.setIsChanged(true);
            this.setState({selectedRows: []});


        } else {
            toast.error(this.props.t("subtableAtLeastOne"));
        }
        
    }

    setSelectedAll = () => {
        if (this.state.selectedRows.length === this.state.stateRows.length) {
            this.setState({selectedRows: []})
        } else {
            const newRows = this.props.rows.slice();
            this.setState({selectedRows: newRows})
        }
        
    }


    changeCellValue = (rowIndex, value, name) => {
        const newRows = this.state.stateRows.slice();
        newRows.find((item, index) => index === rowIndex && item)[name] = value;
        this.setState({stateRows: newRows});
        this.props.setIsChanged(true);
    }

    

    render() {
        const {
            columns = [],
            rows = [],
            goToCard,
            actions,
            isShowActions,
            selectedRows,
            loadList,
            disabledCheck,
            name,
            progress,
            t,
            checkForEditing,
            setSubTableEditing,
            subTableEditing,
            handleSaveSubTable,
            closeSubTable,
            readOnlyColumns,
            isChanged,
            isHover,
            setTableRows,
            invokeMassUpdate,
        } = this.props;


        const headerRow = columns && columns.length !== 0 ? columns.map(column => {
            return (
                <Table.Cell className={readOnlyColumns.find(col => column.name === col) && "cell-grid-disabled"}>
                    {column.dynamicDisplayName ? t(column.dynamicDisplayName) : t(column.displayNameKey)}
                </Table.Cell>
            )
        }) : "";


        return (
            <>
            {!isHover && 
                <div className="sub-table-buttons__upper">
                    <Button disabled={this.state.selectedRows.length === 0} 
                    onClick={this.handleCopyRows}>{t("copy_btn")}</Button>
                    <Button disabled={this.state.selectedRows.length === 0} 
                    onClick={this.handleDeleteRows}>{t("delete")}</Button>
                </div>
            }
            <div  style={{maxHeight: "300px", overflow: "auto"}}>
                <Table>
                    <Table.Body className="ui celled table scrollable">
                        <Table.Row>
                            {!isHover && 
                                <Table.Cell>
                                    <CustomCheckbox
                                    indeterminate={this.state.selectedRows.length > 0}
                                    checked={this.state.selectedRows.length === this.state.stateRows.length}
                                    multi
                                    onChange={this.setSelectedAll}
                                    />
                                </Table.Cell>
                            }
                        
                            {headerRow}
                        </Table.Row>
                        {this.state.stateRows && this.state.stateRows.map((row, indexRow) => 
                            !isHover ?

                                <Table.Row className={`grid-row${
                                    this.state.selectedRows.indexOf(row) != -1 ? ' grid-row-selected' : ''
                                }`}>
                                    {!isHover && 
                                        <Table.Cell>
                                            <CustomCheckbox
                                                checked={this.state.selectedRows.length !== 0 && this.state.selectedRows.indexOf(row) != -1}
                                                onChange={e => {
                                                    this.handleCheck(e, row);
                                                }}
                                            />
                                        </Table.Cell>
                                    }
                                    
                                    {columns && columns.map((column, indexColumn) => 
                                        <SubTableCell
                                            key={`cell_${row.id}_${column.name}`}
                                            value={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].value
                                                : row[column.name]
                                            }
                                            column={column}
                                            isReadOnly={readOnlyColumns.find(col => column.name === col)}
                                            t={t}
                                            indexRow={indexRow}
                                            indexColumn={indexColumn}
                                            status={row.status}
                                            rowId={row.id}
                                            subTableEditing={subTableEditing}
                                            setSubTableEditing={setSubTableEditing}
                                            checkForEditing={checkForEditing}
                                            changeCellValue={this.changeCellValue}
                                        />
                                    )}
                                    
                                </Table.Row>
                                : indexRow < 3 ?
                                <Table.Row className={`grid-row${
                                    this.state.selectedRows.indexOf(row) != -1 ? ' grid-row-selected' : ''
                                }`}>
                                    {!isHover && 
                                        <Table.Cell>
                                            <CustomCheckbox
                                                checked={this.state.selectedRows.length !== 0 && this.state.selectedRows.indexOf(row) != -1}
                                                onChange={e => {
                                                    this.handleCheck(e, row);
                                                }}
                                            />
                                        </Table.Cell>
                                    }
                                    
                                    {columns && columns.map((column, indexColumn) => 
                                        <SubTableCell
                                            key={`cell_${row.id}_${column.name}`}
                                            value={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].value
                                                : row[column.name]
                                            }
                                            column={column}
                                            isReadOnly={readOnlyColumns.find(col => column.name === col)}
                                            t={t}
                                            indexRow={indexRow}
                                            indexColumn={indexColumn}
                                            status={row.status}
                                            rowId={row.id}
                                            subTableEditing={subTableEditing}
                                            setSubTableEditing={setSubTableEditing}
                                            checkForEditing={checkForEditing}
                                            changeCellValue={this.changeCellValue}
                                        />
                                    )}
                                    
                                </Table.Row> : null
                            
                           
                        )}
                        
                        <div className="table-bottom-loader">
                            <Loader active={progress && rows.length} />
                        </div>
                        
                        
                    </Table.Body>  
                </Table>
                
            </div>
            
            {isHover && this.state.stateRows.length > 3 &&
                    <div className="sub-table-preview-dots">
                        . . .
                    </div>
                }
            {!isHover &&
                <div className="sub-table-buttons__lower">
                    <Button primary disabled={!isChanged} onClick={handleSaveSubTable}>{t("SaveButton")}</Button>
                    <Button onClick={() => {
                        closeSubTable();
                        this.setState({stateRows: rows})
                        }}
                        >{t("cancelConfirm")}</Button>
                </div>
            }
            
            </>
        );
    }
}


export default withTranslation()(SubTable);
