import React, { useEffect, useRef, useState } from 'react';

import { Table, Visibility, Button } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next'

import './style.scss';

const InfiniteScrollTable = ({
    children,
    header,
    headerRow,
    onBottomVisible,
    unstackable,
    celled,
    selectable,
    className,
    context,
    progress,
    style,
    structured,
    fixed,
    isMaximumLength,
    columns = [],
    hideShowMoreBtn,
}) => {
    let [width, setWidth] = useState(0);
    let [extwidth, setExtWidth] = useState();
    let [showMoreBtnVisible, setShowMoreBtnVisible] = useState(false);

    const { t } = useTranslation()


    useEffect(
        () => {
            let sum = 0;
            const contextWidth = context ? context.offsetWidth - 15 : 0;
            columns.forEach(item => {
                sum = item.width ? sum + item.width : sum;
            });
            setExtWidth(contextWidth - 50 - sum);
            setWidth(sum === 0 ? 0 : sum + 50);
        },
        [columns, context],
    );

    useEffect(() => {
        setShowMoreBtnVisible(false);
    }, [progress])


    return (
        <div style={{ position: 'relative', ...style }}>
            <Table
                celled={celled === undefined ? true : celled}
                selectable={selectable === undefined ? true : celled}
                unstackable={unstackable || false}
                structured={structured}
                className={className || ''}
                fixed={fixed}
                style={width !== 0 ? { minWidth: width, width: width } : {width: "auto", minWidth: "100%"}}
            >
                <Table.Header>{React.cloneElement(headerRow, { extwidth })}</Table.Header>

                {children}
            </Table>
            <Visibility
                continuous={false}
                once={false}
                onBottomVisible={() => setShowMoreBtnVisible(true)}
                onBottomVisibleReverse={() => setShowMoreBtnVisible(false)}
                context={context || undefined}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: -1,
                }}
            />
            {showMoreBtnVisible && !progress && !isMaximumLength && !hideShowMoreBtn &&
                <div className="show-more__container">
                    <Button onClick={onBottomVisible} className="show-more__button">{t("showMore")}</Button>
                </div>
            }
        </div>
    );
};

export default InfiniteScrollTable;
