import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    allActionsSelector, allBacklightsSelector,
    allPermissionsSelector,
    clearRolesCard,
    createRoleRequest,
    errorSelector,
    getAllActionsRequest, getAllBacklightsRequest,
    getAllPermissionsRequest,
    getRoleCardRequest,
    progressSelector,
    roleCardSelector,
} from '../../ducks/roles';
import { getDictionaryCardDefaultValueRequest, cardSelector } from '../../ducks/dictionaryView';
import CardLayout from '../../components/CardLayout';
import { Button, Dimmer, Form, Loader, Tab } from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import { sortFunc } from '../../utils/sort';
import { SELECT_TYPE, TEXT_TYPE, LOCAL_DATE_TIME } from '../../constants/columnTypes';

const RoleCard = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { id } = params;

    let [form, setForm] = useState({});

    const loading = useSelector(state => progressSelector(state));
    const role = useSelector(state => roleCardSelector(state)) || {};
    const error = useSelector(state => errorSelector(state)) || {};
    const allPermissions = useSelector(state => allPermissionsSelector(state)) || [];
    const allActions = useSelector(state => allActionsSelector(state)) || [];
    const allBacklights = useSelector(state => allBacklightsSelector(state)) || [];
    const defaults = useSelector(state => cardSelector(state));

    useEffect(() => {
        id ? dispatch(getRoleCardRequest(id)) : dispatch(getDictionaryCardDefaultValueRequest('roles'));
        dispatch(getAllPermissionsRequest());
        dispatch(getAllActionsRequest());
        dispatch(getAllBacklightsRequest());

        return () => {
            dispatch(clearRolesCard());
        };
    }, []);

    useEffect(() => {
        setForm(defaults);
    }, [defaults]);

    useEffect(
        () => {
            setForm(form => ({
                ...form,
                ...role,
                permissions: role.permissions ? role.permissions.map(item => item.code) : [],
                backlights: role.backlights ? role.backlights.map(item => item.value) : [],
                actions: role.actions ? role.actions.map(item => item.value) : [],
            }));
        },
        [role],
    );

    const title = useMemo(
        () => (id ? t('edit_role', { name: role.name }) : `${t('create_role_title')}`),
        [id, role],
    );

    const handleClose = () => {
        history.push({
            pathname: location.state.pathname,
            state: { ...location.state },
        });
    };

    const handleChange = useCallback((e, {name, value}) => {
        setForm(form => ({
            ...form,
            [name]: value,
        }));
    }, [form]);

    const handlePermissions = (e, { value }) => {
        const { permissions } = form;

        const selectedPermissions = new Set(permissions);

        selectedPermissions[selectedPermissions.has(value) ? 'delete' : 'add'](value);

        if (value === 1 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(2);
            selectedPermissions.delete(4);
            selectedPermissions.delete(5);
            selectedPermissions.delete(6);
        }

        if (value === 7 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(10);
            selectedPermissions.delete(11);
            selectedPermissions.delete(12);
        }

        handleChange(null, { name: 'permissions', value: Array.from(selectedPermissions) });
    };

    const handleActions = (e, {value}) => {
        const {actions} = form;


        const selectedActions = new Set(actions);

        selectedActions[selectedActions.has(value) ? 'delete' : 'add'](value);

        handleChange(null, {name: 'actions', value: Array.from(selectedActions)});
    };

    const handleBacklights = (e, {value}) => {
        const {backlights} = form;

        const selectedBacklights = new Set(backlights);

        selectedBacklights[selectedBacklights.has(value) ? 'delete' : 'add'](value);

        handleChange(null, {name: 'backlights', value: Array.from(selectedBacklights)});
    };

    const mapData = () => {
        return {
            ...form,
            permissions: form.permissions ? form.permissions.map(item => ({
                code: item,
            })) : [],
            backlights: form.backlights && allBacklights.filter(item => form.backlights.includes(item.value)),
            actions: [...demoActions, ...orderTransportActions, ...registerCarrierActions, ...registerDmActions, ...auctionActions].filter(item => form.actions && form.actions.includes(item.value)),
        };
    };

    const handleSave = () => {
        dispatch(createRoleRequest({ params: mapData(), callbackFunc: handleClose }));
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue" onClick={handleSave}>
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form],
    );

    const getContent = useCallback(
        () => {
            return [
                {
                    menuItem: 'general',
                    render: () => (
                        <Form className="role-form">
                            <FormField
                                name="name"
                                value={form['name']}
                                type={TEXT_TYPE}
                                isRequired
                                autoFocus
                                error={error['name']}
                                onChange={handleChange}
                            />
                            <FormField
                                search
                                selection
                                name="companyId"
                                value={form['companyId']}
                                source="companies"
                                error={error['companyId']}
                                type={SELECT_TYPE}
                                onChange={handleChange}
                            />
                            <FormField
                                fluid
                                name="editUserId"
                                value={form['editUserId']}
                                source="users"
                                isReadOnly
                                type={SELECT_TYPE}
                                error={error['editUserId']}
                                onChange={handleChange}
                            />
                            <FormField
                                fluid
                                name="createUserId"
                                value={form['createUserId']}
                                source="users"
                                isReadOnly
                                type={SELECT_TYPE}
                                error={error['createUserId']}
                                onChange={handleChange}
                            />
                            <FormField
                                fluid
                                name="editDate"
                                value={form['editDate']}
                                isReadOnly
                                type={LOCAL_DATE_TIME}
                                error={error['editDate']}
                                onChange={handleChange}
                            />
                            <FormField
                                fluid
                                name="createDate"
                                value={form['createDate']}
                                isReadOnly
                                type={LOCAL_DATE_TIME}
                                error={error['createDate']}
                                onChange={handleChange}
                            />
                            <Form.Field>
                                <label>{t('permissions')}</label>
                            </Form.Field>
                            {allPermissions.map(permission => (
                                <Form.Field key={permission.code}>
                                    <Form.Checkbox
                                        label={t(permission.name)}
                                        value={permission.code}
                                        checked={
                                            permissions && permissions.includes(permission.code)
                                        }
                                        disabled={
                                            (permissions &&
                                                ([2, 4, 5, 6].includes(permission.code) &&
                                                    !permissions.includes(1))) ||
                                            ([10, 11, 12].includes(permission.code) &&
                                                !permissions.includes(7))
                                        }
                                        onChange={handlePermissions}
                                    />
                                </Form.Field>
                            ))}
                        </Form>
                    ),
                },
                {
                    menuItem: 'order_transport_actions',
                    render: () => (
                        <Form className="role-form">
                            {sortFunc(orderTransportActions, t).map(action => (
                                <Form.Field key={action.value}>
                                    <Form.Checkbox
                                        label={t(action.name)}
                                        value={action.value}
                                        checked={actions && actions.includes(action.value)}
                                        onChange={handleActions}
                                    />
                                </Form.Field>
                            ))}
                        </Form>
                    ),
                },
                {
                    menuItem: 'register_dm_actions',
                    render: () => (
                        <Form className="role-form">
                            {sortFunc(registerDmActions, t).map(action => (
                                <Form.Field key={action.value}>
                                    <Form.Checkbox
                                        label={t(action.name)}
                                        value={action.value}
                                        checked={actions && actions.includes(action.value)}
                                        onChange={handleActions}
                                    />
                                </Form.Field>
                            ))}
                        </Form>
                    ),
                },
                {
                    menuItem: 'register_carrier_actions',
                    render: () => (
                        <Form className="role-form">
                            {sortFunc(registerCarrierActions, t).map(action => (
                                <Form.Field key={action.value}>
                                    <Form.Checkbox
                                        label={t(action.name)}
                                        value={action.value}
                                        checked={actions && actions.includes(action.value)}
                                        onChange={handleActions}
                                    />
                                </Form.Field>
                            ))}
                        </Form>
                    ),
                },
                {
                    menuItem: 'auction_actions',
                    render: () => (
                        <Form className="role-form">
                            {sortFunc(auctionActions, t).map(action => (
                                <Form.Field key={action.value}>
                                    <Form.Checkbox
                                        label={t(action.name)}
                                        value={action.value}
                                        checked={actions && actions.includes(action.value)}
                                        onChange={handleActions}
                                    />
                                </Form.Field>
                            ))}
                        </Form>
                    ),
                }
            ];
        },
        [form, error],
    );

    const permissions = useMemo(() => form.permissions || [], [form]);
    const actions = useMemo(() => form.actions || [], [form]);
    const backlights = useMemo(() => form.backlights || [], [form]);
    const demoActions = useMemo(() => allActions.demoActions || [], [allActions]);
    const orderTransportActions = useMemo(() => allActions.orderTransportActions || [], [allActions]);
    const registerDmActions = useMemo(() => allActions.registerDmActions || [], [allActions]);
    const registerCarrierActions = useMemo(() => allActions.registerCarrierActions || [], [allActions]);
    const auctionActions = useMemo(() => allActions.auctionActions || [], [allActions]);

    return (
        <CardLayout
            title={title}
            actionsFooter={getActionsFooter}
            content={getContent}
            onClose={handleClose}
            loading={loading}
        />
    );
};

export default RoleCard;
