import FormField from "../../../components/BaseComponents";
import React from "react";
import { indexOf } from "lodash";

const Content = ({ columns, error, form, handleChange, setNotChangeForm }) => {
    return (
        <div className="ui form dictionary-edit">
            {columns.map(column => {
                let newColumn = {
                    ...column,
                };

                if (column.dependencies && column.dependencies.length) {
                    let sourceParams = {};

                    column.dependencies.forEach(item => {
                        sourceParams = {
                            ...sourceParams,
                            [item]:
                                form[item] && typeof form[item] === 'object'
                                    ? form[item].value
                                    : form[item],
                        };
                    });

                    newColumn = {
                        ...newColumn,
                        sourceParams,
                    };
                }
                return (
                    column && !column.isHide &&
                    <FormField
                        {...newColumn}
                        focused={columns.indexOf(column) === 0}
                        noScrollColumn={column}
                        key={column.name}
                        error={error[column.name]}
                        value={form[column.name]}
                        setNotChangeForm={setNotChangeForm}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};

export default Content;
