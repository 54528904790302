import React, { useRef, useEffect, useState } from 'react';
import CellValue from '../../ColumnsValue';
import TextCropping from '../../ColumnsValue/TextCropping';

const BodyCellComponent = ({
    column,
    children,
    value,
    valueText,
    valueTooltip,
    indexColumn,
    indexRow,
    toggleIsActive,
    t,
    id,
    backlights,
    width,
}) => {
    const cellRef = useRef(null);
    let cellColor = "white";
    if (backlights && backlights.length !== 0) {
        for (let key in backlights) {
            if (key.toString() === column.name) {
                cellColor = backlights[key];
            }
        }
    }
    

    return (
        <td
            className={column.isFixedPosition ? 'no-scroll no-scroll-value' : ''}
            ref={cellRef}
            style={
                column.isFixedPosition
                    ? {
                          left: `${(150 * indexColumn) + 50}px`,
                          maxWidth: '150px',
                          minWidth: '150px',
                          backgroundColor: cellColor
                      }
                    : width
                    ? { maxWidth: `${width}px`,
                        backgroundColor: cellColor }
                    : {
                          maxWidth: '600px',
                          backgroundColor: cellColor
                      }
            }
        >
            <CellValue
                {...column}
                toggleIsActive={toggleIsActive}
                indexRow={indexRow}
                indexColumn={indexColumn}
                value={value}
                valueText={valueText}
                valueTooltip={valueTooltip}
                rowId={id}
                t={t}
            />
            {/*<TextCropping width={width} indexColumn={indexColumn}>
                {value}
            </TextCropping>*/}
        </td>
    );
};

export default React.memo(BodyCellComponent);
