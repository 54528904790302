import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FormField from '../formField';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { ORDER_TRANSPORTS_CARD } from '../../../../constants/grids';

const Information = ({
    form,
    onChange,
    uniquenessNumberCheck,
    settings,
    error,
}) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, ORDER_TRANSPORTS_CARD));


    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="dateTimeVehicleDelivery"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="dateTimeDeadlineConfirmation"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="dateTimeConfirmation"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="documentDate"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="status"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="orderNumber"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>      
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="tripNumber"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="shippingPointName"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="palletCount"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>   
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="comment"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="commentTc"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <FormField
                                                        name="numberContractTransportService"
                                                        columnsConfig={columnsConfig}
                                                        settings={settings}
                                                        form={form}
                                                        error={error}
                                                        onChange={onChange}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <FormField
                                                        name="dateContractTransportService"
                                                        columnsConfig={columnsConfig}
                                                        settings={settings}
                                                        form={form}
                                                        error={error}
                                                        onChange={onChange}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>  
                                </Grid.Row>      
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="orderPriority"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="transportationType"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="planningGroupCode"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>     
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="deliveryRegionName"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="transportManagerCode"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="utdNumber"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="cancelReasonCode"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="cancelReason"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="utdDate"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="dateTimeExpectedArrival"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
