import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FormField from '../formField';
import CellValue from '../../../../components/ColumnsValue'
import InfiniteScrollTable from '../../../../components/InfiniteScrollTable';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { itemColumnsGridSelector } from '../../../../ducks/gridList';
import { ORDER_TRANSPORTS_CARD } from '../../../../constants/grids';

const CarrierBills = ({
    form,
}) => {
    const { t } = useTranslation();
    const tableColumns = useSelector((state) => itemColumnsGridSelector(state, ORDER_TRANSPORTS_CARD));
    const billsColumns = tableColumns ? tableColumns.fineCarrierBills : [];


    const headerRowComponent = () => {
        return (
            <Table.Row>          
                {billsColumns && 
                billsColumns.map((row, index) => (
                    <Table.HeaderCell className="route-tab-table__header-cell">
                        {t(row.dynamicDisplayName ? row.dynamicDisplayName : row.displayNameKey)}
                    </Table.HeaderCell>
                ))}
            </Table.Row>
        );
    };


    return (
        <>
            {form.fineCarrierBills && form.fineCarrierBills.length ? 
                <Form className="tabs-card">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <Grid>
                                        <div className="scroll-table-container route-tab-table">
                                            <InfiniteScrollTable
                                                className="grid-table table-info"
                                                headerRow={headerRowComponent()}
                                            >
                                            {form.fineCarrierBills && form.fineCarrierBills.map((row, indexRow) => (
                                                <Table.Row>
                                                    {billsColumns && billsColumns.map((column, indexColumn) => (
                                                        <td
                                                            className={column.isFixedPosition ? 'no-scroll no-scroll-value' : ''}
                                                        >
                                                            <CellValue
                                                                {...column}
                                                                indexRow={indexRow}
                                                                indexColumn={indexColumn}
                                                                value={row[column.name]}
                                                                valueText={
                                                                    row[column.name] &&
                                                                    typeof row[column.name] === 'object' &&
                                                                    !Array.isArray(row[column.name])
                                                                        ? row[column.name].name
                                                                        : null
                                                                }
                                                                t={t}
                                                            />
                                                        </td>
                                                    ))}
                                                </Table.Row>
                                            ))}
                                            </InfiniteScrollTable>
                                        </div>
                                    </Grid>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            : null}
        </>
    );
};

export default CarrierBills;
