import { createSelector } from 'reselect'
import { postman, downloader } from '../utils/postman'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { representationFromGridSelector } from './representations'
import { showModal } from "./modal"
import { tripFreelanceEventSelector } from './profile'
import downloadFile from "../utils/downloadFile"

//*  TYPES  *//

const GET_IDS_REQUEST = 'GET_IDS_REQUEST'
const GET_IDS_SUCCESS = 'GET_IDS_SUCCESS'
const GET_IDS_ERROR = 'GET_IDS_ERROR'

const GET_ACTIONS_REQUEST = 'GET_ACTIONS_REQUEST'
const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS'
const GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR'

const INVOKE_ACTION_REQUEST = 'INVOKE_ACTION_REQUEST'
const INVOKE_ACTION_SUCCESS = 'INVOKE_ACTION_SUCCESS'
const INVOKE_ACTION_ERROR = 'INVOKE_ACTION_ERROR'

const INVOKE_MASS_UPDATE_REQUEST = 'INVOKE_MASS_UPDATE_REQUEST'
const INVOKE_MASS_UPDATE_SUCCESS = 'INVOKE_MASS_UPDATE_SUCCESS'
const INVOKE_MASS_UPDATE_ERROR = 'INVOKE_MASS_UPDATE_ERROR'

const FIELDS_MASS_UPDATE_REQUEST = 'FIELDS_MASS_UPDATE_REQUEST'
const FIELDS_MASS_UPDATE_SUCCESS = 'FIELDS_MASS_UPDATE_SUCCESS'
const FIELDS_MASS_UPDATE_ERROR = 'FIELDS_MASS_UPDATE_ERROR'

const INVOKE_ADDITIONAL_ACTION_REQUEST = 'INVOKE_ADDITIONAL_ACTION_REQUEST'
const INVOKE_ADDITIONAL_ACTION_SUCCESS = 'INVOKE_ADDITIONAL_ACTION_SUCCESS'
const INVOKE_ADDITIONAL_ACTION_ERROR = 'INVOKE_ADDITIONAL_ACTION_ERROR'

const CHECK_FOR_UTD_DOWNLOAD_REQUEST = 'CHECK_FOR_UTD_DOWNLOAD_REQUEST'
const CHECK_FOR_UTD_DOWNLOAD_SUCCESS = 'CHECK_FOR_UTD_DOWNLOAD_SUCCESS'
const CHECK_FOR_UTD_DOWNLOAD_ERROR = 'CHECK_FOR_UTD_DOWNLOAD_ERROR'

const CHECK_FREELANCE_EVENT_REQUEST = 'CHECK_FREELANCE_EVENT_REQUEST'
const CHECK_FREELANCE_EVENT_SUCCESS = 'CHECK_FREELANCE_EVENT_SUCCESS'
const CHECK_FREELANCE_EVENT_ERROR = 'CHECK_FREELANCE_EVENT_ERROR'

const SAVE_FREELANCE_EVENT_REQUEST = 'SAVE_FREELANCE_EVENT_REQUEST'
const SAVE_FREELANCE_EVENT_SUCCESS = 'SAVE_FREELANCE_EVENT_SUCCESS'
const SAVE_FREELANCE_EVENT_ERROR = 'SAVE_FREELANCE_EVENT_ERROR'

const DOWNLOAD_UTD_DOCUMENT_REQUEST = 'DOWNLOAD_UTD_DOCUMENT_REQUEST'
const DOWNLOAD_UTD_DOCUMENT_SUCCESS = 'DOWNLOAD_UTD_DOCUMENT_SUCCESS'
const DOWNLOAD_UTD_DOCUMENT_ERROR = 'DOWNLOAD_UTD_DOCUMENT_ERROR'

const SUBTABLE_UPDATE_REQUEST = 'SUBTABLE_UPDATE_REQUEST'
const SUBTABLE_UPDATE_SUCCESS = 'SUBTABLE_UPDATE_SUCCESS'
const SUBTABLE_UPDATE_ERROR = 'SUBTABLE_UPDATE_ERROR'

const REFUSAL_CHANGE_REQUEST = 'REFUSAL_CHANGE_REQUEST'
const REFUSAL_CHANGE_SUCCESS = 'REFUSAL_CHANGE_SUCCESS'
const REFUSAL_CHANGE_ERROR = 'REFUSAL_CHANGE_ERROR'

const FORM_REGISTER_REQUEST = 'FORM_REGISTER_REQUEST'
const FORM_REGISTER_SUCCESS = 'FORM_REGISTER_SUCCESS'
const FORM_REGISTER_ERROR = 'FORM_REGISTER_ERROR'

const ADD_REGISTER_REQUEST = 'ADD_REGISTER_REQUEST'
const ADD_REGISTER_SUCCESS = 'ADD_REGISTER_SUCCESS'
const ADD_REGISTER_ERROR = 'ADD_REGISTER_ERROR'

const ADD_NEW_FINE_DM_BILL_REQUEST = 'ADD_NEW_FINE_DM_BILL_REQUEST'
const ADD_NEW_FINE_DM_BILL_SUCCESS = 'ADD_NEW_FINE_DM_BILL_SUCCESS'
const ADD_NEW_FINE_DM_BILL_ERROR = 'ADD_NEW_FINE_DM_BILL_ERROR'

const GET_REGISTER_DEFAULTS_REQUEST = 'GET_REGISTER_DEFAULTS_REQUEST'
const GET_REGISTER_DEFAULTS_SUCCESS = 'GET_REGISTER_DEFAULTS_SUCCESS'
const GET_REGISTER_DEFAULTS_ERROR = 'GET_REGISTER_DEFAULTS_ERROR'

const MASS_REJECT_BY_DM_REQUEST = 'MASS_REJECT_BY_DM_REQUEST'
const MASS_REJECT_BY_DM_SUCCESS = 'MASS_REJECT_BY_DM_SUCCESS'
const MASS_REJECT_BY_DM_ERROR = 'MASS_REJECT_BY_DM_ERROR'

const DOWNLOAD_ORDER_ALL_FILES_REQUEST = 'DOWNLOAD_ORDER_ALL_FILES_REQUEST'
const DOWNLOAD_ORDER_ALL_FILES_SUCCESS = 'DOWNLOAD_ORDER_ALL_FILES_SUCCESS'
const DOWNLOAD_ORDER_ALL_FILES_ERROR = 'DOWNLOAD_ORDER_ALL_FILES_ERROR'

const GET_DATE_DISCLAIMER_WARRANTY_REQUEST = 'GET_DATE_DISCLAIMER_WARRANTY'
const GET_DATE_DISCLAIMER_WARRANTY_SUCCESS = 'GET_DATE_DISCLAIMER_WARRANTY_SUCCESS'
const GET_DATE_DISCLAIMER_WARRANTY_ERROR = 'GET_DATE_DISCLAIMER_WARRANTY_ERROR'

const CLEAR_ACTIONS = 'CLEAR_ACTIONS'

const DEFAULT_STATE = 'DEFAULT_STATE'

//*  INITIAL STATE  *//

const initial = {
    actions: [],
    actionsCard: [],
    info: {},
    updates: [],
    ids: [],
    defaultForm: {},
    progress: false,
    progressActionName: null,
    progressMassUpdate: false,
    progressUtdCheck: false,
    progressFreelanceEventCheck: false,
    progressFreelanceEventSave: false,
    progressDownload: false,
    progressFormRegister: false,
    progressAddRegister: false,
    progressDefaults: false,
    progressAddFineDmBill: false,
    progressMassReject: false,
    refuseProgress: false,
    progressDownloadOrderAllFiles: false,
    progressGetDateDisclaimerWarranty: false,
}

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_IDS_REQUEST:
            return {
                ...state,
                actions: [],
                info: [],
                updates: [],
            }
        case GET_IDS_SUCCESS:
            return {
                ...state,
                ids: payload
            }
        case GET_ACTIONS_REQUEST:
            return {
                ...state,
                actions: [],
                progress: true
            }
        case GET_ACTIONS_SUCCESS:
            let stateNew = {
                ...state,
                info: payload.info,
                updates: payload.updates,
                progress: false,
            }

            if (payload.isCard) {
                stateNew = {
                    ...stateNew,
                    actionsCard: payload.actions,
                }
            } else {
                stateNew = {
                    ...stateNew,
                    actions: payload.actions,
                }
            }

            return stateNew
        case GET_ACTIONS_ERROR:
            return {
                ...state,
                actions: [],
                progress: false
            }
        case INVOKE_ACTION_REQUEST:
            return {
                ...state,
                progressActionName: payload.actionName,
            }
        case INVOKE_ACTION_SUCCESS:
            return {
                ...state,
                progressActionName: null,
            }
        case INVOKE_ACTION_ERROR:
            return {
                ...state,
                progressActionName: null,
            }
        case INVOKE_MASS_UPDATE_REQUEST:
        case FIELDS_MASS_UPDATE_REQUEST:
            return {
                ...state,
                progressMassUpdate: true,
            }
        case INVOKE_MASS_UPDATE_SUCCESS:
        case INVOKE_MASS_UPDATE_ERROR:
        case FIELDS_MASS_UPDATE_SUCCESS:
        case FIELDS_MASS_UPDATE_ERROR:
            return {
                ...state,
                progressMassUpdate: false,
            }
        case SUBTABLE_UPDATE_REQUEST:
            return {
                ...state,
                progressMassUpdate: true,
            }
        case SUBTABLE_UPDATE_SUCCESS:
        case SUBTABLE_UPDATE_ERROR:
            return {
                ...state,
                progressMassUpdate: false,
            }
        case INVOKE_ADDITIONAL_ACTION_REQUEST:
            return {
                ...state,
                progressMassUpdate: true,
            }
        case INVOKE_ADDITIONAL_ACTION_SUCCESS:
        case INVOKE_ADDITIONAL_ACTION_ERROR:
            return {
                ...state,
                progressMassUpdate: false,
            }
        case CHECK_FOR_UTD_DOWNLOAD_REQUEST:
            return {
                ...state,
                progressUtdCheck: true,
            }
        case CHECK_FOR_UTD_DOWNLOAD_SUCCESS:
        case CHECK_FOR_UTD_DOWNLOAD_ERROR:
            return {
                ...state,
                progressUtdCheck: false,
            }
        case CHECK_FREELANCE_EVENT_REQUEST:
            return {
                ...state,
                progressFreelanceEventCheck: true,
            }
        case CHECK_FREELANCE_EVENT_SUCCESS:
        case CHECK_FREELANCE_EVENT_ERROR:
            return {
                ...state,
                progressFreelanceEventCheck: false,
            }
        case SAVE_FREELANCE_EVENT_REQUEST:
            return {
                ...state,
                progressFreelanceEventSave: true,
            }
        case SAVE_FREELANCE_EVENT_SUCCESS:
        case SAVE_FREELANCE_EVENT_ERROR:
            return {
                ...state,
                progressFreelanceEventSave: false,
            }
        case DOWNLOAD_UTD_DOCUMENT_REQUEST:
            return {
                ...state,
                progressDownload: true,
            }
        case DOWNLOAD_UTD_DOCUMENT_SUCCESS:
        case DOWNLOAD_UTD_DOCUMENT_ERROR:
            return {
                ...state,
                progressDownload: false,
            }
        case REFUSAL_CHANGE_REQUEST:
            return {
                ...state,
                refuseProgress: true,
            }
        case REFUSAL_CHANGE_SUCCESS:
        case REFUSAL_CHANGE_ERROR:
            return {
                ...state,
                refuseProgress: false,
            }
        case MASS_REJECT_BY_DM_REQUEST:
            return {
                ...state,
                progressMassReject: true,
            }
        case MASS_REJECT_BY_DM_SUCCESS:
        case MASS_REJECT_BY_DM_ERROR:
            return {
                ...state,
                progressMassReject: false,
            }
        case FORM_REGISTER_REQUEST:
            return {
                ...state,
                progressFormRegister: true,
            }
        case FORM_REGISTER_SUCCESS:
        case FORM_REGISTER_ERROR:
            return {
                ...state,
                progressFormRegister: false,
            }
        case ADD_REGISTER_REQUEST:
            return {
                ...state,
                progressAddRegister: true,
            }
        case ADD_REGISTER_SUCCESS:
        case ADD_REGISTER_ERROR:
            return {
                ...state,
                progressAddRegister: false,
            }
        case ADD_NEW_FINE_DM_BILL_REQUEST:
            return {
                ...state,
                progressAddFineDmBill: true,
            }
        case ADD_NEW_FINE_DM_BILL_SUCCESS:
        case ADD_NEW_FINE_DM_BILL_ERROR:
            return {
                ...state,
                progressAddFineDmBill: false,
            }
        case GET_REGISTER_DEFAULTS_REQUEST:
            return {
                ...state,
                progressDefaults: true,
            }
        case GET_REGISTER_DEFAULTS_SUCCESS:
            return {
                ...state,
                progressDefaults: false,
                defaultForm: payload,
            }
        case GET_REGISTER_DEFAULTS_ERROR:
            return {
                ...state,
                progressDefaults: false,
                defaultForm: {},
            }
        case DOWNLOAD_ORDER_ALL_FILES_REQUEST:
            return {
                ...state,
                progressDownloadOrderAllFiles: true,
            }
        case DOWNLOAD_ORDER_ALL_FILES_SUCCESS:
        case DOWNLOAD_ORDER_ALL_FILES_ERROR:
            return {
                ...state,
                progressDownloadOrderAllFiles: false,
            }
        case GET_DATE_DISCLAIMER_WARRANTY_REQUEST:
            return {
                ...state,
                progressGetDateDisclaimerWarranty: true,
            }
        case GET_DATE_DISCLAIMER_WARRANTY_SUCCESS:
        case GET_DATE_DISCLAIMER_WARRANTY_ERROR:
            return {
                ...state,
                progressGetDateDisclaimerWarranty: false,
            }
        case CLEAR_ACTIONS:
            return {
                ...state,
                actions: [],
                info: {},
                updates: [],
                actionsCard: [],
            }
        
        case DEFAULT_STATE:
            return {
                ...initial
            }
        default:
            return state
    }
}

//*  ACTION CREATORS  *//

export const getActionsRequest = payload => {
    return {
        type: GET_ACTIONS_REQUEST,
        payload,
    }
}

export const invokeActionRequest = payload => {
    return {
        type: INVOKE_ACTION_REQUEST,
        payload,
    }
}

export const refusalChangeRequest = payload => {
    return {
        type: REFUSAL_CHANGE_REQUEST,
        payload,
    }
}

export const clearActions = () => {
    return {
        type: CLEAR_ACTIONS,
    }
}

export const getAllIdsRequest = payload => {
    return {
        type: GET_IDS_REQUEST,
        payload,
    }
}

export const invokeMassUpdateRequest = payload => {
    return {
        type: INVOKE_MASS_UPDATE_REQUEST,
        payload,
    }
}

export const subtableUpdateRequest = payload => {
    return {
        type: SUBTABLE_UPDATE_REQUEST,
        payload,
    }
}

export const clearAllIdsRequest = payload => {
    return {
        type: GET_IDS_SUCCESS,
        payload
    }
}

export const invokeAdditionalActionRequest = payload => {
    return {
        type: INVOKE_ADDITIONAL_ACTION_REQUEST,
        payload
    }
}

export const checkForUtdDownloadRequest = payload => {
    return {
        type: CHECK_FOR_UTD_DOWNLOAD_REQUEST,
        payload
    }
}

export const checkFreelanceEventRequest = payload => {
    return {
        type: CHECK_FREELANCE_EVENT_REQUEST,
        payload
    }
}

export const saveFreelanceEventRequest = payload => {
    return {
        type: SAVE_FREELANCE_EVENT_REQUEST,
        payload
    }
}

export const downloadUtdDocumentRequest = payload => {
    return {
        type: DOWNLOAD_UTD_DOCUMENT_REQUEST,
        payload
    }
}

export const formRegisterRequest = payload => {
    return {
        type: FORM_REGISTER_REQUEST,
        payload
    }
}

export const addRegisterRequest = payload => {
    return {
        type: ADD_REGISTER_REQUEST,
        payload
    }
}

export const addFineDmBillRequest = payload => {
    return {
        type: ADD_NEW_FINE_DM_BILL_REQUEST,
        payload
    }
}

export const massRejectByDmRequest = payload => {
    return {
        type: MASS_REJECT_BY_DM_REQUEST,
        payload
    }
}

export const getRegisterDefaultsRequest = payload => {
    return {
        type: GET_REGISTER_DEFAULTS_REQUEST,
        payload
    }
}

export const downloadOrderAllFilesRequest = payload => {
    return {
        type: DOWNLOAD_ORDER_ALL_FILES_REQUEST,
        payload
    }
}

export const getDateDisclaimerWarrantyRequest = payload => {
    return {
        type: GET_DATE_DISCLAIMER_WARRANTY_REQUEST,
        payload
    }
}

export const fieldsMassUpdateRequest = payload => {
    return {
        type: FIELDS_MASS_UPDATE_REQUEST,
        payload
    }
}

//*  SELECTORS *//

const stateSelector = state => state.gridActions

export const actionsSelector = createSelector(stateSelector, state =>
    state.actions.map(item => ({
        ...item,
        ids: item.ids || [],
    })),
)

export const actionsCardSelector = createSelector(stateSelector, state =>
    (state.actionsCard || []).map(item => ({
        ...item,
        ids: item.ids || [],
    })),
)

export const progressSelector = createSelector(stateSelector, state => state.progress)

export const progressActionNameSelector = createSelector(
    stateSelector,
    state => state.progressActionName,
)

export const formDefaultsSelector = createSelector(stateSelector, state => state.defaultForm)

export const infoSelector = createSelector(stateSelector, state => state.info)
export const updatesSelector = createSelector(stateSelector, state => state.updates)
export const progressMassUpdateSelector = createSelector(
    stateSelector,
    state => state.progressMassUpdate,
)

export const progressUtdCheckSelector = createSelector(stateSelector, state => state.progressUtdCheck)
export const progressFreelanceEventSaveSelector = createSelector(stateSelector, state => state.progressFreelanceEventSave)
export const progressFreelanceEventCheckSelector = createSelector(stateSelector, state => state.progressFreelanceEventCheck)
export const progressDownloadSelector = createSelector(stateSelector, state => state.progressDownload)
export const progressFormRegister = createSelector(stateSelector, state => state.progressFormRegister)
export const progressAddRegister = createSelector(stateSelector, state => state.progressAddRegister)
export const progressAddFineDmBillSelector = createSelector(stateSelector, state => state.progressAddFineDmBill)
export const progressDefaultsSelector = createSelector(stateSelector, state => state.progressDefaults)
export const refuseProgressSelector = createSelector(stateSelector, state => state.refuseProgress)
export const progressMassRejectSelector = createSelector(stateSelector, state => state.progressMassReject)
export const progressDownloadOrderAllFilesSelector = createSelector(stateSelector, state => state.progressDownloadOrderAllFiles)
export const progressGetDateDisclaimerWarrantySelector = createSelector(stateSelector, state => state.progressGetDateDisclaimerWarranty)

export const allIdsSelector = createSelector(stateSelector, state => state.ids)

//*  SAGA  *//

function* getActionsSaga({ payload }) {
    try {
        const { name, ids = [], isCard } = payload
        if (ids.length) {
            const actions = yield postman.post(`/${name}/getActions`, ids)
            let info = {}
            let updates = []

            if (!isCard) {
                info = yield postman.post(`/${name}/getSummary`, ids)
                updates = yield postman.post(`/${name}/getBulkUpdates`, ids)
            }
            yield put({
                type: GET_ACTIONS_SUCCESS,
                payload: { actions, info, updates, isCard },
            })
        } else {
            yield put({
                type: GET_ACTIONS_SUCCESS,
                payload: { actions: [], info: {}, update: [], actionsCard: [] },
            })
        }
    } catch (e) {
        yield put({ type: GET_ACTIONS_ERROR })
    }
}

function* invokeActionSaga({ payload }) {
    try {
        const { ids, callbackSuccess, name, actionName } = payload
        const result = yield postman.post(`/${name}/invokeAction/${actionName}`, ids)
        yield put({
            type: INVOKE_ACTION_SUCCESS,
        })


        if (!result.isError) {
            if (result.messageType === 0) {
                toast.info(result.message, {
                    autoClose: false,
                })
            } else {
                yield put(showModal(result))
            }
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
        }

    } catch (e) {
        yield put({
            type: INVOKE_ACTION_ERROR,
            payload: e,
        })
    }
}

function* invokeAdditionalActionSaga({ payload }) {
    try {

        const { ids, callbackSuccess, gridName, actionName } = payload
        const result = yield postman.post(`/${gridName}/invokeAdditionalAction/${actionName}`, ids)
        yield put({
            type: INVOKE_ADDITIONAL_ACTION_SUCCESS
        })

        if (!result.isError) {
            toast.info(result.message, {
                autoClose: false,
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
        }

    } catch (e) {
        yield put({
            type: INVOKE_ADDITIONAL_ACTION_ERROR,
            payload: e,
        })
    }
}

function* checkForUtdDownloadSaga({ payload }) {
    try {

        const { ids, number, callbackSuccess } = payload;
        const params = {
            ids: ids,
            date: number ? number["utdDate"] : "",
        };
        const result = yield postman.post(`/orderTransports/utdValidation`, params)

        if (!result.isError) {
            yield put({
                type: CHECK_FOR_UTD_DOWNLOAD_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: CHECK_FOR_UTD_DOWNLOAD_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: CHECK_FOR_UTD_DOWNLOAD_ERROR,
            payload: e,
        })
    }
}

function* checkFreelanceEventSaga({ payload }) {
    try {

        const { ids, callbackSuccess } = payload
        const result = yield postman.post(`/orderTransports/freelanceEventValidation`, ids)

        if (!result.isError) {
            yield put({
                type: CHECK_FREELANCE_EVENT_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: CHECK_FREELANCE_EVENT_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: CHECK_FREELANCE_EVENT_ERROR,
            payload: e,
        })
    }
}

function* saveFreelanceEventSaga({ payload }) {
    try {

        const { id, form, callbackSuccess } = payload
        const result = yield postman.post(`/orderTransports/addFreelanceEvent/${id}`, form)

        if (!result.isError) {
            toast.info(result.message)
            yield put({
                type: SAVE_FREELANCE_EVENT_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: SAVE_FREELANCE_EVENT_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: CHECK_FREELANCE_EVENT_ERROR,
            payload: e,
        })
    }
}

function* downloadUtdDocumentSaga({ payload }) {
    try {
        const { number, ids, callbackSuccess } = payload
        const params = {
            ids: ids,
            date: number ? number["utdDate"] : "",

        }
        const utdEncoded = encodeURIComponent(number && number["utdNumber"] ? number["utdNumber"] : "")
        const result = yield downloader.post(`/orderTransports/utdDocuments/${utdEncoded}`, params, { responseType: 'blob' })

        downloadFile(result)
        yield put({
            type: DOWNLOAD_UTD_DOCUMENT_SUCCESS,
        })
        callbackSuccess && callbackSuccess()
    } catch (e) {
        const { callbackError } = payload
        callbackError && callbackError()
        yield put({
            type: DOWNLOAD_UTD_DOCUMENT_ERROR,
            payload: e,
        })
    }
}

function* getAllIdsSaga({ payload }) {
    try {
        const { filter, name, callbackSuccess } = payload
        const representation = yield select(state => representationFromGridSelector(state, name))
        filter.filter.columns = representation ? representation.map(item => item.name) : [];
        const result = yield postman.post(`/${name}/ids`, {
            ...filter
        })

        // const result = yield postman.get(`/${name}/ids`);
        // yield put({
        //     type: GET_IDS_SUCCESS,
        //     payload: result
        // });

        callbackSuccess && callbackSuccess(result)
    } catch (e) {
        yield put({
            type: GET_IDS_ERROR,
            payload: e,
        })
    }
}

function* invokeMassUpdateSaga({ payload }) {
    try {
        const { ids, callbackSuccess, name, field, value, modalCallback } = payload
        const result = yield postman.post(`/${name}/invokeBulkUpdate/${field}`, {
            ids,
            value: value && typeof value === 'object' ? value.value : value,
        })
        if (result.isError) {
            toast.error(result.message)
            yield put({
                type: INVOKE_MASS_UPDATE_ERROR,
                payload: result,
            })
        } else {
            yield put({
                type: INVOKE_MASS_UPDATE_SUCCESS,
            })

        }

        if (result.needUpdateField) {
            modalCallback && modalCallback(result.needUpdateField)
        } else {
            !result.isError && callbackSuccess && callbackSuccess()
            result.message && !result.isError && toast.info(result.message)
        }

    } catch (e) {
        yield put({
            type: INVOKE_MASS_UPDATE_ERROR,
            payload: e,
        })
    }
}

function* fieldsMassUpdateSaga({ payload }) {
    try {
        const { ids, callbackSuccess, name, urlName, form = {}, modalCallback } = payload
        const result = yield postman.post(`/${name}/${urlName}`, {
            ids,
            ...form
        })
        if (result.isError) {
            toast.error(result.message)
            yield put({
                type: FIELDS_MASS_UPDATE_ERROR,
                payload: result,
            })
        } else {
            yield put({
                type: FIELDS_MASS_UPDATE_SUCCESS,
            })

        }

        if (result.needUpdateField) {
            modalCallback && modalCallback(result.needUpdateField)
        } else {
            !result.isError && callbackSuccess && callbackSuccess()
            result.message && !result.isError && toast.info(result.message)
        }

    } catch (e) {
        yield put({
            type: FIELDS_MASS_UPDATE_ERROR,
            payload: e,
        })
    }
}

function* formRegisterSaga({ payload }) {
    try {

        const { type, form, callbackSuccess } = payload
        const result = yield postman.post(`/${type}/formRegister`, form)

        if (!result.isError) {
            toast.info(result.message)
            yield put({
                type: FORM_REGISTER_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: FORM_REGISTER_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: FORM_REGISTER_ERROR,
            payload: e,
        })
    }
}

function* addRegisterSaga({ payload }) {
    try {

        const { type, form, callbackSuccess } = payload
        const result = yield postman.post(`/${type}/addNewRegister`, form)

        if (!result.isError) {
            toast.info(result.message)
            yield put({
                type: ADD_REGISTER_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: ADD_REGISTER_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: ADD_REGISTER_ERROR,
            payload: e,
        })
    }
}

function* addNewFineDmBillSaga({ payload }) {
    try {

        const { type, form, callbackSuccess } = payload
        const result = yield postman.post(`/${type}/addNewFineDmBill`, form)

        if (!result.isError) {
            toast.info(result.message)
            yield put({
                type: ADD_NEW_FINE_DM_BILL_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: ADD_NEW_FINE_DM_BILL_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: ADD_NEW_FINE_DM_BILL_ERROR,
            payload: e,
        })
    }
}

function* getRegisterDefaultsSaga({ payload }) {
    try {

        const { type, isFormRegister, callbackSuccess } = payload
        const result = yield postman.get(isFormRegister ? `/${type}/formRegister/default` : `/${type}/defaults`)

        if (!result.isError) {
            if (result.message) {
                toast.info(result.message)
            }
            yield put({
                type: GET_REGISTER_DEFAULTS_SUCCESS,
                payload: result
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: GET_REGISTER_DEFAULTS_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: GET_REGISTER_DEFAULTS_ERROR,
            payload: e,
        })
    }
}

function* subtableUpdateSaga({ payload }) {
    try {
        const { callbackSuccess, callbackError, name, value } = payload
        const result = yield postman.post(`/${name}/subtable/saveOrCreate`, value)
        if (result.isError) {
            toast.error(result.message)
            yield put({
                type: SUBTABLE_UPDATE_ERROR,
                payload: result,
            })
            callbackError && callbackError()
        } else {
            yield put({
                type: SUBTABLE_UPDATE_SUCCESS,
            })
            result.message && toast.info(result.message)
        }
        callbackSuccess && callbackSuccess()
    } catch (e) {
        yield put({
            type: SUBTABLE_UPDATE_ERROR,
            payload: e,
        })

    }
}

function* massRejectByDmSaga({ payload }) {
    try {

        const { form, callbackSuccess } = payload
        const result = yield postman.post(`/registerCarrier/massRejectByDm`, form)

        if (!result.isError) {
            toast.info(result.message)
            yield put({
                type: MASS_REJECT_BY_DM_SUCCESS
            })
            callbackSuccess && callbackSuccess()
        } else {
            toast.error(result.message, {
                autoClose: false,
            })
            yield put({
                type: MASS_REJECT_BY_DM_ERROR,
                payload: result,
            })
        }

    } catch (e) {
        yield put({
            type: MASS_REJECT_BY_DM_ERROR,
            payload: e,
        })
    }
}

function* refusalChangeSaga({ payload }) {
    try {
        const { id, fieldName, gridName, callbackSuccess } = payload
        const result = yield postman.post(`/${gridName}/refusalChangeField/${fieldName}/${id}`)
        yield put({
            type: REFUSAL_CHANGE_SUCCESS,
            payload
        })
        callbackSuccess && callbackSuccess()
    } catch (e) {
        yield put({
            type: REFUSAL_CHANGE_ERROR,
            payload: e,
        })
    }
}

function* downloadOrderAllFilesSaga({ payload }) {
    try {
        const { id } = payload

        const result = yield downloader.get(`/orderTransports/files/${id}`, { responseType: 'blob' })

        downloadFile(result)
        yield put({
            type: DOWNLOAD_ORDER_ALL_FILES_SUCCESS,
        })
    } catch (e) {
        const { callbackError } = payload
        callbackError && callbackError()
        yield put({
            type: DOWNLOAD_ORDER_ALL_FILES_ERROR,
            payload: e,
        })
    }
}

function* getDateDisclaimerWarrantySaga({ payload }) {
    try {
        const { callbackSuccess, ids } = payload
        const result = yield postman.post('/availableVehicles/getDateDisclaimerWarranty', ids)

        yield put({
            type: GET_DATE_DISCLAIMER_WARRANTY_SUCCESS,
            payload
        })
        callbackSuccess && callbackSuccess(result)
    } catch (e) {
        const { callbackError } = payload
        callbackError && callbackError()
        yield put({
            type: GET_DATE_DISCLAIMER_WARRANTY_ERROR,
            payload: e,
        })
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_ACTIONS_REQUEST, getActionsSaga),
        takeEvery(INVOKE_ACTION_REQUEST, invokeActionSaga),
        takeEvery(GET_IDS_REQUEST, getAllIdsSaga),
        takeEvery(INVOKE_MASS_UPDATE_REQUEST, invokeMassUpdateSaga),
        takeEvery(SUBTABLE_UPDATE_REQUEST, subtableUpdateSaga),
        takeEvery(INVOKE_ADDITIONAL_ACTION_REQUEST, invokeAdditionalActionSaga),
        takeEvery(CHECK_FOR_UTD_DOWNLOAD_REQUEST, checkForUtdDownloadSaga),
        takeEvery(CHECK_FREELANCE_EVENT_REQUEST, checkFreelanceEventSaga),
        takeEvery(SAVE_FREELANCE_EVENT_REQUEST, saveFreelanceEventSaga),
        takeEvery(DOWNLOAD_UTD_DOCUMENT_REQUEST, downloadUtdDocumentSaga),
        takeEvery(FORM_REGISTER_REQUEST, formRegisterSaga),
        takeEvery(ADD_REGISTER_REQUEST, addRegisterSaga),
        takeEvery(ADD_NEW_FINE_DM_BILL_REQUEST, addNewFineDmBillSaga),
        takeEvery(GET_REGISTER_DEFAULTS_REQUEST, getRegisterDefaultsSaga),
        takeEvery(MASS_REJECT_BY_DM_REQUEST, massRejectByDmSaga),
        takeEvery(REFUSAL_CHANGE_REQUEST, refusalChangeSaga),
        takeEvery(DOWNLOAD_ORDER_ALL_FILES_REQUEST, downloadOrderAllFilesSaga),
        takeEvery(GET_DATE_DISCLAIMER_WARRANTY_REQUEST, getDateDisclaimerWarrantySaga),
        takeEvery(FIELDS_MASS_UPDATE_REQUEST, fieldsMassUpdateSaga)
    ])
}
