import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupRequest, valuesListSelector } from '../../ducks/lookup';
import TextCropping from './TextCropping';

const SubTableValue = ({ value, source, indexRow, indexColumn, showRawValue, width, valueText }) => {

    return (
        <TextCropping width={width} indexColumn={indexColumn}>
            {valueText}
        </TextCropping>
    );
};

export default SubTableValue;
