import React, { createRef, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Tel = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    pattern,
    autoComplete,
    autoFocus,
    setNotChangeForm,
    focused,
    displayNameKey,
}) => {
    const { t } = useTranslation();

    const [validationError, setValidationError] = useState(null);

    const handleBlur = () => {
        if (pattern) {
            let reg = new RegExp(pattern);
            if (!reg.exec(value)) {
                setValidationError(t("phone.incorrectFormat"));
                setNotChangeForm && setNotChangeForm(true);
            } else {
                setValidationError(null);
            }
        }
        
    }


   
    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(displayNameKey ? displayNameKey : text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <Input
                placeholder={placeholder}
                list={valuesList && name}
                className={className}
                type="tel"
                disabled={isDisabled}
                name={name}
                value={value ? value : ""}
                error={validationError ? true : error}
                pattern={pattern}
                onChange={onChange}
                onBlur={handleBlur}
                autoComplete={autoComplete}
                autoFocus={autoFocus || focused}
            />
            {validationError ? (
                <span className="label-error">{validationError}</span>
            ) : error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    );
};
export default Tel;
