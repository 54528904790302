import { all, put, takeEvery, take, spawn, delay, cancelled, cancel, fork, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {downloader, postman} from '../utils/postman';
import downloadFile from "../utils/downloadFile";

const TYPE_API = 'reports';

//*  TYPES  *//

const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

const REPORT_EXPORT_TO_EXCEL_REQUEST = 'REPORT_EXPORT_TO_EXCEL_REQUEST';
const REPORT_EXPORT_TO_EXCEL_SUCCESS = 'REPORT_EXPORT_TO_EXCEL_SUCCESS';
const REPORT_EXPORT_TO_EXCEL_ERROR = 'REPORT_EXPORT_TO_EXCEL_ERROR';

const FORM_REPORT_REQUEST = 'FORM_REPORT_REQUEST';
const FORM_REPORT_SUCCESS = 'FORM_REPORT_SUCCESS';
const FORM_REPORT_ERROR = 'FORM_REPORT_ERROR';

const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    columns: [],
    config: [],
    reportData: {},
    progress: false,
    formReportProgress: false,
    exportProgress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_REPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                ...payload,
            };
        case GET_REPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case FORM_REPORT_REQUEST:
            return {
                ...state,
                formReportProgress: true,
            };
        case FORM_REPORT_SUCCESS:
            return {
                ...state,
                formReportProgress: false,
                reportData: payload,
            };
        case FORM_REPORT_ERROR:
            return {
                ...state,
                formReportProgress: false,
                reportData: {},
            };
        case REPORT_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_TO_EXCEL_SUCCESS:
        case REPORT_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case CLEAR_REPORT_DATA:
            return {
                ...state,
                reportData: {},
            }
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getReportRequest = (payload) => {
    return {
        type: GET_REPORT_REQUEST,
        payload
    }
};

export const formReportRequest = (payload) => {
    return {
        type: FORM_REPORT_REQUEST,
        payload
    }
};

export const reportExportToExcelRequest = payload => {
    return {
        type: REPORT_EXPORT_TO_EXCEL_REQUEST,
        payload
    }
};

export const clearReportData = payload => {
    return {
        type: CLEAR_REPORT_DATA,
        payload
    }
};

//*  SELECTORS *//

const stateSelector = state => state.reports;
const stateProfile = state => state.profile;
const reportName = (state, name) => name;
export const reportSelector = createSelector(stateSelector, state => state.data);

export const columnsSelector = createSelector([stateProfile, reportName], (state, name) => {
    const report = state.reports && state.reports.find(item => item.name === name)
    return report ? report.columns : []
})

export const filtersSelector = createSelector([stateProfile, reportName], (state, name) => {
    const report = state.reports && state.reports.find(item => item.name === name)
    let reportConfig = {};
    if (report) {
        report.filters.forEach(filter => {
            reportConfig = {
                ...reportConfig,
                [filter.name]: filter
            }
        })
    }

    return reportConfig;
})

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const reportDataSelector = createSelector(stateSelector, state => state.reportData);
export const formReportProgressSelector = createSelector(stateSelector, state => state.formReportProgress);
export const exportProgressSelector = createSelector(stateSelector, state => state.exportProgress);

//*  SAGA  *//

function* getReportSaga({ payload }) {
    try {
        const {type, params} = payload;

        const config = yield postman.get(`/${TYPE_API}/${type}/reportConfiguration`);

        const result = yield postman.post(`/${TYPE_API}/${type}/get`, params);

        yield put({
            type: GET_REPORT_SUCCESS,
            payload: {
                ...result,
                config: config.columns
            }
        })
    } catch (e) {
        yield put({
            type: GET_REPORT_ERROR
        })
    }
}

function* formReportSaga({ payload }) {
    try {
        const {name, params} = payload;

        const result = yield postman.post(`/${name}/generate`, params);

        yield put({
            type: FORM_REPORT_SUCCESS,
            payload: result,
        })
    } catch (e) {
        yield put({
            type:FORM_REPORT_ERROR
        })
    }
}

function* reportExportToExcelSaga({ payload }) {
    try {
        const {name, params} = payload;

        const result = yield downloader.post(`/${name}/exportToExcel`, params, { responseType: 'blob' });
        const { data } = result;
        let headerLine = result.headers['content-disposition'].split('; ')[2];
        let filename = decodeURI(headerLine.substring(17));
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // downloadFile(result);

        yield put({type: REPORT_EXPORT_TO_EXCEL_SUCCESS})
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_TO_EXCEL_ERROR
        })
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_REPORT_REQUEST, getReportSaga),
        takeEvery(FORM_REPORT_REQUEST, formReportSaga),
        takeEvery(REPORT_EXPORT_TO_EXCEL_REQUEST, reportExportToExcelSaga),
    ])
}
