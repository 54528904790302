import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormField from '../../components/BaseComponents';
import { Button, Confirm, Dropdown, Popup, Modal, Form, Grid, GridColumn, Message } from 'semantic-ui-react';
import {
    addError,
    cardSelector,
    clearGridCard, createGridCardRequest,
    editCardRequest,
    editProgressSelector,
    errorSelector, getAuctionConfigRequest,
    getCardRequest, placeBetRequest,
    progressSelector,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    invokeMassUpdateRequest,
    progressActionNameSelector,
    progressFreelanceEventCheckSelector,
    progressFreelanceEventSaveSelector,
    progressMassUpdateSelector,
    checkFreelanceEventRequest,
    createFreelanceEvent,
    saveFreelanceEventRequest,
    refusalChangeRequest,
    refuseProgressSelector,
    addRegisterRequest,
    progressDefaultsSelector,
    formDefaultsSelector,
    addFineDmBillRequest,
    progressAddFineDmBillSelector,
    progressAddRegister,
} from '../../ducks/gridActions';
import { columnsTypesConfigSelector } from '../../ducks/representations';
import { REGISTER_DM_CARD, ORDER_TRANSPORTS_CARD, ORDER_TRANSPORT_AUCTIONS_CARD, REGISTER_CARRIER_CARD, FINE_DM_BILL_CARD } from '../../constants/grids';
import DemoGridEntityCard from './components/demoGridEntityCard';
import OrderCard from './components/orderCard';
import TransportAuctionsCard from './components/transportAuctionsCard';
import {DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK, GRID_CARD_LINK} from '../../router/links';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import {getFieldsSettingRequest, getFieldsSettingSaga} from "../../ducks/fieldsSetting";
import {createFreelanceEventSelector, roleIdSelector} from "../../ducks/profile";
import FreelanceEventModal from '../../components/SuperGrid/components/freelanceEventModal';
import RegisterCard from './components/registerCard';
import FineDmBillCard from './components/fineDmBillCard';

const Card = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { name, id } = params;

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });
    let [showFreelanceEvent, setShowFreelanceEvent] = useState(false);
    let [needUpdateModal, setNeedUpdateModal] = useState(null);
    let [needUpdateValue, setNeedUpdateValue] = useState({});


    const title = useMemo(
        () =>
            id
                ? name === ORDER_TRANSPORTS_CARD ? t('order') + " " + (name === ORDER_TRANSPORTS_CARD ? form.orderNumber : "") 
                + " - " + (t(form.status)) : name === REGISTER_DM_CARD ? t(REGISTER_DM_CARD) + " - " + t('history') : name === REGISTER_CARRIER_CARD ? t(REGISTER_CARRIER_CARD) + " - " + t('history')
                : name === ORDER_TRANSPORT_AUCTIONS_CARD ? t('auctions') : ''
                : t(`new_${name}`),
        [name, id, form],
    );


    const card = useSelector(state => cardSelector(state));
    const settings = useSelector(state => settingsFormSelector(state, card.status));
    const error = useSelector(state => errorSelector(state));
    const roleId = useSelector(state => roleIdSelector(state));
    const createFreelanceEvent = useSelector(state => createFreelanceEventSelector(state, name));
    const freelanceEventCheckLoader = useSelector(state => progressFreelanceEventCheckSelector(state));
    const freelanceEventSaveLoader = useSelector(state => progressFreelanceEventSaveSelector(state));
    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, name));
    const refuseLoader = useSelector((state) => refuseProgressSelector(state));
    const progressMassUpdateLoader= useSelector((state) => progressMassUpdateSelector(state));
    const progressDefaults = useSelector((state) => progressDefaultsSelector(state));
    const formDefaults = useSelector((state) => formDefaultsSelector(state));

    useEffect(() => {
        setForm(formDefaults);
    }, [formDefaults]);

    useEffect(() => {
        dispatch(clearActions());
        id && loadCard();

        dispatch(getFieldsSettingRequest({
            forEntity: name,
            roleId,
        }));

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, []);

    useEffect(
        () => {
            if (notChangeForm) {
                Object.keys(form).forEach(key => {
                    if (form[key] !== card[key]) {
                        setNotChangeForm(false);
                    }
                });
            }
        },
        [form],
    );

    useEffect(
        () => {
            if (name === ORDER_TRANSPORT_AUCTIONS_CARD) {
                dispatch(
                    getAuctionConfigRequest()
                )
            }
        },
        []
    );

    const checkFreelanceEvent = () => {
        dispatch(checkFreelanceEventRequest({ 
            ids:  [id],
            callbackSuccess: () => {
                setShowFreelanceEvent(true);
            },
        }));
    };

    const closeFreelanceEventModal = () => {
        setShowFreelanceEvent(false);
    };

    const addRegister = () => {
        dispatch(addRegisterRequest({
            type: name,
            form: form,
            callbackSuccess: () => {
                handleClose();
            }
        }))
    }

    const addFineDmBill = () => {
        const newForm = Object.assign(form);
        newForm.orderId = localStorage.getItem("orderIdForTripPoint");
        dispatch(addFineDmBillRequest({
            type: name,
            form: newForm,
            callbackSuccess: () => {
                handleClose();
            }
        }))
    }

    const loadCard = () => {
        id && dispatch(
            getCardRequest({
                name,
                id,
                callbackSuccess: card => {
                    setForm(card);
                    setNotChangeForm(true);
                    if (card.validationResult) {
                        card.validationResult && card.validationResult._errors && card.validationResult._errors.forEach(item => {
                            dispatch(
                                addError(item),
                            );
                        });
                    }
                },
            }),
        );
        id && dispatch(
            getActionsRequest({
                name,
                ids: [id],
                isCard: true,
            }),
        );
        id && dispatch(getHistoryRequest(id));
    };

    const onClose = () => {
        const { state } = location;
        const { pathname, gridLocation } = state;

        history.replace({
            pathname: pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        });
    };

    const handleClose = isConfirm => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback((e, { name, value }) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, []);


    const saveOrEditForm = (callbackFun) => {
        dispatch(
            editCardRequest({
                name,
                params: form,
                modalCallback: (needUpdateField) => {
                    setNeedUpdateModal(needUpdateField);
                    setNeedUpdateValue(form[needUpdateField.fieldName]);
                },
                callbackSuccess: callbackFun ? callbackFun : () => {
                    if (form.id) {
                        setNotChangeForm(true);
                        loadCard();
                    } else {
                        handleClose();
                    }
                },
            }),
        );
    };

    const placeBet = () => {
        dispatch(
            placeBetRequest({
                auctionId: form.id,
                offeredCost: Number(form.costOfferedByTc)
            })
        )
    };

    const createForm = () => {
        dispatch(
            createGridCardRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            })
        )
    };

    const handleSave = () => {
        saveOrEditForm();
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const invokeAction = actionName => {
        showConfirmation(
            `${t('grid_actions_confirmation')} "${t(actionName)}"?`,
            () => {
                closeConfirmation();
                dispatch(
                    invokeActionRequest({
                        ids: [id],
                        name,
                        actionName,
                        callbackSuccess: () => {
                            if (actionName.toLowerCase().includes('delete')) {
                                onClose();
                            } else {
                                loadCard();
                            }
                        },
                    }),
                );
            },
            closeConfirmation,
        );
    };

    const loading = useSelector(state => progressSelector(state));
    const editLoading = useSelector(state => editProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const progressActionName = useSelector(state => progressActionNameSelector(state));
    const addFineDmBillLoading = useSelector(state => progressAddFineDmBillSelector(state));
    const addRegisterLoading = useSelector(state => progressAddRegister(state));
    const disableSave = useMemo(() => {
        return Boolean(
            progressActionName ||
                notChangeForm ||
                Object.keys(error).filter((p) => p !== 'null').length,
        );
    }, [progressActionName, notChangeForm, error]);

    const getActionsFooter = useCallback(() => {
        return (
            <>
                {(name === 'registerDm' || name === 'registerCarrier' || name === 'fineDmBillsGrid') && !id ? (
                    <>
                        <Button color="grey" onClick={handleClose}>
                            {t('CancelButton')}
                        </Button>
                        <Button
                            color="blue"
                            loading={name === 'fineDmBillsGrid' ? addFineDmBillLoading : addRegisterLoading}
                            onClick={name === 'fineDmBillsGrid' ? addFineDmBill : addRegister}
                            disabled={Object.keys(form).length === 0}
                        >
                            {t('create_btn')}
                        </Button>
                    </>
                ) : name === 'auctions' ? (
                    <>
                        <Button
                            color="blue"
                            onClick={onClose}
                        >
                            {t('close')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button color="grey" onClick={handleClose}>
                            {t('CancelButton')}
                        </Button>
                        <Button
                            color="blue"
                            disabled={disableSave}
                            loading={editLoading}
                            onClick={handleSave}
                        >
                            {t('SaveButton')}
                        </Button>
                    </>
                )}
            </>
        );
    }, [form, disableSave, editLoading, name]);

    const goToCard = (gridName, cardId) => {
        const { state } = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const getActionsHeader = useCallback(
        () => {
            return (
                <div
                    className="grid-card-header"
                >
                    {createFreelanceEvent && name === ORDER_TRANSPORTS_CARD && <Popup
                        content={t("freelanceEvent.title")}
                        position="bottom right"  
                        trigger={
                            <Button
                                icon
                                loading={freelanceEventCheckLoader}
                                onClick={checkFreelanceEvent}
                            > 
                                {t("freelanceEvent.title")}
                            </Button>
                        }
                    />}
                    <Dropdown
                        icon="ellipsis horizontal"
                        floating
                        button
                        pointing="top right"
                        className="icon"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {actions &&
                                actions.filter(item => item.allowedFromForm).map(action => (
                                    <Dropdown.Item
                                        key={action.name}
                                        text={t(action.name)}
                                        label={{
                                            color: action.color,
                                            empty: true,
                                            circular: true,
                                        }}
                                        onClick={() => invokeAction(action.name)}
                                    />
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            );
        },
        [form, actions, name],
    );

    return (
        <React.Fragment>
            {name === ORDER_TRANSPORTS_CARD ? (
                <OrderCard
                    {...props}
                    id={id}
                    load={loadCard}
                    name={name}
                    form={form}
                    title={title}
                    settings={settings}
                    loading={loading}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                />
            ) : null}
            {name === ORDER_TRANSPORT_AUCTIONS_CARD ? (
                <TransportAuctionsCard
                    {...props}
                    id={id}
                    load={loadCard}
                    name={name}
                    form={form}
                    title={title}
                    settings={settings}
                    loading={loading}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    handleClick={placeBet}
                />
            ) : null}
            {name === REGISTER_DM_CARD || name === REGISTER_CARRIER_CARD ? (
                <RegisterCard
                    {...props}
                    id={id}
                    load={loadCard}
                    name={name}
                    form={form}
                    title={title}
                    settings={settings}
                    loading={loading}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                />
            ) : null}
            {name === FINE_DM_BILL_CARD ? (
                <FineDmBillCard
                    {...props}
                    id={id}
                    load={loadCard}
                    name={name}
                    form={form}
                    title={title}
                    settings={settings}
                    loading={loading}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                />
            ) : null}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <Modal open={needUpdateModal} size="small" closeOnDimmerClick={false}>
                <Modal.Header>
                    {t(`edit`) + " " + t(needUpdateModal && needUpdateModal.fieldName)}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description className="need-update-modal-description">
                        {needUpdateModal && 
                            <Message info> 
                                <p>{needUpdateModal.infoMessage}</p>
                            </Message>
                        }
                    </Modal.Description>
                    <Form>
                            <Form.Field>
                                <Grid>
                                    <Grid.Row columns="1">
                                        <Grid.Column>
                                            <FormField
                                                {...columnsConfig[needUpdateModal ? needUpdateModal.fieldName : '']}
                                                name={needUpdateModal && needUpdateModal.fieldName}
                                                text={needUpdateModal && t(needUpdateModal.fieldName)}
                                                value={needUpdateValue && needUpdateValue}
                                                onChange={(e, {value}) => setNeedUpdateValue(value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                
                            </Form.Field>
                        </Form>   
                </Modal.Content>
                <Modal.Actions>
                    <Button loading={refuseLoader} 
                        disabled={needUpdateModal ? needUpdateModal.disabledCancelButton : false}
                        onClick={() => {
                        dispatch(refusalChangeRequest({
                            id: needUpdateModal ? needUpdateModal.id : null,
                            fieldName: needUpdateModal ? needUpdateModal.fieldName : null,
                            gridName: name,
                            callbackSuccess: () => {
                                setNeedUpdateModal(null);
                            }
                        }));
                    }}>{t('cancelConfirm')}</Button>
                    <Button
                        color="primary"
                        loading={progressMassUpdateLoader}
                        onClick={() => {
                            dispatch(invokeMassUpdateRequest({
                                ids: [needUpdateModal ? needUpdateModal.id : null],
                                name: name,
                                field: needUpdateModal ? needUpdateModal.fieldName : null,
                                value: needUpdateValue ? needUpdateValue : null,
                                callbackSuccess: () => {
                                    setNeedUpdateModal(null);
                                }
                            }))
                        }}
                    >
                        {t('SaveButton')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={showFreelanceEvent}>
                <Modal.Header>{t('freelanceEvent.title.create')}</Modal.Header>
                <FreelanceEventModal 
                    closeFreelanceEventModal={closeFreelanceEventModal}
                    cardCallback={() => loadCard()}
                    selectedIds={[id]} 
                />
            </Modal>
        </React.Fragment>
    );
};

export default Card;
