import {ACTIVE_TYPE, SELECT_TYPE, TEXT_TYPE, MULTI_SELECT_TYPE, LOCAL_DATE_TIME} from './columnTypes';

export const usersColumns = [
    {
        name: 'login',
        displayNameKey: 'login',
    },
    {
        name: 'email',
        displayNameKey: 'email',
        type: TEXT_TYPE
    },
    {
        name: 'userName',
        displayNameKey: 'userName',
        type: TEXT_TYPE
    },
    {
        name: 'roleId',
        displayNameKey: 'role',
        type: SELECT_TYPE,
        filterType: SELECT_TYPE,
        source: 'roles',
    },
    {
        name: 'companyId',
        displayNameKey: 'companyId',
        type: SELECT_TYPE,
        filterType: SELECT_TYPE,
        source: 'companies',
    },
    {
        name: 'transportCompanyId',
        displayNameKey: 'transportCompanyId',
        type: SELECT_TYPE,
        filterType: SELECT_TYPE,
        source: 'transportCompanies',
    },
    {
        name: 'cities',
        displayNameKey: 'cities',
        type: MULTI_SELECT_TYPE,
        source: 'shippingCities',
        notSortAndFilter: true,
    },
    {
        name: 'distributionCenters',
        displayNameKey: 'distributionCenters',
        type: MULTI_SELECT_TYPE,
        source: 'distributionCenters',
        notSortAndFilter: true,
    },
    {
        name: 'editUserId',
        displayNameKey: 'editUserId',
        filterType: SELECT_TYPE,
        source: "users",
        type: SELECT_TYPE
    },
    {
        name: 'createUserId',
        displayNameKey: 'createUserId',
        filterType: SELECT_TYPE,
        source: "users",
        type: SELECT_TYPE
    },
    {
        name: 'editDate',
        displayNameKey: 'editDate',
        filterType: LOCAL_DATE_TIME,
        type: LOCAL_DATE_TIME
    },
    {
        name: 'createDate',
        displayNameKey: 'createDate',
        filterType: LOCAL_DATE_TIME,
        type: LOCAL_DATE_TIME
    },
    {
        name: 'isActive',
        displayNameKey: 'isActive',
        type: ACTIVE_TYPE,
    },
];
