import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { Resizable } from 'react-resizable';
import FacetField from '../../FilterComponents';

const HeaderCellComponent = forwardRef(
    ({ row, index, setFilter, width, setSort, filters = {}, sort = {}, gridName, handleResize, getColumnWidth }, ref) => {
        /* const cellRef = useRef(null);
    let [position, setPosition] = useState(null);

    useEffect(() => {
        setPosition(cellRef.current.offsetLeft);
    }, []);

*/      
        return (
            <Resizable
                key={`resizable_${row.name}`}
                width={getColumnWidth(width, index, row.name)}
                height={0}
                axis="x"
                onResize={(e, { size }) => handleResize(e, { size, index: index, name: row.name })}
            >
                <th
                    className={
                        row.isFixedPosition ? 'no-scroll table-header-cell' : 'table-header-cell'
                    }
                    ref={ref}
                    id={"header-cell-" + row.name}
                    style={
                        row.isFixedPosition
                            ? {
                                left: (index * 150) + 50,
                                maxWidth: '150px',
                                minWidth: '150px',
                            }
                            : width
                            ? {
                                maxWidth: `${width}px`,
                                minWidth: `${width}px`,
                            }
                            : { maxWidth: '600px' }
                    }
                >
                    <FacetField
                        key={'facet' + row.name}
                        notWrapHeader
                        index={index}
                        name={row.name}
                        gridName={gridName}
                        displayNameKey={row.displayNameKey}
                        dynamicDisplayName={row.dynamicDisplayName}
                        sort={sort && sort.name === row.name ? sort.desc : null}
                        setSort={setSort}
                        type={row.filterType}
                        value={
                            filters.filter && filters.filter.filter && filters.filter.filter[row.name]
                        }
                        filters={filters}
                        setFilter={setFilter}
                        source={row.source}
                        noSort={row.isSortDisabled}
                        notSortAndFilter={row.notSortAndFilter}
                        noFilter={row.isFilterDisabled}
                        handleResize={handleResize}
                    />
                </th>

            </Resizable>
            
        );
    },
);

export default React.memo(HeaderCellComponent);
