import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FormField from '../../../customGrid/components/formField';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { ORDER_TRANSPORTS_CARD } from '../../../../constants/grids';
import { NUMBER_TYPE } from '../../../../constants/columnTypes';

const IntegrationLag = ({
    form,
    onChange,
    uniquenessNumberCheck,
    settings,
    columns,
    error,
}) => {
    const { t } = useTranslation();



    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FormField
                                            name="integrationLag"
                                            columnsConfig={columns}
                                            form={form}
                                            error={error}
                                            type={NUMBER_TYPE}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default IntegrationLag;