import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Information from './orderTabs/information'
import Driver from './orderTabs/driver';
import Route from './orderTabs/route';
import CarrierBills from './orderTabs/carrierBills';
import DmBills from './orderTabs/dmBills';
import Requirements from './orderTabs/requirements';
import Documents from './shared/documents';
import Incidents from './orderTabs/incidents';
import History from './shared/history';

import CardLayout from '../../../components/CardLayout';
import { isTripPointsGridSelector, isFineDmBillsSelector } from '../../../ducks/gridList';
import { GRID_LIST_LINK, GRID_NEW_LINK } from '../../../router/links';
import { userPermissionsSelector } from '../../../ducks/profile';



const OrderCard = ({
    form,
    onChangeForm,
    name,
    id,
    load,
    isNotUniqueNumber,
    uniquenessNumberCheck,
    settings,
    error,
    title,
    onClose,
    actionsFooter,
    actionsHeader,
    loading,
    goToSoldTo,
    history,
}) => {
    const {t} = useTranslation();
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    const isTripPointsAvailable = useSelector(state => isTripPointsGridSelector(state));
    const isFineDmBillsAvailable = useSelector(state => isFineDmBillsSelector(state));

    const handleOpenRouteGrid = () => {
        localStorage.setItem("orderIdForTripPoint", id);
    }

    const handleOpenFineDmCreateForm = () => {
        localStorage.setItem("orderIdForTripPoint", id);

        const gridPath = "/" + history.location.pathname.split('/')[1] + "/" + history.location.pathname.split('/')[2];

        history.push({
            pathname: GRID_NEW_LINK.replace(':name', "fineDmBillsGrid"),
            state: {
                pathname: history.location.pathname,
                gridLocation: gridPath,
            },
        });
    }

    const handleOpenFineDmGrid = () => {
        localStorage.setItem("orderIdForTripPoint", id);

        const gridPath = "/" + history.location.pathname.split('/')[1] + "/" + history.location.pathname.split('/')[2];

        history.push({
            pathname: GRID_LIST_LINK.replace(':name', "fineDmBillsGrid"),
            state: {
                pathname: history.location.pathname,
                gridLocation: gridPath,
            },
        });
    }

    const getPanes = () => {
        let obj = [
            {
                menuItem: 'route',
                actions() {
                    if (isTripPointsAvailable) {
                        return [
                            <Button as={Link} onClick={handleOpenRouteGrid} to={GRID_LIST_LINK.replace(':name', "gridTripPoints")}>{t("editRoute")}</Button>
                        ]
                    } else {
                        return [

                        ]
                    }
                },
                render: () => (
                    <Route
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'driverAndTransport',
                render: () => (
                    <Driver
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'information',
                render: () => (
                    <Information
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'requirements',
                render: () => (
                    <Requirements
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'incidents',
                render: () => (
                    <Incidents
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'fineCarrierBills',
                render: () => (
                    <CarrierBills
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'fineDmBills',
                actions() {
                    if (isFineDmBillsAvailable) {
                        return [
                            <>
                                <Button icon labelPosition='left' role="button" onClick={handleOpenFineDmCreateForm}>
                                    <Icon name='plus'/>
                                    {t("addNewItem")}
                                </Button>
                                <Button icon labelPosition='left' onClick={handleOpenFineDmGrid}>
                                    <Icon name='arrow up'/>
                                    {t("addToRegister")}
                                </Button>
                            </>
                        ]
                    } else {
                        return [

                        ]
                    }
                },
                render: () => (
                    <DmBills
                        form={form}
                        settings={settings}
                        error={error}
                        load={load}
                        isNotUniqueNumber={isNotUniqueNumber}
                        uniquenessNumberCheck={uniquenessNumberCheck}
                        onChange={onChangeForm}
                        goToSoldTo={goToSoldTo}
                    />
                ),
            },
            {
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        form={form}
                        isGrid={true}
                        cardId={id}
                        load={load}
                        disableAddBtn={true}
                        isEditPermissions={userPermissions.includes(5)}
                    />
                ),
            },
            {
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            },
            // {
            //     menuItem: 'position',
            //     render: () => (
            //         <Position
            //             form={form}
            //             onChange={onChangeForm}
            //             gridName={name}
            //             load={load}
            //             error={error}
            //             settings={settings}
            //         />
            //     ),
            // },
        ];
        /*{
                menuItem: 'returns',
                render: () => (
                    <Returns
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            },*/


        if ((userPermissions.includes(4) || userPermissions.includes(5)) && form.id) {
            obj.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        load={load}
                        isEditPermissions={userPermissions.includes(5)}
                    />
                ),
            });
        }

        if (userPermissions.includes(6) && form.id) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            });
        }

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />
    );
};

export default OrderCard;
