import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../../../customGrid/components/formField';
import { NUMBER_TYPE } from '../../../../constants/columnTypes';

const AuctionDuration = ({ form, onChange, columns, error }) => {
    const { t } = useTranslation();

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <FormField
                                            name="auctionDuration"
                                            columnsConfig={columns}
                                            form={form}
                                            error={error}
                                            type={NUMBER_TYPE}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default AuctionDuration;