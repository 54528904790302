export default {
    users: [
        {
            id: '5d5d2fac1d1e470a60e04535',
            login: 'admin',
            name: 'Администратор',
            fio: 'Администратор',
            email: 'admin@admin.ru',
            role: 'Admin',
            is_active: true,
            shippers: [
                'ООО "Бастион"',
                'ООО "ТОРГОВАЯ КОМПАНИЯ ЕВРАЗИЯ ФУДС"',
                'ООО "ДВА КАПИТАНА"',
                'ЗАО "Байсад"',
                'ООО "БАРИНОФФ"',
                'ООО "Главпродукт-контракт"',
            ],
            regions: [
                'РЦ Уткина Заводь',
                'РЦ СЛК',
                'РЦ Нон-Фуд',
                'РЦ ЗТЛ',
                'РЦ Подольск ТД',
                'РЦ Видное',
                'РЦ Богородск Сухой',
            ],
            psg: ['РЦ КДК Томилино'],
        },
        {
            id: '5d5d2fac1d1e470a60e04536',
            login: 'i_ivanov',
            name: 'Иван Иванов',
            fio: 'Иван Иванов',
            email: 'ivan@artlogics.ru',
            role: 'Planner',
            is_active: false,
            shippers: null,
            regions: null,
            psg: null,
        },
        {
            id: '5d5d2fac1d1e470a60e04537',
            login: 'sergey',
            name: 'Сергей Михалков',
            fio: 'Сергей Михалков',
            email: 'sergey@artlogics.ru',
            role: 'Planner',
            is_active: true,
            shippers: null,
            regions: null,
            psg: null,
        },
        {
            id: '5d5d2fac1d1e470a60e04538',
            login: 'katya',
            name: 'Екатерина Хлебникова',
            fio: 'Екатерина Хлебникова',
            email: 'katya@artlogics.ru',
            role: 'Planner',
            is_active: true,
            shippers: null,
            regions: null,
            psg: null,
        },
        {
            id: '5d5d2fac1d1e470a60e04539',
            login: 'user',
            name: 'Борис Рогозин',
            fio: 'Борис Рогозин',
            email: 'mail@artlogics.ru',
            role: 'Planner',
            is_active: true,
            shippers: null,
            regions: null,
            psg: null,
        },
        {
            id: '5d5d2fac1d1e470a60e0453a',
            login: 'artem',
            name: 'Артём Лебедев',
            fio: 'Артём Лебедев',
            email: 'user1@artlogics.ru',
            role: 'Planner',
            is_active: true,
            shippers: null,
            regions: null,
            psg: null,
        },
    ],
};
