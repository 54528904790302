import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DocWithEditor from '../../../../components/Documents/DocWithEditor';
import {
    clearDocuments,
    documentsSelector,
    getDocumentsRequest,
    progressSelector,
} from '../../../../ducks/documents';
import {Dimmer, Loader} from 'semantic-ui-react';

const Documents = ({gridName, cardId, isEditPermissions, load, isGrid, form, disableAddBtn}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [prevDriverId, setPrevDriverId] = useState(null);
    const [prevVehicleId, setPrevVehicleId] = useState(null);

    const getDocuments = () => {
        if (gridName === "orderTransports") {
            dispatch(getDocumentsRequest({gridName, cardId : [cardId, form.driverId && form.driverId.value,
            form.vehicleId && form.vehicleId.value]}));
        } else {
            dispatch(getDocumentsRequest({gridName, cardId}));
        }
        
    };

    useEffect(() => {
        getDocuments();
        return () => {
            dispatch(clearDocuments());
        };
    }, []);

    useEffect(() => {
        if (gridName === "orderTransports" && ((form.driverId && form.driverId.value !== prevDriverId) || (form.vehicleId && form.vehicleId.value !== prevVehicleId))) { 
            setPrevDriverId(form.driverId ? form.driverId.value : null);
            setPrevVehicleId(form.vehicleId ? form.vehicleId.value : null);
            getDocuments();
        }
    }, [form]);

    const documents = useSelector(state => documentsSelector(state));
    const loading = useSelector(state => progressSelector(state));

    return (
        <div className="flex-container tabs-card">
          {/*  <Dimmer active={loading} inverted>
                <Loader size="huge">Loading</Loader>
            </Dimmer>*/}
            {documents.map((document, index) => (
                <DocWithEditor
                    key={document.fileId}
                    currentFileId={document.fileId}
                    gridName={gridName}
                    cardId={cardId}
                    load={load}
                    document={document}
                    isEditPermissions={isEditPermissions}
                    getDocuments={getDocuments}
                    isGrid={isGrid}
                    titleText={t('Edit document')}
                    okButtonText={t('SaveButton')}
                />
            ))}
            {!disableAddBtn && 
                <DocWithEditor
                    gridName={gridName}
                    cardId={cardId}
                    load={load}
                    isGrid={isGrid}
                    isEditPermissions={isEditPermissions}
                    getDocuments={getDocuments}
                    titleText={t('Add document')}
                    okButtonText={t('AddButton')}
                />
            }
        </div>
    );
};

export default Documents;
