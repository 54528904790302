import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FormField from '../formField';
import { columnsTypesConfigSelector } from '../../../../ducks/representations';
import { ORDER_TRANSPORTS_CARD } from '../../../../constants/grids';

const Requirements = ({
    form,
    onChange,
    uniquenessNumberCheck,
    settings,
    error,
}) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, ORDER_TRANSPORTS_CARD));


    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="planingPalletCount"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="tailLift"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="highLess"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="pass"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="tempRegime"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <FormField
                                            name="prop4"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>       
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <FormField
                                            name="prop5"
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>        
                            </Grid>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Requirements;
