import React from 'react';

import {
    ACTIVE_TYPE,
    BOOLEAN_TYPE,
    CUSTOM_SELECT_TYPE,
    CUSTOM_STATE_TYPE,
    ENUM_TYPE,
    LABELS_TYPE,
    LINK_TYPE,
    LOCAL_DATE_TIME,
    NUMBER_TYPE,
    VALIDATED_NUMBER_TYPE,
    SELECT_TYPE,
    STATE_TYPE,
    MULTI_ENUM_TYPE,
    SUBTABLE_TYPE,
    FILE_TYPE,
    MULTI_SELECT_TYPE,
    TRANSPORT_COMPANIES_TYPE,
    TIME_ZONE_TYPE
} from '../../constants/columnTypes';
import { numbersFormat, tryParseFloatFromRuFormat } from '../../utils/numbersFormat';
import { Checkbox, Label } from 'semantic-ui-react';
import StateValue from './StateValue';
import SelectValue from './SelectValue';
import SubTableValue from './SubTableValue';
import FilesValue from './FilesValue';
import MultiSelectValue from './MultiSelectValue';
import TextCropping from './TextCropping';
import { dateToUTC } from '../../utils/dateTimeFormater';
import ToggleCheckbox from './ToogleCheckbox';
import moment from 'moment';

//хардкорим локаль ru-RU, так как на беке возникает проблема парсинга фильтра для типа number
//например локаль стоит русская, то тогда к примеру 141,6 - распартиться как 1416, что валидно,
//но по факту должно было быть 141.6, а на беке мы не знаем локаль клиента
const locale = 'ru-RU'

const CellValue = ({
    type,
    value = '',
    valueText,
    valueTooltip,
    name,
    toggleIsActive,
    source,
    indexRow,
    indexColumn,
    modalCard,
    showRawValue,
    width,
    t,
    isDisabled,
    cardLink,
    gridName,
    rowId,
    goToCard,
    decimals,
    alternativeCosts,
    valueTable,
    subTableText,
    ...extProps
}) => {

    if (type === MULTI_SELECT_TYPE) {
        return (
            <MultiSelectValue
                width={width}
                value={value}
                valueText={valueText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }
    if (type === SELECT_TYPE || type === CUSTOM_SELECT_TYPE) {
        return (
            <SelectValue
                width={width}
                value={value}
                valueText={valueText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }

    if (type === SUBTABLE_TYPE) {
        return (
            <SubTableValue
                width={width}
                value={valueTable}
                valueText={subTableText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }

    if (type === FILE_TYPE) {
        return (
            <FilesValue
                width={width}
                value={value}
                id={rowId}
                valueText={subTableText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }

    if (type === STATE_TYPE || type === CUSTOM_STATE_TYPE) {
        return (
            <StateValue
                width={width}
                value={value}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
            />
        );
    }

    if (type === LABELS_TYPE) {
        return (
            <>
                {!value
                    ? t('All')
                    : 
                    <div style={{minWidth: "500px"}}>
                        {value.map((n, i) => (
                            <Label key={n.name} className="label-margin">
                                {t(n.name)}
                            </Label>
                        ))}
                    </div>}
            </>
        );
    }

    if (type === ENUM_TYPE || type === TIME_ZONE_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {value ? valueText : ''}
            </TextCropping>
        );
    }

    if (type === ACTIVE_TYPE) {
        return (
            <ToggleCheckbox
                id={rowId}
                value={value}
                disabled={isDisabled}
                toggleIsActive={toggleIsActive}
            />
        );
    }

    if (type === BOOLEAN_TYPE) {
        return <>{value === true ? t('Yes') : value === false ? t('No') : ''}</>;
    }

    if (type === MULTI_ENUM_TYPE) {
        return value
            ? value.reduce((str, item) => `${str}${str.length ? ', ' : ''}${item.name}`, '')
            : '';
    }

    if (type === VALIDATED_NUMBER_TYPE) {
        let cellValue;
        if (valueText !== undefined && valueText !== null && valueText) {
            cellValue = valueText;
        } else if (decimals !== undefined && decimals !== null) {
            cellValue = new Intl.NumberFormat(locale).format(
                numbersFormat(tryParseFloatFromRuFormat(value).toFixed(decimals)),
            ); // new Intl.NumberFormat().format() чтоб разделитель дробной части брался из настроек системы)
        } else {
            cellValue = new Intl.NumberFormat(locale).format(numbersFormat(tryParseFloatFromRuFormat(value)));
        }

        if (valueTooltip !== undefined && valueTooltip !== null && valueTooltip.length > 0) {
            return <span title={valueTooltip}>{cellValue}</span>;
        }
        return <>{cellValue}</>;
    }

    if (value === undefined || value === null) return '';

    if (type === NUMBER_TYPE) {
        if (decimals !== undefined && decimals !== null) {
            return (
                <>
                    {new Intl.NumberFormat(locale).format(
                        numbersFormat(tryParseFloatFromRuFormat(value).toFixed(decimals)),
                    )}
                </>
            ); // new Intl.NumberFormat().format() чтоб разделитель дробной части брался из настроек системы)
        }
        return <>{new Intl.NumberFormat(locale).format(numbersFormat(tryParseFloatFromRuFormat(value)))}</>;
    }

    if (type === LINK_TYPE) {
        const handleGoToCard = () => {
            goToCard(true, rowId, source);
        };

        return (
            <>
                {goToCard ? (
                    <div className="link-cell" onClick={handleGoToCard}>
                        <TextCropping width={width} indexColumn={indexColumn}>
                            {value}
                        </TextCropping>
                    </div>
                ) : (
                    <TextCropping width={width} indexColumn={indexColumn}>
                        {value}
                    </TextCropping>
                )}
            </>
        );
    }

    if (type === LOCAL_DATE_TIME) {
        // return (
        //     <TextCropping width={width} indexColumn={indexColumn}>
        //         {dateToUTC(value, 'DD.MM.YYYY HH:mm')}
        //     </TextCropping>
        // );
        let testDateUtc = moment.utc(value, "DD.MM.YY HH:mm");
        let localDate = moment(testDateUtc).local();
        let localStringDate = localDate.format("DD.MM.YY HH:mm");
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {localStringDate}
            </TextCropping>
        );
    }

    return (
        <TextCropping width={width} indexColumn={indexColumn}>
            {value}
        </TextCropping>
    );
};

export default React.memo(CellValue);
