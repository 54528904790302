import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { formatDate, parseDate } from '../../utils/dateTimeFormater';
import { useTranslation } from 'react-i18next';

const Date = ({
    value,
    name,
    onChange,
    isDisabled,
    noLabel,
    text,
    placeholder,
    className,
    isRequired,
    error,
    maxDate,
    minDate,
    displayNameKey,
    isPeriod,
}) => {
    const getClassNames = () => {
        const classNames = [];

        if (error) {
            classNames.push('input-error');
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    const { t } = useTranslation();

    const onChangePeriod = (dates, e) => {
        onChange(e, { name: name, value: {startDate: dates[0], endDate: dates[1]}});
    }

    const PeriodInput = ({value, onClick}) => {
        return <Input onClick={onClick} value={value} />
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(displayNameKey ? displayNameKey : text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            {!isPeriod ? 
                <DatePicker
                    disabled={isDisabled || false}
                    placeholderText={placeholder}
                    className={getClassNames()}
                    isClearable={!(isDisabled || false)}
                    selected={parseDate((value || '').substring(0, 10))}
                    locale={localStorage.getItem('i18nextLng')}
                    dateFormat="dd.MM.yy"
                    maxDate={parseDate((maxDate || '').substring(0, 10))}
                    minDate={parseDate((minDate || '').substring(0, 10))}
                    onChangeRaw={(e) => {
                        e.target.value = value;
                    }}
                    onChange={(date, e) => {
                        onChange(e, { name: name, value: date ? formatDate(date) : null });
                    }}

                /> :
                <DatePicker
                    selected={value && value.startDate}
                    disabled={isDisabled || false}
                    placeholderText={placeholder}
                    className={getClassNames()}
                    isClearable={!(isDisabled || false)}
                    locale={localStorage.getItem('i18nextLng')}
                    dateFormat="dd.MM.yy"
                    startDate={value && value.startDate}
                    endDate={value && value.endDate}
                    onChange={(dates, e) => onChangePeriod(dates, e)}
                    customInput={<PeriodInput />}
                    selectsRange={true}

                />
            }
            
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default Date;
