import React, { useRef, useState, useCallback, useEffect } from 'react';
import CellValue from '../../ColumnsValue';
import { Button, Form, Icon, Loader, Modal, Popup, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import FormField from '../../BaseComponents';
import SubTable from '../../SuperGrid/components/subTableGrid';
import { LINK_TYPE } from '../../../constants/columnTypes';
import _ from 'lodash';

const ModalComponent = ({ element, props, children }) => {
    if (!element) {
        return <>{children}</>;
    }
    return React.cloneElement(element, props, children);
};

const SubTableCell = ({
    value,
    valueText,
    valueTooltip,
    column,
    loadList,
    indexRow,
    backlights,
    indexColumn,
    modalCard,
    gridName,
    t,
    checkForEditing,
    invokeMassUpdate,
    setSubTableEditing,
    subTableEditing,
    changeCellValue,
    status,
    rowId,
    isReadOnly,
    rowNumber,
    goToCard,
}) => {
    const contextRef = useRef(null);

    let [open, setOpen] = useState(false);
    let [valueForm, setValue] = useState(value);
    let [progress, setProgress] = useState(false);

    const copyToClipboard = () => {
        const text = contextRef.current && contextRef.current.textContent;
        navigator.clipboard &&
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };

    useEffect(() => {
        setValue(value);
    }, [value])

    const handleClick = (rowId, fieldName, state) => {
        handleOpen();
    };

    const handleOpen = () => {
        setOpen(true);
        setSubTableEditing(true);
        setValue(valueText ? { value, name: valueText } : value);
    };

    const handleClose = () => {
        setOpen(false);
        setSubTableEditing(false);
        setValue(value);
    };

    const handleSave = () => {
        setOpen(false);
        changeCellValue(indexRow, valueForm, column.name)
        setSubTableEditing(false);
    };

    const handleChange = useCallback((e, { value }) => {
        setValue(value);
    }, []);

    const handleCellClick = e => {
        column.type !== LINK_TYPE ? handleClick(rowId, column.name, status) : e.stopPropagation();
    };


    return (
        <>
            <Table.Cell className={`value-cell ${isReadOnly && 'cell-grid-disabled'}`} disabled={isReadOnly}>
                <div className={`cell-grid `}   >
                    <div
                        className={`cell-grid-value`}
                        ref={contextRef}
                        onClick={handleCellClick}
                        
                    >
                        <CellValue
                            {...column}
                            indexRow={indexRow}
                            indexColumn={indexColumn}
                            value={valueForm}
                            valueText={valueText}
                            width={column.width}
                            gridName={gridName}
                            rowId={rowId}
                            t={t}
                            goToCard={goToCard}
                        />
                    </div>
                    <div>
                        {progress ? (
                            <Loader active={true} size="mini" />
                        ) : null}
                    </div>
                </div>
            </Table.Cell>
                <Modal open={open} size="tiny" closeOnDimmerClick={false}>
                    <Modal.Header>
                        {t("edit_btn") + " " + t(column.name).toLowerCase()}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form onSubmit={handleSave}>
                                <FormField {...column} autoFocus value={valueForm} onChange={handleChange} />
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                        <Button
                            color="primary"
                            disabled={_.isEqual(valueForm, value)}
                            onClick={handleSave}
                        >
                            {t('SaveButton')}
                        </Button>
                    </Modal.Actions>
                </Modal> 
            
        </>
    );
};

export default React.memo(
    SubTableCell /*, (prevProps, nextProps) => {
    return prevProps.column === nextProps.column &&
        prevProps.loadList === nextProps.loadList &&
        prevProps.indexRow === nextProps.indexRow &&
        prevProps.indexColumn === nextProps.indexColumn &&
        prevProps.modalCard === nextProps.modalCard &&
        prevProps.gridName === nextProps.gridName &&
        prevProps.t === nextProps.t &&
        prevProps.checkForEditing === nextProps.checkForEditing &&
        prevProps.invokeMassUpdate === nextProps.invokeMassUpdate &&
        _.isEqual(prevProps.row, nextProps.row)
}*/,
);