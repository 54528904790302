import { all, delay, put, takeEvery, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { downloader, postman, setAccessToken } from '../utils/postman';
import downloadFile from '../utils/downloadFile';
import { toast } from 'react-toastify';
import { showModal } from './modal';
import { filtersSelector, getListRequest } from './gridList';
import { currentLocationSelector } from './general';
import {DEMO_GRID_ENTITIES_GRID} from '../constants/grids';

//*  TYPES  *//

const TEMPLATE_UPLOAD_REQUEST = 'TEMPLATE_UPLOAD_REQUEST';
const TEMPLATE_UPLOAD_SUCCESS = 'TEMPLATE_UPLOAD_SUCCESS';
const TEMPLATE_UPLOAD_ERROR = 'TEMPLATE_UPLOAD_ERROR';

const DATA_LOADING_REQUEST = 'DATA_LOADING_REQUEST';
const DATA_LOADING_SUCCESS = 'DATA_LOADING_SUCCESS';
const DATA_LOADING_ERROR = 'DATA_LOADING_ERROR';

const GET_INSTRUCTION_REQUEST = 'GET_INSTRUCTION_REQUEST';
const GET_INSTRUCTION_SUCCESS = 'GET_INSTRUCTION_SUCCESS';
const GET_INSTRUCTION_ERROR = 'GET_INSTRUCTION_ERROR';

const GET_CONFIGURATION_COLUMNS_REQUEST = 'GET_CONFIGURATION_COLUMNS_REQUEST';
const GET_CONFIGURATION_COLUMNS_SUCCESS = 'GET_CONFIGURATION_COLUMNS_SUCCESS';
const GET_CONFIGURATION_COLUMNS_ERROR = 'GET_CONFIGURATION_COLUMNS_ERROR';

const CHANGE_ORDERS_LOADING = 'CHANGE_ORDERS_LOADING';

const CHANGE_SELECTED_COLUMNS = 'CHANGE_SELECTED_COLUMNS';
const SAVE_SELECTED_COLUMNS = 'SAVE_SELECTED_COLUMNS';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    ordersLoading: false,
    columns: [],
    selectedColumns: [],
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case TEMPLATE_UPLOAD_REQUEST:
        case DATA_LOADING_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case TEMPLATE_UPLOAD_SUCCESS:
        case TEMPLATE_UPLOAD_ERROR:
        case DATA_LOADING_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case DATA_LOADING_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CHANGE_ORDERS_LOADING:
            return {
                ...state,
                ordersLoading: payload,
            };
        case GET_CONFIGURATION_COLUMNS_REQUEST:
        case GET_CONFIGURATION_COLUMNS_ERROR:
            return {
                ...state,
                columns: [],
            };
        case GET_CONFIGURATION_COLUMNS_SUCCESS:
            return {
                ...state,
                columns: payload
            };
        case CHANGE_SELECTED_COLUMNS:
            return {
                ...state,
                selectedColumns: payload,
            };
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const templateUploadRequest = payload => {
    return {
        type: TEMPLATE_UPLOAD_REQUEST,
        payload,
    };
};

export const dataLoadingRequest = payload => {
    return {
        type: DATA_LOADING_REQUEST,
        payload,
    };
};

export const getInstructionRequest = payload => {
    return {
        type: GET_INSTRUCTION_REQUEST,
        payload
    }
};

export const getConfigurationColunmsRequest = payload => {
    return {
        type: GET_CONFIGURATION_COLUMNS_REQUEST,
        payload
    }
};

export const changeSelectedColumnsRequest = payload => {
    return {
        type: CHANGE_SELECTED_COLUMNS,
        payload
    }
};

export const saveSelectedColumnsRequest = payload => {
    return {
        type: SAVE_SELECTED_COLUMNS,
        payload
    }
};

//*  SELECTORS *//
const stateSelector = state => state.loadingData;
export const progressLoadingDataSelector = createSelector(
    stateSelector,
    state => state.progress,
);

export const ordersLoadingSelector = createSelector(stateSelector, state => state.ordersLoading);

export const columnsSelector = createSelector([stateSelector, (state, selectedColumns) => selectedColumns], (state, selectedColumns = []) => {
    return (state.columns || []).filter(i => !selectedColumns.map(m => m.name).includes(i.name)).map(item => ({
        ...item,
        isMarker: item.isRequired,
    }))
});
export const selectedColumnsSelector = createSelector([stateSelector, columnsSelector], (state, columns) => {
    return state.selectedColumns && state.selectedColumns.length ? state.selectedColumns : columns.filter(i => i.isRequired)
});
//*  SAGA  *//

function* templateUploadSaga({ payload }) {
    try {
        const { typeApi } = payload;
        const saveConfig = yield postman.get(`/userSettings/import_${typeApi}`);

        yield put({
            type: CHANGE_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : [],
        });

        const columns = yield select(selectedColumnsSelector);

        let res;

        if (columns && columns.length) {
            res = yield downloader.post(`/import/${typeApi}/excelTemplate`, {columns: columns.map(i => i.name)}, {
                responseType: 'blob',
            });
        } else {
            res = yield downloader.post(`/import/${typeApi}/excelTemplate`, {columns: null}, {
                responseType: 'blob',
            });
        }

        downloadFile(res);
        yield put({ type: TEMPLATE_UPLOAD_SUCCESS });
    } catch (e) {
        yield put({
            type: TEMPLATE_UPLOAD_ERROR,
        });
    }
}

function* dataLoadingSaga({ payload }) {
    try {
        const { form, typeApi, callBackFunc } = payload;
        yield put({
            type: CHANGE_ORDERS_LOADING,
            payload: typeApi === 'orders',
        });
        const result = yield postman.post(`/import/${typeApi}/importFromExcel`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (!result.isError) {
            yield put({
                type: DATA_LOADING_SUCCESS,
            });

            const currentLocation = yield select(currentLocationSelector);

            if (currentLocation.includes(DEMO_GRID_ENTITIES_GRID)) {
                const filters = yield select(filtersSelector);
                yield put(getListRequest({ ...filters, notLoader: false }));
            }

        }



        if (result.isError && result.message) {
            yield put({
                type: DATA_LOADING_ERROR,
            });
            toast.error(result.message, { autoClose: false});
        } else if (!result.isError && result.message) {
            toast.info(result.message, { autoClose: false});
        } else {
            yield put(showModal(result));
        }
    } catch (e) {
        yield put({
            type: DATA_LOADING_ERROR,
        });
    } finally {
        yield put({
            type: CHANGE_ORDERS_LOADING,
            payload: false,
        })
    }
}

function* getInstructionSaga({ payload }) {
    try {
        const { fileName } = payload;

        const result = yield downloader.get(`/static/${fileName}`, { responseType: 'blob' });
        const { data } = result;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        yield put({
            type: GET_INSTRUCTION_SUCCESS
        });
    } catch (e) {
        yield put({
            type: GET_INSTRUCTION_ERROR
        })
    }
}

function* getConfigurationColunmsSaga({payload}) {
    try {
        // const {typeApi} = payload;
        //  const result = yield postman.get(`import/${typeApi}/configuration`);

        //  yield put({
        //      type: GET_CONFIGURATION_COLUMNS_SUCCESS,
        //      payload: result.columns
        //  });

        // const saveConfig = yield postman.get(`/userSettings/import_${typeApi}`);

        // yield put({
        //     type: CHANGE_SELECTED_COLUMNS,
        //     payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        // });

        const {typeApi} = payload;
        const result = yield postman.get('appConfiguration');
        let importColumns = [];
        result.import.map(item => {
            if (item.name === typeApi) {
                importColumns = item.columns;
            }
        });
        yield put ({
            type: GET_CONFIGURATION_COLUMNS_SUCCESS,
            payload: importColumns
        });

        const saveConfig = yield postman.get(`/userSettings/import_${typeApi}`);

        yield put({
            type: CHANGE_SELECTED_COLUMNS,
            payload: saveConfig.value ? JSON.parse(saveConfig.value) : {},
        });

    } catch (e) {
        yield put({
            type: GET_CONFIGURATION_COLUMNS_ERROR
        })
    }
}

function* saveSelectedColumnsSaga({ payload }) {
    try {
        const { typeApi, callbackSuccess } = payload;
        const columns = yield select(selectedColumnsSelector);
        const result = yield postman.post(`/userSettings/import_${typeApi}`, {
            value: JSON.stringify(columns),
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        console.log("___error", e)
    }
}

export function* saga() {
    yield all([
        takeEvery(TEMPLATE_UPLOAD_REQUEST, templateUploadSaga),
        takeEvery(DATA_LOADING_REQUEST, dataLoadingSaga),
        takeEvery(GET_INSTRUCTION_REQUEST, getInstructionSaga),
        takeEvery(GET_CONFIGURATION_COLUMNS_REQUEST, getConfigurationColunmsSaga),
        takeEvery(SAVE_SELECTED_COLUMNS, saveSelectedColumnsSaga),
    ]);
}
