import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const Number = ({
    value = '',
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    placeholder,
    isRequired,
    isInteger,
    autoFocus,
    focused,
    displayNameKey,
}) => {
    const { t } = useTranslation()

    const handleOnChange = (e, { value }) => {
        if (value.length <= 9) {
            onChange(e, {
                name,
                value: value.replace(/[^\d]/g, ''),
            })
        }
    }
    const handleOnChangeNumber = (e, { value }) => {
        const validatedNumber = value === '' || value === '-'
            || (value === parseFloat(value.replace(',', '.')).toString().replace('.', ',')
                + ",")
            || value.toString().slice(-1) === '0' ?
            value :
            parseFloat(value.replace(',', '.')).toString().replace('.', ',')
        if (value === validatedNumber
            && validatedNumber.toString().length <= 14
            && value.split(',').length <= 2) {
            onChange(e, { name, value: value })
        }
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(displayNameKey ? displayNameKey : text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <Input
                placeholder={placeholder}
                className={className}
                type={'text'}
                error={error}
                disabled={isDisabled || false}
                name={name}
                value={!!value && value.toString().replace('.', ',') || value}
                step="any"
                autoFocus={autoFocus || focused}
                onChange={isInteger ? handleOnChange : handleOnChangeNumber}
                autoComplete="off"
            />
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    )
}
export default Number
