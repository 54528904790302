import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FormField from '../formField'
import {
    BIG_TEXT_TYPE,
    DATE_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
} from '../../../../constants/columnTypes';
import { columnsTypesConfigSelector } from '../../../../ducks/representations'
import { getRegisterDefaultsRequest } from '../../../../ducks/gridActions';
import { REGISTER_CARRIER_CARD, REGISTER_DM_CARD } from '../../../../constants/grids'

const CreateRegister = ({ type, form = {}, onChange, isNotUniqueNumber, uniquenessNumberCheck, error }) => {
    
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegisterDefaultsRequest({type}))
    }, [])

    const columnsConfig = useSelector((state) => columnsTypesConfigSelector(state, type === "registerDm" ? REGISTER_DM_CARD : REGISTER_CARRIER_CARD))


    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="orderNumber"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isRequired
                            onChange={onChange}
                        />
                    </Grid.Column>
                    {type === "registerDm" ? 
                        <Grid.Column>
                            <FormField
                                name="penaltyDmType"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                isRequired
                                onChange={onChange}
                            />
                        </Grid.Column> :
                        <Grid.Column>
                            <FormField
                                name="penaltyCarrierType"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                isRequired
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                    <Grid.Column>
                        <FormField
                            name="fineAmountFact"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            isRequired
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    {type === "registerDm" && 
                        <Grid.Column>
                            <FormField
                                name="toleranceFact"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                    {type === "registerDm" && 
                        <Grid.Column>
                            <FormField
                                name="idleTripPointId"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                    {type === "registerCarrier" && 
                        <Grid.Column>
                            <FormField
                                name="lateTripPointId"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                    {type === "registerCarrier" && 
                        <Grid.Column>
                            <FormField
                                name="lateTime"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                    <Grid.Column>
                        <FormField
                            name="registerNumber"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="claimNumber"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="claimCloseType"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    {type === "registerDm" && 
                        <Grid.Column>
                            <FormField
                                name="claimPaymentDate"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="offsettingNumber"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="offsettingDate"
                            columnsConfig={columnsConfig}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    {type === "registerDm" && 
                        <Grid.Column>
                            <FormField
                                name="claimDate"
                                columnsConfig={columnsConfig}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default CreateRegister;
