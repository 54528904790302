import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Grid, Icon, Message, Popup, Modal, Form, Input, Dropdown, TextArea, Menu } from 'semantic-ui-react'
import moment from 'moment'
import Date from '../../BaseComponents/Date'
import Select from '../../BaseComponents/Select'
import Search from '../../../components/Search'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    canExportToExcelSelector,
    canImportFromExcelSelector,
    canDeleteRowsSelector,
    datePeriodFilterTypesSelector,
    exportProgressSelector,
    exportToExcelRequest,
    importFromExcelRequest,
    importProgressSelector,
    deleteProgressSelector,
    deleteRowsRequest,
    claimUploadProgressSelector,
    gridValidateUploadClaimRequest,
    gridUploadClaimRequest,
    sendClaimToSapProgressSelector,
    gridSendClaimToSapRequest,
    gridFormClaimRequest,
    gridValidateForClaimFormRequest,
    gridValidateMassRejectRequest,
    massRejectValidationProgressSelector,
    claimFormProgressSelector,
    claimValidationProgressSelector,
} from '../../../ducks/gridList'
import { toast } from 'react-toastify'
import CustomIcon from '../../CustomIcon'
import FieldsConfig from './representations'
import FreelanceEventModal from './freelanceEventModal'
import { uploadFileRequest } from '../../../ducks/documents'
import {
    getRepresentationsRequest,
    representationsSelector,
    setRepresentationRequest,
} from '../../../ducks/representations'
import {
    invokeAdditionalActionRequest,
    progressMassUpdateSelector,
    progressUtdCheckSelector,
    checkForUtdDownloadRequest,
    downloadUtdDocumentRequest,
    checkFreelanceEventRequest,
    progressFreelanceEventCheckSelector,
    progressDownloadSelector,
    invokeMassUpdateRequest,
    formRegisterRequest,
    progressFormRegister,
    getRegisterDefaultsRequest,
    formDefaultsSelector,
    progressMassRejectSelector,
    massRejectByDmRequest,
    progressDownloadOrderAllFilesSelector,
    downloadOrderAllFilesRequest,
    progressGetDateDisclaimerWarrantySelector,
    getDateDisclaimerWarrantyRequest,
    fieldsMassUpdateRequest
} from '../../../ducks/gridActions'
import { ordersLoadingSelector } from "../../../ducks/loadingData"
import File from '../../BaseComponents/File'
import MassChangesModal from "../../MassChangesModal/MassChangesModal";

const Header = ({
    isCreateBtn,
    searchValue,
    searchOnChange,
    counter,
    clearFilter,
    disabledClearFilter,
    loadList,
    name,
    additionalActions,
    tabs,
    activeTab,
    setActiveTab,
    setSelected,
    representationName,
    filter,
    goToCard,
    width,
    pageLoading,
    createFreelanceEvent,
    selectedRows,
    setDatePeriodType,
    periodType,
    rows,
}) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const fileUploader = useRef(null)

    const isImportBtn = useSelector(state => canImportFromExcelSelector(state, name))
    const isExportBtn = useSelector(state => canExportToExcelSelector(state, name))
    const isDeleteBtn = useSelector(state => canDeleteRowsSelector(state, name))

    const importLoader = useSelector(state => importProgressSelector(state))
    const invokeActionLoader = useSelector(state => progressMassUpdateSelector(state))
    const exportLoader = useSelector(state => exportProgressSelector(state))
    const deleteLoader = useSelector(state => deleteProgressSelector(state))
    const loadingOrders = useSelector(ordersLoadingSelector)
    const utdLoader = useSelector(state => progressUtdCheckSelector(state))
    const utdExportLoader = useSelector(state => progressDownloadSelector(state))
    const formRegisterLoader = useSelector(state => progressFormRegister(state))
    const freelanceEventCheckLoader = useSelector(state => progressFreelanceEventCheckSelector(state))
    const datePeriodTypes = useSelector(state => datePeriodFilterTypesSelector(state, name))
    const formRegisterDefaults = useSelector(state => formDefaultsSelector(state))
    const claimUploadLoader = useSelector(state => claimUploadProgressSelector(state))
    const claimValidationLoader = useSelector(state => claimValidationProgressSelector(state))
    const claimFormLoader = useSelector(state => claimFormProgressSelector(state))
    const sendClaimToSapLoader = useSelector(state => sendClaimToSapProgressSelector(state))
    const massRejectLoader = useSelector(state => progressMassRejectSelector(state))
    const massRejectValidationLoader = useSelector(state => massRejectValidationProgressSelector(state))
    const downloadOrderAllFilesLoader = useSelector(state => progressDownloadOrderAllFilesSelector(state))
    const dateDisclaimerWarrantyLoader = useSelector(state => progressGetDateDisclaimerWarrantySelector(state))

    const representations = useSelector(state => representationsSelector(state, name))

    const [disableAddActions, setDisableAddActions] = useState(true)
    const [disableFreelanceEventBtn, setDisableFreelanceEventBtn] = useState(true)
    const [selectedIds, setSelectedIds] = useState([])
    const [showUtd, setShowUtd] = useState(false)
    const [showFormRegister, setShowFormRegister] = useState(false)
    const [showMassReject, setShowMassReject] = useState(false)
    const [showUploadClaim, setShowUploadClaim] = useState(false)
    const [showFreelanceEvent, setShowFreelanceEvent] = useState(false)
    const [utdNumber, setUtdNumber] = useState(null)
    const [formRegisterParams, setFormRegisterParams] = useState(null)
    const [uploadClaimParams, setUploadClaimParams] = useState(null)
    const [datePeriodFilterValue, setDatePeriodFilterVaule] = useState(periodType)
    const [showDatePeriodFilter, setShowDatePeriodFilter] = useState(false)
    const [showMassDateEditModal, setShowMassDateEditModal] = useState(false)
    const [massEditModalValue, setMassEditModalValue] = useState(null)
    const [massRejectModalValue, setMassRejectModalValue] = useState(null)
    const [isClaimUpload, setIsClaimUpload] = useState(false)
    const [dateDisclaimerOfWarranties, setDateDisclaimerOfWarranties] = useState(null)
    const [disclaimerOfWarrantiesAvailable, setDisclaimerOfWarrantiesAvailable] = useState(true)

    useEffect(() => {
        const selectedRows = selectedIds.map(id => (
            rows.find(row => row.id === id)
        ))

        const disabled = !!selectedRows.find(row => (
            !row?.endDate
                || moment().isAfter(moment(row.endDate.replace(/(.+)\.(\d{2})/g, '$1.20$2'), 'DD.MM.YYYY'), 'day')
                || row?.status === 'children'
        ))

        setDisclaimerOfWarrantiesAvailable(!disabled)
    }, [rows, selectedIds])    

    useEffect(() => {
        const ids = []
        for (const entry of selectedRows) {
            ids.push(entry)
        }
        if (ids.length === 1) {
            setDisableFreelanceEventBtn(false)
        } else {
            setDisableFreelanceEventBtn(true)
        }
        if (ids.length === 0) {
            setDisableAddActions(true)
        } else {
            setDisableAddActions(false)
        }
        setSelectedIds(ids)
    }, [selectedRows])

    useEffect(() => {
        setDatePeriodFilterVaule(periodType)
    }, [periodType])

    useEffect(() => {
        if (representationName) {
            setShowDatePeriodFilter(true)
        } else {
            setShowDatePeriodFilter(false)
        }
    }, [representationName])

    const exportExcel = () => {
        dispatch(exportToExcelRequest({ name, filter: filter.filter, ids: selectedIds }))
    }

    const handleClickFileUploader = () => fileUploader && fileUploader.current.click()

    const importExcel = () => {
        setIsClaimUpload(false)
        setTimeout(() => {
            handleClickFileUploader()
        }, 500)
    }
    const [isOpenDeleteConfirmedModal, setToggleDeleteConfirmedModal] = useState(false)

    const openDeleteConfirmedModalHandler = () => setToggleDeleteConfirmedModal(true)
    const closeDeleteConfirmedModalHandler = () => setToggleDeleteConfirmedModal(false)
    const confirmedActionDeleteRowsHandler = () => {
        closeDeleteConfirmedModalHandler()
        deleteRows()
    }
    const deleteRows = () => {
        dispatch(deleteRowsRequest({
            name,
            ids: selectedIds,
            callbackSuccess: () => loadList(false, true),
        }))
    }

    const checkFreelanceEvent = () => {
        dispatch(checkFreelanceEventRequest({
            ids: selectedIds,
            callbackSuccess: () => {
                setShowFreelanceEvent(true)
                console.log("SUCCESS")
            },
        }))
    }

    const checkForUtdDownload = (callbackSuccess) => {
        dispatch(checkForUtdDownloadRequest({
            ids: selectedIds,
            number: utdNumber,
            callbackSuccess,
        }))
    }

    const showFormRegisterModal = () => {
        dispatch(getRegisterDefaultsRequest({
            type: name, isFormRegister: true, callbackSuccess: () => {
                setShowFormRegister(true)
            }
        }))
    }

    const handleFormRegister = () => {
        dispatch(formRegisterRequest({
            type: name,
            form: formRegisterParams,
            callbackSuccess: () => {
                setShowFormRegister(false)
                loadList(false, true)
                setFormRegisterParams(null)
            }
        }))
    }

    const executeAdditionalAction = (actionName, forAllRows) => {
        const ids = []
        for (const entry of selectedRows) {
            ids.push(entry)
        }
        if (forAllRows || (ids.length > 0)) {
            dispatch(invokeAdditionalActionRequest({
                ids: ids,
                gridName: name,
                actionName: actionName,
                callbackSuccess: () => loadList(false, true),
            }))
        }
    }

    const onFilePicked = e => {
        const file = e.target.files[0]

        const data = new FormData()
        data.append('FileName', file.name)
        data.append('FileContent', new Blob([file], { type: file.type }))
        data.append('FileContentType', file.type)

        if (!isClaimUpload) {
            dispatch(
                importFromExcelRequest({
                    name,
                    form: data,
                    callbackSuccess: () => loadList(false, true),
                }),
            )
        } else {
            setIsClaimUpload(false)
            const form = new FormData()
            form.append('formFile', file)

            dispatch(
                uploadFileRequest({
                    form: form,
                    fileName: file.name,
                    callbackSuccess: (fileId) => {
                        dispatch(gridUploadClaimRequest({
                            name,
                            number: selectedIds[0],
                            fileId: fileId,
                            fileName: file.name,
                            callbackSuccess: () => loadList(false, true),
                        }))
                    }
                })
            )
        }

        e.target.value = null
    }

    const getRepresentations = callBackFunc => {
        dispatch(getRepresentationsRequest({ key: name, callBackFunc }))
    }

    const changeRepresentation = (key, isEdit) => {
        dispatch(
            setRepresentationRequest({
                gridName: name,
                value: key,
                callbackSuccess: () => {
                    setSelected(new Set())
                    pageLoading && pageLoading()
                },
            }),
        )
    }

    const handleGoToCard = () => {
        goToCard(false, null, name)
    }

    const handleChangeUtdNumber = (e, { name, value }) => {
        setUtdNumber({ ...utdNumber, [name]: value })
    }

    const handleChangeMassRejectValue = (e, { name, value }) => {
        setMassRejectModalValue({ ...massRejectModalValue, [name]: value })
    }

    const handleChangeRegisterParams = (e, { name, value }) => {
        setFormRegisterParams({ ...formRegisterParams, [name]: value })
    }

    const handleChangeUploadClaimParams = (e, { name, value }) => {
        setUploadClaimParams({ ...uploadClaimParams, [name]: value })
    }

    const handleFormClaim = () => {
        dispatch(gridValidateForClaimFormRequest({
            ids: selectedIds,
            callbackSuccess: () => {
                dispatch(gridFormClaimRequest({
                    ids: selectedIds
                }))
            }
        }))
    }

    const handleDownloadUtd = () => {
        checkForUtdDownload(() => {
            dispatch(downloadUtdDocumentRequest({
                number: utdNumber,
                ids: selectedIds,
                callbackSuccess: () => {
                    setShowUtd(false)
                    setUtdNumber(null)
                    loadList(false, true)
                },
                callbackError: () => {
                    toast.error(t("exportUtdFailure"), {
                        autoClose: false,
                    })
                }
            }))
        });
    };

    const handleMassReject = () => {
        dispatch(massRejectByDmRequest({
            form: { ids: selectedIds, ...massRejectModalValue },
            callbackSuccess: () => {
                setMassRejectModalValue(null)
                setShowMassReject(false)
                loadList(false, true)
            }
        }))
    }

    const closeFreelanceEventModal = () => {
        setShowFreelanceEvent(false)
    }

    const handleChangeDatePeriod = (e, { value }) => {
        setDatePeriodType(value)
    }
    const handleUploadClaim = () => {
        setIsClaimUpload(true)

        dispatch(gridValidateUploadClaimRequest({
            id: selectedIds[0],
            callbackSuccess: handleClickFileUploader
        }))
    }

    const handleSendClaimToSap = () => {
        dispatch(gridSendClaimToSapRequest({
            id: selectedIds[0],
            callbackSuccess: () => {
                loadList(false, true)
            }
        }))
    }

    const saveMassUpdate = (form, urlName, callback) => {
        dispatch(fieldsMassUpdateRequest({
            ids: selectedIds,
            name,
            urlName,
            form,
            callbackSuccess: callback
        }))
    }

    const saveChangeMassEndDate = (form) => {
        saveMassUpdate(form, 'changeMassEndDate', () => {
            setShowMassDateEditModal(false)
            loadList(false, true)
        });
    }

    const handleGetDateDisclaimerOfWarranties = useCallback(() => {
        dispatch(getDateDisclaimerWarrantyRequest({
            ids: selectedIds,
            callbackSuccess: (result) => {
                setDateDisclaimerOfWarranties(result)
            }
        }))
    }, [selectedIds])

    const handleDisclaimerOfWarranties = useCallback(() => {
        saveMassUpdate({
            endDate: dateDisclaimerOfWarranties
        }, 'setDateDisclaimerWarranty', () => {
            setDateDisclaimerOfWarranties(null)
            loadList(false, true)
        });
    }, [dateDisclaimerOfWarranties, selectedIds])

    const handleChangeMassEditValue = (e, { value }) => {
        setMassEditModalValue(value)
    }

    const handleShowMassReject = () => {
        dispatch(gridValidateMassRejectRequest({
            ids: selectedIds,
            callbackSuccess: () => {
                setShowMassReject(true)
            }
        }))
    }

    useEffect(() => {
        loadList(false, true)
    }, [activeTab])
    const tab = useMemo(() => tabs.find(p => p.value == activeTab), [activeTab])

    const handleDownloadAllOrderFiles = () => {
        dispatch(downloadOrderAllFilesRequest({
            id: selectedIds[0],
            callbackError: () => {
                toast.error(t("downloadAllOrderFilesFailure"), {
                    autoClose: false,
                })
            }
        }))
    }

    return (
        <Grid className="grid-header-panel">
            <Grid.Row style={{ paddingTop: "0", paddingBottom: "0", margin: '0.5rem 0', minHeight: '42px' }}>
                <Grid.Column className='header-text'>
                    <h2 style={{ paddingLeft: "1%" }}>
                        {t(name)}
                    </h2>
                </Grid.Column>
                <Grid.Column className='header-tabs'>
                    {
                        tabs && !!tabs.length &&
                        <Menu compact>
                            {
                                tabs.map(tab => (
                                    <Menu.Item
                                        active={activeTab === tab.value}
                                        onClick={() => setActiveTab(tab.value)}
                                    >
                                        {t(tab.displayNameKey)}
                                    </Menu.Item>)
                                )
                            }
                        </Menu>
                    }
                </Grid.Column>
            </Grid.Row>
            <hr className="grid-name-line" />
            <Grid.Row>
                <Grid.Column width={5} style={{ display: "flex" }}>
                    <FieldsConfig
                        gridName={name}
                        width={width}
                        representationName={representationName}
                        getRepresentations={getRepresentations}
                        changeRepresentation={changeRepresentation}
                        representations={representations}
                    />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                    <span className="records-counter">{t('totalCount', { count: counter })}</span>
                </Grid.Column>
                <Grid.Column width={3}>
                    {
                        loadingOrders
                            ? <Message info>
                                <Icon name="clock" />
                                Идет загрузка данных...
                            </Message>
                            : null
                    }

                </Grid.Column>
                <Grid.Column width={7} className="grid-right-elements">
                    {datePeriodTypes && showDatePeriodFilter && (
                        <Form.Field style={{ paddingRight: "1%" }}>
                            <div className={`form-select`} style={{ width: "200px" }}>
                                <Dropdown
                                    clearable
                                    value={datePeriodFilterValue}
                                    text={t(datePeriodFilterValue)}
                                    labeled
                                    floating
                                    button
                                    className='icon'
                                    icon="calendar"
                                    placeholder={t("datePeriodFilter")}
                                    onChange={handleChangeDatePeriod}
                                    style={{ width: "200px" }}
                                >
                                    <div role="listbox" className={`menu limited-listbox transition`}>
                                        {datePeriodTypes && datePeriodTypes.length ? (
                                            datePeriodTypes.map(item => (
                                                <Dropdown.Item
                                                    key={item}
                                                    selected={datePeriodFilterValue && item === datePeriodFilterValue}
                                                    active={datePeriodFilterValue && item === datePeriodFilterValue}
                                                    value={item}
                                                    onClick={() => {
                                                        setDatePeriodType(item)
                                                    }}
                                                >
                                                    {t(item)}
                                                </Dropdown.Item>
                                            ))
                                        ) : (
                                            <div className="message">No results found.</div>
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                        </Form.Field>
                    )}
                    {(!tab || tab.canCreateByForm) && isCreateBtn && (
                        <Popup
                            content={t('add_record')}
                            position="bottom right"
                            trigger={<Button icon="add" onClick={handleGoToCard} />}
                        />
                    )}
                    {createFreelanceEvent && name === "orderTransports" && (
                        <Popup
                            content={t("freelanceEvent.title")}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon
                                    loading={freelanceEventCheckLoader}
                                    disabled={disableFreelanceEventBtn}
                                    onClick={checkFreelanceEvent}
                                >
                                    {t("freelanceEvent.title")}
                                </Button>
                            }
                        />
                    )}
                    {(!tab || tab.canDeleteRows) && isDeleteBtn && (
                        <Popup
                            content={t('delete')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="trash"
                                    loading={deleteLoader}
                                    disabled={disableAddActions}
                                    onClick={openDeleteConfirmedModalHandler}
                                />
                            }
                        />
                    )}
                    {(!tab || tab.canImportFromExcel) && isImportBtn && (
                        <Popup
                            content={t('importFromExcel')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="upload"
                                    loading={importLoader}
                                    onClick={importExcel}
                                />
                            }
                        />
                    )}
                    {(!tab || tab.canExportToExcel) && isExportBtn && (
                        <Popup
                            content={
                                t('exportToExcel') // todo
                            }
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="download"
                                    loading={exportLoader}
                                    onClick={exportExcel}
                                />
                            }
                        />
                    )}
                    {name === "orderTransports" && (
                        <Popup
                            content={
                                t('exportDocument')
                            }
                            position="bottom right"
                            trigger={
                                <Button
                                    icon
                                    loading={utdLoader}
                                    disabled={disableAddActions || !additionalActions.includes('utdButton')}
                                    onClick={() => setShowUtd(true)}
                                >
                                    {t('utd')}
                                </Button>
                            }
                        />
                    )}
                    {additionalActions && additionalActions.map(action => {
                        switch (action) {
                            case "formRegisterDm":
                                return !tab || (tab.availableActions && tab.availableActions.includes("formRegisterDm")) ?
                                    <Popup
                                        content={
                                            t('formRegister')
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                disabled={!isCreateBtn}
                                                onClick={showFormRegisterModal}
                                            >
                                                {t('formRegister')}
                                            </Button>
                                        }
                                    />
                                    : null
                            case "formRegisterCarrier":
                                return !tab || (tab.availableActions && tab.availableActions.includes("formRegisterCarrier")) ?
                                    <Popup
                                        content={
                                            t('formRegister')
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                disabled={!isCreateBtn}
                                                onClick={showFormRegisterModal}
                                            >
                                                {t('formRegister')}
                                            </Button>
                                        }
                                    />
                                    : null
                            case "formClaimCarrier":
                                return !tab || (tab.availableActions && tab.availableActions.includes("formClaimCarrier")) ?
                                    <Popup
                                        content={
                                            t('formClaim')
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                loading={claimValidationLoader || claimFormLoader}
                                                disabled={!isCreateBtn || selectedIds.length < 1}
                                                onClick={handleFormClaim}
                                            >
                                                {t('formClaim')}
                                            </Button>
                                        }
                                    />
                                    : null
                            case "uploadClaimCarrier":
                                return !tab || (tab.availableActions && tab.availableActions.includes("uploadClaimCarrier")) ?
                                    <Popup
                                        content={
                                            t('uploadClaim')
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                loading={claimUploadLoader}
                                                disabled={!isCreateBtn || selectedIds.length !== 1}
                                                onClick={() => handleUploadClaim()}
                                            >
                                                {t('uploadClaim')}
                                            </Button>
                                        }
                                    />
                                    : null
                            case "sendClaimCarrierToSap":
                                return !tab || (tab.availableActions && tab.availableActions.includes("sendClaimCarrierToSap")) ?
                                    <Popup
                                        content={
                                            t('sendToSapClaimButtonPopup')
                                        }
                                        position="bottom right"
                                        trigger={
                                            <Button
                                                icon
                                                loading={sendClaimToSapLoader}
                                                disabled={!isCreateBtn || selectedIds.length !== 1}
                                                onClick={handleSendClaimToSap}
                                            >
                                                {'SAP'}
                                            </Button>
                                        }
                                    />
                                    : null
                            case "disclaimerOfWarranties": {
                                return name === "availableVehicles" ?
                                    <Button
                                        icon
                                        disabled={!disclaimerOfWarrantiesAvailable || dateDisclaimerWarrantyLoader || selectedIds.length < 1}
                                        loading={dateDisclaimerWarrantyLoader}
                                        onClick={handleGetDateDisclaimerOfWarranties}
                                    >
                                        {t("disclaimerOfWarranties")}
                                    </Button>
                                    : null
                            }
                            case "sendToSap": {
                                return <Button
                                    icon
                                    disabled={disableAddActions || invokeActionLoader}
                                    onClick={() => executeAdditionalAction(action)}
                                >
                                    {invokeActionLoader ? <Icon loading name='spinner' /> : "SAP"}
                                </Button>
                            }
                            case "changeAvailableVehiclesEndTime": {
                                return <Popup
                                    content={
                                        t(action)
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon
                                            disabled={disableAddActions || invokeActionLoader}
                                            loading={invokeActionLoader}
                                            onClick={() => setShowMassDateEditModal(true)}
                                        >
                                            {t("changeMassDate")}
                                        </Button>
                                    }
                                />
                            }
                            case "startAuction": {
                                return <Popup
                                    content={
                                        t(action)
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="play"
                                            disabled={disableAddActions || invokeActionLoader}
                                            loading={invokeActionLoader}
                                            onClick={() => executeAdditionalAction(action)}
                                        />
                                    }
                                />
                            }
                            case "stopAuction": {
                                return <Popup
                                    content={
                                        t(action)
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="stop"
                                            disabled={disableAddActions || invokeActionLoader}
                                            loading={invokeActionLoader}
                                            onClick={() => executeAdditionalAction(action)}
                                        />
                                    }
                                />
                            }
                            case "autoblockingWarrantyButtonBlock":
                            case "autoblockingWarrantyButtonReject": {
                                return (tab && tab.value === 'awaiting') && <Button
                                    disabled={!rows.length || invokeActionLoader}
                                    loading={invokeActionLoader}
                                    onClick={() => executeAdditionalAction(action, true)}
                                >
                                    {t(action)}
                                </Button>
                            }
                            case "massRejectByDm": {
                                return <Popup
                                    content={
                                        t(action)
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon='cancel'
                                            loading={massRejectValidationLoader}
                                            disabled={disableAddActions || invokeActionLoader}
                                            onClick={handleShowMassReject}
                                        />
                                    }
                                />
                            }
                            case "downloadAllOrderFiles": {
                                return <Popup
                                    content={
                                        t(action)
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon
                                            disabled={downloadOrderAllFilesLoader || selectedIds.length !== 1}
                                            loading={downloadOrderAllFilesLoader}
                                            onClick={handleDownloadAllOrderFiles}
                                        >
                                            {t("downloadAllOrderFiles")}
                                        </Button>
                                    }
                                />
                            }
                            default: {
                                return null
                            }
                        }
                    })}
                    <Popup
                        content={t('reset_filters')}
                        position="bottom right"
                        trigger={
                            <Button
                                icon
                                className={`clear-filter-btn`}
                                onClick={clearFilter}
                                disabled={disabledClearFilter}
                            >
                                <CustomIcon name="clear-filter" />
                            </Button>
                        }
                    />
                    <Search
                        searchValue={searchValue}
                        className="search-input"
                        value={filter.filter.filter.search}
                        onChange={searchOnChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <input
                type="file"
                ref={fileUploader}
                accept={isClaimUpload ? '.pdf' : ''}
                style={{ display: 'none' }}
                onInput={onFilePicked}
            />
            <Modal open={showUtd}>
                <Modal.Header>{t('exportDocument')}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t('utdNumber')}</label>
                                    <Input
                                        fluid
                                        type="text"
                                        value={utdNumber && utdNumber["utdNumber"]}
                                        name="utdNumber"
                                        onChange={handleChangeUtdNumber}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    {/* <label>{t('utdDate')}</label>
                                        <DatePicker
                                            isClearable={true}
                                            locale={localStorage.getItem('i18nextLng')}
                                            dateFormat="dd.MM.yyyy"
                                            selected={parseDate((utdNumber && utdNumber["utdDate"] || '').substring(0, 10))}
                                            name="utdDate"
                                            className="date-picker-form-field"
                                            onChange={handleChangeUtdNumber}
                                        /> */}
                                    <Date
                                        name="utdDate"
                                        className="date-picker-form-field"
                                        onChange={handleChangeUtdNumber}
                                        value={utdNumber && utdNumber["utdDate"]}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={() => {
                        setUtdNumber(null)
                        setShowUtd(false)
                    }}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={utdNumber === null ||
                            !utdNumber["utdNumber"] || utdNumber["utdNumber"].length === 0 ||
                            !utdNumber["utdDate"] || utdNumber["utdDate"].length === 0}
                        onClick={handleDownloadUtd}
                        loading={utdExportLoader}
                    >
                        {t('exportUtd')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={dateDisclaimerOfWarranties}>
                <Modal.Header>{t('disclaimerOfWarranties')}</Modal.Header>
                <Modal.Content>
                    {t('disclaimerOfWarranties.text').replace('{0}', moment(dateDisclaimerOfWarranties).add(1, 'd').format('DD.MM.YYYY'))}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="grey"
                        onClick={() => {
                            setDateDisclaimerOfWarranties(null)
                        }}
                    >
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={invokeActionLoader}
                        onClick={handleDisclaimerOfWarranties}
                        loading={invokeActionLoader}
                    >
                        {t('orderTransportConfirmationTc')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <MassChangesModal
                name='changeMassEndDate'
                loading={invokeActionLoader}
                onSave={saveChangeMassEndDate}
                open={showMassDateEditModal}
                setOpen={setShowMassDateEditModal}
            />
            <Modal open={showFormRegister}>
                <Modal.Header>{
                    t('formRegisterTitle') +
                    ((formRegisterDefaults && formRegisterDefaults.nextRegisterName) ? " - №" + formRegisterDefaults.nextRegisterName : " - ") +
                    ((formRegisterDefaults && formRegisterDefaults.registerDate) ? " - " + formRegisterDefaults.registerDate : " - ")
                }</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Form.Field>
                                    <Date
                                        name="startDate"
                                        className="date-picker-form-field"
                                        value={formRegisterParams && formRegisterParams["startDate"]}
                                        onChange={handleChangeRegisterParams}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <Date
                                        name="endDate"
                                        className="date-picker-form-field"
                                        value={formRegisterParams && formRegisterParams["endDate"]}
                                        onChange={handleChangeRegisterParams}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Form.Field>
                                    <Select
                                        name="penaltyType"
                                        isTranslate
                                        source={name === "registerDm" ? "penaltyDmType" : "penaltyCarrierDisplayType"}
                                        value={formRegisterParams && formRegisterParams["penaltyType"]}
                                        onChange={handleChangeRegisterParams}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={() => {
                        setFormRegisterParams(null)
                        setShowFormRegister(false)
                    }}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={formRegisterParams === null ||
                            !formRegisterParams["startDate"] || !formRegisterParams['endDate'] ||
                            !formRegisterParams["penaltyType"]}
                        loading={formRegisterLoader}
                        onClick={handleFormRegister}
                    >
                        {t('formRegister')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={showMassReject}>
                <Modal.Header>{
                    t('massRejectTitle')
                }</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Form.Field style={{ display: "flex", flexDirection: "column" }}>
                                    <label>{t('commentDmText')}</label>
                                    <TextArea
                                        autoHeight
                                        className="ui form textarea"
                                        value={massRejectModalValue && massRejectModalValue["commentDmText"]}
                                        name="commentDmText"
                                        onChange={handleChangeMassRejectValue}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <Select
                                        name="rejectReasonTypeId"
                                        isTranslate
                                        source={"rejectReasonTypes"}
                                        value={massRejectModalValue && massRejectModalValue["rejectReasonTypeId"]}
                                        onChange={handleChangeMassRejectValue}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={() => {
                        setShowMassReject(false)
                    }}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        loading={massRejectLoader}
                        onClick={handleMassReject}
                    >
                        {t('reject')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal size="tiny" open={showUploadClaim}>
                <Modal.Header>{
                    t('uploadClaim')
                }</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>{t('claimNumber')}</label>
                                    <Input
                                        fluid
                                        type="text"
                                        value={uploadClaimParams && uploadClaimParams["claimNumber"]}
                                        name="claimNumber"
                                        onChange={handleChangeUploadClaimParams}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <File
                                        fromModalUpload={true}
                                        name="document"
                                        value={uploadClaimParams && uploadClaimParams["files"]}
                                        onChange={handleChangeUploadClaimParams}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={() => {
                        setUploadClaimParams(null)
                        setShowUploadClaim(false)
                    }}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        onClick={handleUploadClaim}
                        disabled={!uploadClaimParams || !uploadClaimParams["files"] || !uploadClaimParams["claimNumber"] || !uploadClaimParams["files"].length}
                    >
                        {t('SaveButton')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={showFreelanceEvent}>
                <Modal.Header>{t('freelanceEvent.title.create')}</Modal.Header>
                <FreelanceEventModal
                    closeFreelanceEventModal={closeFreelanceEventModal}
                    selectedIds={selectedIds}
                />
            </Modal>
            <Modal
                size='mini'
                dimmer='blurring'
                open={isOpenDeleteConfirmedModal}
                onClose={closeDeleteConfirmedModalHandler}
            >
                <Modal.Header>{t('table.delete.records.confirm.header')}</Modal.Header>
                <Modal.Content>
                    <p>{t('table.delete.records.confirm.question')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={closeDeleteConfirmedModalHandler}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue" onClick={confirmedActionDeleteRowsHandler}>
                        {t('delete')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )
}

export default Header
