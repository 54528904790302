import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import Documents from './shared/documents';
import History from './shared/history';
import CreateFineDmBill from './registerTabs/createFineDmBill';

import CardLayout from '../../../components/CardLayout';
import { isTripPointsGridSelector } from '../../../ducks/gridList';
import { GRID_LIST_LINK } from '../../../router/links';
import { userPermissionsSelector } from '../../../ducks/profile';



const FineDmBillCard = ({
    form,
    onChangeForm,
    name,
    id,
    load,
    isNotUniqueNumber,
    uniquenessNumberCheck,
    settings,
    error,
    title,
    onClose,
    actionsFooter,
    actionsHeader,
    loading,
    goToSoldTo,
}) => {
    const {t} = useTranslation();
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    const isTripPointsAvailable = useSelector(state => isTripPointsGridSelector(state));

    const getPanes = () => {
        let obj = [
            {
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        form={form}
                        isGrid={true}
                        cardId={id}
                        load={load}
                        isEditPermissions={userPermissions.includes(5)}
                    />
                ),
            },
            {
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            },
        ];

        if ((userPermissions.includes(4) || userPermissions.includes(5)) && form.id) {
            obj.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        load={load}
                        isEditPermissions={userPermissions.includes(5)}
                    />
                ),
            });
        }

        if (userPermissions.includes(6) && form.id) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} status={form.status} />,
            });
        }

        return obj;
    };

    return (
        <>
        {id ? (
                <CardLayout
                    title={title}
                    actionsFooter={actionsFooter}
                    actionsHeader={actionsHeader}
                    content={getPanes}
                    onClose={onClose}
                    loading={loading}
                />
            ) : (
                <CardLayout
                    title={title}
                    actionsFooter={actionsFooter}
                    onClose={onClose}
                    loading={loading}
                >
                    <CreateFineDmBill type={name} form={form} error={error} settings={settings} onChange={onChangeForm} />
                </CardLayout>
            )}
        </>
    );
};

export default FineDmBillCard;
