import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadFilesRequest, downloadProgressSelector, downloadingEntityIdSelector } from '../../ducks/documents'
import { Icon } from 'semantic-ui-react'
import TextCropping from './TextCropping'
import { Loader } from 'semantic-ui-react'

const FilesValue = ({ value, id, indexColumn, showRawValue, width, valueText }) => {
    const dispatch = useDispatch()
    const isFileDownloading = useSelector(state => downloadProgressSelector(state))
    const downloadingEntityId = useSelector(state => downloadingEntityIdSelector(state))

    const handleDownload = (e) => {
        e.stopPropagation()
        id && dispatch(downloadFilesRequest({
            id: id
        }))
    }

    const isFetching = useMemo(
        () => isFileDownloading && downloadingEntityId == id,
        [isFileDownloading, downloadingEntityId])

    return (
        <TextCropping width={width} indexColumn={indexColumn}>
            <Loader size='tiny' active={isFetching}/>
            {
                !isFetching &&
                <Icon
                    disabled={value && value.length === 0}
                    name="file"
                    size='large'
                    placeholder={valueText}
                    onClick={handleDownload}
                />
            }
            {!isFetching && value && value.length ? value.length : null}
        </TextCropping>
    )
}

export default FilesValue